import { ref } from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  components: {
    VueDatePicker,
  },
  data() {
    return {
      dateRange: ref(),
      friends: [
        {
          id: 1,
          imageUrl: 'https://media.vanityfair.com/photos/63974b59d6001ee3cc78578a/1:1/w_2000,h_2000,c_limit/kit-harington-Jon-Snow.png',
          name: 'Jon Snow',
        },
        {
          id: 2,
          imageUrl: 'https://static.hbo.com/content/dam/hbodata/series/game-of-thrones/character/s5/daenarys-1920.jpg?w=1200',
          name: 'Daenerys',
        },
        {
          id: 3,
          imageUrl: 'https://static.hbo.com/content/dam/hbodata/series/game-of-thrones/character/s5/margarey-tyrell-1920.jpg?w=1200',
          name: 'Margaery Tyrell',
        },
        {
          id: 4,
          imageUrl: 'https://pyxis.nymag.com/v1/imgs/178/44a/8c3923a68356de844ade9c32fa35f981c0-20-sansa-lede.1x.rsocial.w1200.jpg',
          name: 'Sansa Stark',
        },
        {
          id: 5,
          imageUrl: '',
          name: 'New Friend',
        },
      ],
    };
  },
  methods: {
    formatDate(date) {
      return new Intl.DateTimeFormat("en-US").format(date);
    },
  },
};