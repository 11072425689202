import { login } from '../../services/apiService';
import { useAuthStore } from '@/store/authStore';
import FullPageLoader from '../../components/FullPageLoader.vue';

export default {
  components: {
    FullPageLoader,
  },
  data() {
    return {
      email: '',
      password: '',
      passwordVisible: false,
      emailError: '',
      passwordError: '',
      errorMessage:'',
      loading: false,
    };
  },
  created() {
    const authStore = useAuthStore(); // Initialize the auth store
    authStore.logout(); // Example action, e.g., clear token
  },
  computed: {
    // Using Pinia's error state
    errorMessage() {
      const authStore = useAuthStore();
      return authStore.error;
    }
  },
  methods: {
    showPassword() {
      this.passwordVisible = !this.passwordVisible;
    },
    RedirectTo(path) {
      this.$router.push(path);
    },
    validate() {
      this.emailError = '';
      this.passwordError = '';
      
      if (!this.email) {
        this.emailError = 'E-mail is vereist';
      } else if (!this.validEmail(this.email)) {
        this.emailError = 'E-mail is ongeldig';
      }
      
      if (!this.password) {
        this.passwordError = 'Wachtwoord is vereist';
      } else if (this.password.length < 8) {
        this.passwordError = 'Je wachtwoord moet minstens 8 karakters bevatten';
      }

      return !(this.emailError || this.passwordError); // return true if no errors
    },
    validEmail(email) {
      const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/; // Simple regex for email validation
      return regex.test(email);
    },
    handleSubmit() {
      const authStore = useAuthStore()
      if (this.validate()) {
        // Logic to handle login
        this.loading = true;
        login(this.email, this.password)
          .then((response) => {
            this.loading = false;
            authStore.setAuthData(response);
            window.location.href = "family"; // Change this to the desired route
          })
          .catch((error) => {
            this.loading = false;
            // console.error('Login failed:', error);
            this.errorMessage = error.message || 'Inloggen mislukt. Probeer het opnieuw.';
          });
      }
    }
  }
}