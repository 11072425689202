<template>
    <!-- Subscription Modal -->
    <div class="modal fade font-quicksand-500" id="subscriptionModal" tabindex="-1" aria-labelledby="subscriptionModal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="container">
                        <div class="d-flex justify-content-center position-relative">
                            <div class="text-center">
                                <h5 class="mt-2 modal-title font-quicksand-600 font-24 mb-1" id="descriptionModalLabel">Abonnement</h5>
                            </div>
                            <a class="modal-cross-icon cursor-pointer" data-bs-dismiss="modal">
                                <svg width="19" height="22" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.8295 4.3295C3.39017 4.76884 3.39017 5.48116 3.8295 5.9205L6.40901 8.5L3.8295 11.0795C3.39017 11.5188 3.39017 12.2312 3.8295 12.6705C4.26884 13.1098 4.98116 13.1098 5.4205 12.6705L8 10.091L10.5795 12.6705C11.0188 13.1098 11.7312 13.1098 12.1705 12.6705C12.6098 12.2312 12.6098 11.5188 12.1705 11.0795L9.59099 8.5L12.1705 5.9205C12.6098 5.48116 12.6098 4.76884 12.1705 4.3295C11.7312 3.89017 11.0188 3.89017 10.5795 4.3295L8 6.90901L5.4205 4.3295C4.98116 3.89017 4.26884 3.89017 3.8295 4.3295Z" fill="#4A4A4A"/>
                                </svg>
                            </a>
                        </div>
                        <div class="text-center mb-3">
                            <button type="button" class="btn-theme mt-2">Opslaan</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  methods: {
  }
}
</script>