import { createRouter, createWebHistory } from 'vue-router';
import Login from './pages/Login/index.vue';
import CreateAccount from './pages/CreateAccount/index.vue';
import ForgotPassword from './pages/ForgotPassword/index.vue';
import ResetPassword from './pages/ResetPassword/index.vue';
import Family from './pages/Family/index.vue';
import AddParent from './pages/Profile/AddParent/index.vue';
import OtherMember from './pages/Profile/OtherMember/index.vue';
import AddChild from './pages/Profile/AddChild/index.vue';
import ViewProfile from './pages/Profile/ViewProfile/index.vue';
import ViewChildProfile from './pages/Profile/ViewChildProfile/index.vue';
import More from './pages/More/index.vue';
import Calendar from './pages/Calendar/index.vue';
import CalendarAddEvent from './pages/Calendar/AddEvent/index.vue';
import Registrations from './pages/Registrations/index.vue';
import Search from './pages/Search/index.vue';
import MoreCamps from './pages/MoreCamps/index.vue';
import CampDetails from './pages/CampDetails/index.vue';
import Notifications from './pages/More/Notifications/index.vue';
import AccountSettings from './pages/More/AccountSettings/index.vue';
import Success from './pages/Success/index.vue';
import ActivateAccount from './pages/ActivateAccount/index.vue';
import Enrollment from './pages/Enrollment/index.vue';
import EnrollmentRRN from './pages/Enrollment/EnrollmentRRN/index.vue';
import UpdateParent from './pages/Profile/UpdateParent/index.vue';
import UpdateMember from './pages/Profile/UpdateMember/index.vue';
import UpdateChild from './pages/Profile/UpdateChild/index.vue';
import FriendRequest from './pages/FriendRequest/index.vue';
import PreviewCamp from './pages/PreviewCamp/index.vue';
import RegisteredCampDetais from './pages/RegisteredCampDetails/index.vue';
import ViewRequestChildProfile from './pages/Profile/ViewRequestChildProfile/index.vue';
import EnrollmentFriend from './pages/EnrollmentFriend/index.vue';

const routes = [
  { path: '/', component: Login },
  { path: '/success',component: Success},
  { path: '/verify', component: ActivateAccount },
  { path: '/logout', redirect: '/'}, // Redirect to login after logout
  { path: '/create-account', component: CreateAccount },
  { path: '/forgot-password', component: ForgotPassword },
  { path: '/reset-password', component: ResetPassword },
  { path: '/preview-camp-details/:id', component: PreviewCamp },
  { path: '/enrollment-non-auth', component: EnrollmentFriend },
  {
    path: '/family',
    component: Family,
    meta: { requiresAuth: true }
  },
  {
    path: '/parent-profile-form',
    component: AddParent,
    meta: { requiresAuth: true }
  },
  {
    path: '/other-profile-form',
    component: OtherMember,
    meta: { requiresAuth: true }
  },
  {
    path: '/child-profile-form',
    component: AddChild,
    meta: { requiresAuth: true }
  },
  {
    path: '/update-parent-profile/:id/:tab',
    component: UpdateParent,
    meta: { requiresAuth: true }
  },
  {
    path: '/friend-request/:id',
    component: FriendRequest,
    meta: { requiresAuth: true }
  },
  {
    path: '/update-other-profile/:id',
    component: UpdateMember,
    meta: { requiresAuth: true }
  },
  {
    path: '/update-child-profile/:id/:tab',
    component: UpdateChild,
    meta: { requiresAuth: true }
  },
  {
    path: '/view-profile/:id',
    component: ViewProfile,
    meta: { requiresAuth: true }
  },
  {
    path: '/view-child-profile/:id',
    component: ViewChildProfile,
    meta: { requiresAuth: true }
  },
  {
    path: '/view-request-child-profile/:id',
    component: ViewRequestChildProfile,
    meta: { requiresAuth: true }
  },
  {
    path: '/more',
    component: More,
    meta: { requiresAuth: true }
  },
  {
    path: '/more-camps/:type',
    component: MoreCamps,
    meta: { requiresAuth: true }
  },
  {
    path: '/calendar',
    component: Calendar,
    meta: { requiresAuth: true }
  },
  {
    path: '/calendar/add-event',
    component: CalendarAddEvent,
    meta: { requiresAuth: true }
  },
  {
    path: '/enrollment',
    component: Enrollment,
    meta: { requiresAuth: true }
  },
  {
    path: '/enrollment-rrn',
    component: EnrollmentRRN,
    meta: { requiresAuth: true }
  },
  {
    path: '/notifications',
    component: Notifications,
    meta: { requiresAuth: true }
  },
  {
    path: '/account-settings',
    component: AccountSettings,
    meta: { requiresAuth: true }
  },
  {
    path: '/registrations/:tab',
    component: Registrations,
    meta: { requiresAuth: true }
  },
  {
    path: '/search',
    component: Search,
    meta: { requiresAuth: true }
  },
  {
    path: '/camp-details/:id',
    component: CampDetails,
    meta: { requiresAuth: true }
  },
  {
    path: '/registered-camp-details/:id',
    component: RegisteredCampDetais,
    meta: { requiresAuth: true }
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Example navigation guard for protected routes
router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('authToken'); // Replace with your auth logic
  if (to.meta.requiresAuth && !isAuthenticated) {
    next('/'); // Redirect to login if not authenticated
  } else if (to.path === '/' && isAuthenticated) {
    next('/family'); // Redirect to family if already authenticated and trying to access login
  } else {
    next();
  }
});

export default router;