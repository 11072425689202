import AppCompoundShape from '@/assets/images/AppCompoundShape.png';
import Logo from '@/assets/images/LogoInverted.png';
import FullPageLoader from '@/components/FullPageLoader.vue';
import {
  getNotifications,
  readNotification,
  readAllNotification,
  deleteNotification,
} from '@/services/apiService';
import { useAuthStore } from '@/store/authStore';

export default {
  data() {
    return {
      AppCompoundShape,
      FullPageLoader,
      Logo,
      user: null,
      loading: false,
      notifications: [],
    };
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem('loggedin_user'));

    this.fetchNotifications();
  },
  methods: {
    async fetchNotifications() {
      this.loading = true;

      if (!this.user) return;

      getNotifications(this.user.id)
        .then((response) => {
          this.loading = false;
          this.notifications = response.data;
          console.log(this.notifications);
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.message);
        });
    },
    getNotificationIcon(icon) {
      let iconResult;

      try {
        iconResult = require(`@/assets/icons/ic-notification-${icon}.svg`);
      } catch (e) {
        iconResult = require('@/assets/icons/ic-notification-fallback.svg');
      }

      return iconResult;
    },
    onClickNotification(n) {
        if (n.url) {
          window.location.href = n.url;
        }

        if (!this.user) return;
      if (n.is_read == 0) {
        readNotification(n)
        .then(() => {
          this.fetchNotifications();
        })
        .catch((error) => {
          console.log(error.message);
        });
      }
    },
    removeNotification(notificationId) {
      // Remove the notification with the matching id from the notifications array
      this.loading = true;
      const authStore = useAuthStore()

      let formData = new FormData()
      formData.append('id', notificationId)
      formData.append('app_user_id', authStore.user?.parent?.app_user_id)
      deleteNotification(formData)
      .then(() => {
        this.loading = false;
        this.notifications = this.notifications.filter(notification => notification.id !== notificationId);
      })
      .catch((error) => {
        this.loading = false;
        console.log(error.message);
      });
    },
    MarkAllAsRead() {
      if (!this.user) return;

      readAllNotification(this.user.id)
        .then(() => {
          this.fetchNotifications();
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
    formatDate(date) {
      return new Intl.DateTimeFormat('nl-nl', { dateStyle: 'long' }).format(new Date(date));
    },
  }
}