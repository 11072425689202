<template>
  <!-- Friend Request Modal -->
  <div
    class="modal fade font-opensans-400"
    id="socialSecurityModal"
    tabindex="-1"
    aria-labelledby="socialSecurityModal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body" v-if="childInfo">
          <div class="position-relative">
            <div>
              <h5 class="mt-2 modal-title font-quicksand-600 font-24">
                Om {{ childInfo.first_name }} in te kunnen inschrijven hebben we
                een rijksregisternummer nodig.
              </h5>
              <p class="mt-1 font-16 font-opensans-400">
                Het rijksregisternummer van jouw kind is nog niet ingevuld op
                zijn/haar profiel. Geef het hier in en wij voegen het toe aan uw
                kind zijn gegevens.
              </p>
            </div>
            <a class="modal-cross-icon cursor-pointer" data-bs-dismiss="modal">
              <svg
                width="19"
                height="22"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.8295 4.3295C3.39017 4.76884 3.39017 5.48116 3.8295 5.9205L6.40901 8.5L3.8295 11.0795C3.39017 11.5188 3.39017 12.2312 3.8295 12.6705C4.26884 13.1098 4.98116 13.1098 5.4205 12.6705L8 10.091L10.5795 12.6705C11.0188 13.1098 11.7312 13.1098 12.1705 12.6705C12.6098 12.2312 12.6098 11.5188 12.1705 11.0795L9.59099 8.5L12.1705 5.9205C12.6098 5.48116 12.6098 4.76884 12.1705 4.3295C11.7312 3.89017 11.0188 3.89017 10.5795 4.3295L8 6.90901L5.4205 4.3295C4.98116 3.89017 4.26884 3.89017 3.8295 4.3295Z"
                  fill="#4A4A4A"
                />
              </svg>
            </a>
          </div>
          <form @submit.prevent="handleSubmit" class="mt-2">
            <div class="row">
              <!--<div class="col-12 col-md-12 col-lg-12">
                            <div class="form-group">
                                <label for="parentRegistrationNumber" class="input-label">Rijksregisternummer ouder</label>
                                <input type="text" class="form-control form-input" v-model="parentRegistrationNumber" />
                                <div v-if="parentRegistrationNumberError" class="text-validation d-flex align-items-start mt-1">            
                                    <svg width="15" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.8295 5.8295C3.39017 6.26884 3.39017 6.98116 3.8295 7.4205L6.40901 10L3.8295 12.5795C3.39017 13.0188 3.39017 13.7312 3.8295 14.1705C4.26884 14.6098 4.98116 14.6098 5.4205 14.1705L8 11.591L10.5795 14.1705C11.0188 14.6098 11.7312 14.6098 12.1705 14.1705C12.6098 13.7312 12.6098 13.0188 12.1705 12.5795L9.59099 10L12.1705 7.4205C12.6098 6.98116 12.6098 6.26884 12.1705 5.8295C11.7312 5.39017 11.0188 5.39017 10.5795 5.8295L8 8.40901L5.4205 5.8295C4.98116 5.39017 4.26884 5.39017 3.8295 5.8295Z" fill="#F00000"/>
                                    </svg>
                                    {{ parentRegistrationNumberError }}
                                </div>
                            </div>
                        </div>-->
              <div class="col-12 col-md-12 col-lg-12">
                <div class="form-group">
                  <label for="childRegistrationNumber" class="input-label mb-1"
                    >Rijksregisternummer kind</label
                  >
                  <input
                    type="text"
                    class="form-control form-input"
                    v-model="childRegistrationNumber"
                  />
                  <div
                    v-if="childRegistrationNumberError"
                    class="text-validation d-flex align-items-start mt-1"
                  >
                    <svg
                      width="15"
                      height="20"
                      viewBox="0 0 16 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M3.8295 5.8295C3.39017 6.26884 3.39017 6.98116 3.8295 7.4205L6.40901 10L3.8295 12.5795C3.39017 13.0188 3.39017 13.7312 3.8295 14.1705C4.26884 14.6098 4.98116 14.6098 5.4205 14.1705L8 11.591L10.5795 14.1705C11.0188 14.6098 11.7312 14.6098 12.1705 14.1705C12.6098 13.7312 12.6098 13.0188 12.1705 12.5795L9.59099 10L12.1705 7.4205C12.6098 6.98116 12.6098 6.26884 12.1705 5.8295C11.7312 5.39017 11.0188 5.39017 10.5795 5.8295L8 8.40901L5.4205 5.8295C4.98116 5.39017 4.26884 5.39017 3.8295 5.8295Z"
                        fill="#F00000"
                      />
                    </svg>
                    {{ childRegistrationNumberError }}
                  </div>
                </div>
              </div>
              <div class="col-12 mb-2">
                <button type="submit" class="btn-theme mt-2">
                    {{ isLoading ? 'Besparing...' : 'Opslaan' }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <button data-bs-dismiss="modal" class="d-none" id="close">HMM</button>
  </div>
</template>

<script>
import { updateFamilyRegistrationNumber } from '@/services/apiService';

export default {
  props: ['child'],
  data() {
    return {
      // parentRegistrationNumber: '',
      childRegistrationNumber: '',
      // parentRegistrationNumberError: '',
      childRegistrationNumberError: '',
      childInfo: null,
      isLoading: false,
    };
  },
  watch: {
    child(newVal) {
      this.childInfo = newVal;
    },
  },
  methods: {
    validate() {
      // this.parentRegistrationNumberError = '';
      this.childRegistrationNumberError = '';

      // if (!this.parentRegistrationNumber) {
      //     this.parentRegistrationNumberError = 'Vereist';
      // }

      if (!this.childRegistrationNumber) {
        this.childRegistrationNumberError = 'Vereist';
      }

      return !(
        this.parentRegistrationNumberError || this.childRegistrationNumberError
      ); // return true if no errors
    },
    async handleSubmit() {
        if (!this.validate()) return;
        this.isLoading = true;

        try {
            await updateFamilyRegistrationNumber({
                child_id: this.childInfo.id,
                registration_no: this.childRegistrationNumber,
            });

            const close = document.querySelector('#close');
            if (close) close.click();
        } catch (error) {
            console.log(error);
        } finally {
            this.isLoading = false;
        }
    },
  },
};
</script>