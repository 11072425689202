import { createApp } from 'vue';
import App from './App.vue';
import { createPinia } from 'pinia';
import { getRoleName } from '@/utils/roleUtils';
import router from './router';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import './assets/css/styles.css'

const app = createApp(App);
const pinia = createPinia();

app.use(router); // Ensure router is added correctly
app.use(pinia);
app.config.globalProperties.$getRoleName = getRoleName;

app.mount('#app');