// src/utils/roleUtils.js

export function getRoleName(role,memberrole) {
    if (role === 1) {
        return "Ouder";
    } else if (role === 2) {
        return "Ouder";
    } else {
        if (memberrole === 1) {
            return "older";
        } else if (memberrole === 2) {
            return "babysit";
        } else if (memberrole === 3) {
            return "grandparent";
        }else{
            return "Andere";
        }
    }
}


