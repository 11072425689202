<template>
  <div class="hap-calendar-filters">
    <!--<button @click="setFilter('all')">Alle</button>-->
    <button @click="setFilter('registered')" class="green font-quicksand-600">Ingeschreven</button>
    <button @click="setFilter('watchlist')" class="orange font-quicksand-600">Wachtlijst</button>
    <button @click="setFilter('friends')" class="yellow font-quicksand-600">Vriendjes</button>
    <button @click="setFilter('favorite')" class="purple font-quicksand-600">Favorieten</button>
  </div>

  <FullCalendar ref="calendarEl" :options="calendarOptions" />
</template>

<script>
import { toRaw } from 'vue';
import FullCalendar from '@fullcalendar/vue3';
import multiMonthPlugin from '@fullcalendar/multimonth';

export default {
  props: {
    events: Array,
  },
  components: {
    FullCalendar,
  },
  data() {
    return {
      calendarOptions: {
        plugins: [multiMonthPlugin],
        timeZone: 'Europe/Amsterdam',
        locale: 'nl',
        initialView: 'multiMonthYear',
        firstDay: 1,
        events: [],
        headerToolbar: {
          left: 'prev',
          center: 'title',
          right: 'next',
        },
        titleFormat: {
          year: 'numeric',
        },
        multiMonthTitleFormat: {
          month: 'short',
        },
        dayHeaderContent(arg) {
          return arg.text.substring(0, 1);
        },
        eventContent: function (arg) {
          const data = arg.event;

          const html = `
            <div title="${data.title}" data-event="${JSON.stringify(data)}" class="fc-event-multimonth"></div>
          `;
          return { html };
        },
      },
      calendar: null,
    };
  },
 watch: {
  events(newEvents) {
    const events = toRaw(newEvents)[0];
    
    // if (!events || !events.length) return;
    
    const self = this;
    this.calendarOptions.events = events;
    this.calendarOptions.eventDidMount = (arg) => {
      const data = arg.event;
      const el = arg.el;
      
      // Get the event's start and end dates
      const startDate = new Date(data.start);
      const endDate = new Date(data.end || data.start);  // Use start date as end date if end is undefined
      
      // Function to add class to all relevant day cells
      function addClassToRange(startDate, endDate) {
        const dayCells = document.querySelectorAll('.fc-daygrid-day');  // Find all day cells
        
        dayCells.forEach(cell => {
          const cellDate = new Date(cell.getAttribute('data-date'));  // Get the date of the cell from its attribute
            
          // Check if the cell date is within the event range
          if (cellDate >= startDate && cellDate <= endDate) {
            // Add the 'has-event' class to this cell
            cell.classList.add('has-event', 'cursor-pointer');
            
            // Optionally, set tooltip or data attributes for the day cell
            const parent = cell.querySelector('.fc-daygrid-day-top');
            parent.setAttribute('title', data.title);
            parent.setAttribute('data-event', JSON.stringify(data));
            parent.addEventListener('click', () => self.onClickEvent(data));
          }
        });
      }
      
      // Add the class to the range of days
      addClassToRange(startDate, endDate);
      
      // Optionally, add event to specific day element for the first event
      const parent = el.parentElement.parentElement.parentElement.querySelector('.fc-daygrid-day-top');
      const td = parent.parentElement.parentElement;
      td.classList.add('has-event', 'cursor-pointer');
      parent.setAttribute('title', data.title);
      parent.setAttribute('data-event', JSON.stringify(data));
      parent.addEventListener('click', () => self.onClickEvent(data));
    };

    if (this.$refs.calendarEl) {
      this.calendar = this.$refs.calendarEl.calendar;
    }
  },
},
  methods: {
    setFilter(category) {
      this.calendarOptions.events = [];

      if (category === 'all') {
        this.calendarOptions.events = this.events;

        return;
      }

      this.calendarOptions.events = this.events[0].filter((ev) => {
        return ev.category === category;
      });
    },
    onClickEvent(ev) {
      this.$emit('onShowEventModal', ev);
    }
  },
};
</script>

<style>
</style>