<template>
    <FullPageLoader :isLoading="loading" />
    <div class="header-mobile">
        <img class="imageClass" src="@/assets/images/AppCompoundShape.png" alt="top-header"/>
    </div>
    <div class="header-shape-text">
        <router-link class="back-arrow" to="/">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0607 7.06066C16.6464 6.47487 16.6464 5.52513 16.0607 4.93934C15.4749 4.35355 14.5251 4.35355 13.9393 4.93934L7.93934 10.9393C7.35355 11.5251 7.35355 12.4749 7.93934 13.0607L13.9393 19.0607C14.5251 19.6464 15.4749 19.6464 16.0607 19.0607C16.6464 18.4749 16.6464 17.5251 16.0607 16.9393L11.1213 12L16.0607 7.06066Z" fill="white"/>
                <mask id="mask0_4603_993" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="7" y="4" width="10" height="16">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0607 7.06066C16.6464 6.47487 16.6464 5.52513 16.0607 4.93934C15.4749 4.35355 14.5251 4.35355 13.9393 4.93934L7.93934 10.9393C7.35355 11.5251 7.35355 12.4749 7.93934 13.0607L13.9393 19.0607C14.5251 19.6464 15.4749 19.6464 16.0607 19.0607C16.6464 18.4749 16.6464 17.5251 16.0607 16.9393L11.1213 12L16.0607 7.06066Z" fill="white"/>
                </mask>
                <g mask="url(#mask0_4603_993)">
                </g>
            </svg>
        </router-link>
    </div>
    <div class="header-shape-text d-flex justify-content-center align-items-center">
        <h4 class="font-20 font-quicksand-600 mb-0">Wachtwoord vergeten</h4>
    </div>
    <div class="container mt-4 mt-md-6">
        <div class="row p-2 w-form-desktop">
            <div>
                <p class="color-black font-opensans-400 font-16">Geef het e-mailadres van uw account in waarvan u het wachtwoord wil resetten. </p>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12">
                <form @submit.prevent="handleSubmit" class="mt-3">
                <div class="form-group">
                    <label for="email" class="input-label">E-mailadres</label>
                    <input type="text" class="form-control form-input" v-model="email" placeholder="E-mailadres" />
                    <div v-if="errorMessage" class="text-validation d-flex align-items-start mt-1">            
                    <svg width="15" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.8295 5.8295C3.39017 6.26884 3.39017 6.98116 3.8295 7.4205L6.40901 10L3.8295 12.5795C3.39017 13.0188 3.39017 13.7312 3.8295 14.1705C4.26884 14.6098 4.98116 14.6098 5.4205 14.1705L8 11.591L10.5795 14.1705C11.0188 14.6098 11.7312 14.6098 12.1705 14.1705C12.6098 13.7312 12.6098 13.0188 12.1705 12.5795L9.59099 10L12.1705 7.4205C12.6098 6.98116 12.6098 6.26884 12.1705 5.8295C11.7312 5.39017 11.0188 5.39017 10.5795 5.8295L8 8.40901L5.4205 5.8295C4.98116 5.39017 4.26884 5.39017 3.8295 5.8295Z" fill="#F00000"/>
                    </svg>
                    {{ errorMessage }}
                    </div>
                    <div v-if="emailError" class="text-validation d-flex align-items-start mt-1">            
                        <svg width="15" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.8295 5.8295C3.39017 6.26884 3.39017 6.98116 3.8295 7.4205L6.40901 10L3.8295 12.5795C3.39017 13.0188 3.39017 13.7312 3.8295 14.1705C4.26884 14.6098 4.98116 14.6098 5.4205 14.1705L8 11.591L10.5795 14.1705C11.0188 14.6098 11.7312 14.6098 12.1705 14.1705C12.6098 13.7312 12.6098 13.0188 12.1705 12.5795L9.59099 10L12.1705 7.4205C12.6098 6.98116 12.6098 6.26884 12.1705 5.8295C11.7312 5.39017 11.0188 5.39017 10.5795 5.8295L8 8.40901L5.4205 5.8295C4.98116 5.39017 4.26884 5.39017 3.8295 5.8295Z" fill="#F00000"/>
                        </svg>
                        {{ emailError }}
                    </div>
                </div>
                <div class="btn-bottom">
                    <button type="submit" class="btn-theme mt-2">Verstuur</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script src="./index.js"></script>