import { createApp } from 'vue';
import Toast from './GenericToastMessage.vue';

const ToastMessage = {
  instance: null,
  toastTimeout: null, // To keep track of the timeout

  show(message, type = 'info', background, icon, anchorTag, anchorText, anchorUrl, duration = 5000) {
    if (!this.instance) {
      const app = createApp(Toast, { message, type, background, icon, anchorTag, anchorText, anchorUrl, duration });
      const div = document.createElement('div');
      document.body.appendChild(div); // Append div to body first
      this.instance = app.mount(div);

      // Automatically remove the toast after the specified duration
      this.toastTimeout = setTimeout(() => {
        this.removeToast();
      }, duration);
    }
  },

  removeToast() {
    if (this.instance) {
      const toastElement = this.instance.$el;
    //   console.log('Removing toast:', toastElement); // Log the toast being removed
      if (document.body.contains(toastElement)) { // Check if the toast is still in the DOM
        // document.body.removeChild(toastElement);
      }
      this.instance = null; // Reset the instance
      this.toastTimeout = null; // Reset the timeout reference
    }
  },
};

export default ToastMessage;