<template>
  <div class="hap-calendar-filters">
    <!--<button @click="setFilter('all')">Alle</button>-->
    <button @click="setFilter('registered')" class="green font-quicksand-600">Ingeschreven</button>
    <button @click="setFilter('watchlist')" class="orange font-quicksand-600">Wachtlijst</button>
    <button @click="setFilter('friends')" class="yellow font-quicksand-600">Vriendjes</button>
    <button @click="setFilter('favorite')" class="purple font-quicksand-600">Favorieten</button>
  </div>

  <FullCalendar ref="calendarEl" :options="calendarOptions" />
</template>

<script>
import { toRaw } from 'vue';
import FullCalendar from '@fullcalendar/vue3';
import listWeekView from './listWeekView';

export default {
  props: {
    events: Array,
  },
  components: {
    FullCalendar,
  },
  data() {
    return {
      calendarOptions: {
        plugins: [ listWeekView ],
        timeZone: 'Europe/Amsterdam',
        locale: 'nl',
        initialView: 'listWeek',
        firstDay: 1,
        events: [],
        headerToolbar: {
          left: 'prev',
          center: 'title',
          right: 'next'
        },
        titleFormat: {
          month: 'long',
          year: 'numeric',
          day: 'numeric',
          weekday: 'short'
        },
      },
      calendar: null,
    };
  },
  watch: {
    events(newEvents) {
      const events = toRaw(newEvents)[0];
      this.displayEvents(events);
    },
  },
  mounted() {
    const events = toRaw(this.events)[0];
    this.displayEvents(events);
    
    const self = this;

    const nextBtnEl = document.querySelector('.fc-next-button');
    const prevBtnEl = document.querySelector('.fc-prev-button');

    if (nextBtnEl) {
      nextBtnEl.addEventListener('click', function() {
        self.addListClicker();
      });
    }

    if (prevBtnEl) {
      prevBtnEl.addEventListener('click', function() {
        self.addListClicker();
      });
    }
  },
  methods: {
    displayEvents(events) {
      if (!events) return;

      const evs = [];

      events.forEach((ev) => {
        evs.push({
          ...ev,
          start: ev.start.split(' ')[0],
          start_time: ev.start.split(' ')[1],
          end: ev.end.split(' ')[0],
          end_time: ev.end.split(' ')[1],
        });
      });
    
      this.calendarOptions.events = evs;

      setTimeout(() => {
        const eventsEl = document.querySelectorAll('.fc-list-week__event .fc-list-event');
    
        if (eventsEl.length) {
          Array.from(eventsEl).forEach((li) => this.addListOnClick(li));
        }
      }, 150);
      
      if (this.$refs.calendarEl) {
        this.calendar = this.$refs.calendarEl.calendar;
      }
    },
    setFilter(category) {
      this.calendarOptions.events = [];

      if (category === 'all') {
        this.calendarOptions.events = this.events;

        return;
      }

      this.calendarOptions.events = this.events[0].filter((ev) => {
        return ev.category === category;
      });

      this.addListClicker();
    },
    addListClicker() {
      setTimeout(() => {
        const eventsEl = document.querySelectorAll('.fc-list-week__event .fc-list-event');
    
        if (eventsEl.length) {
          Array.from(eventsEl).forEach((li) => this.addListOnClick(li));
        }
      }, 150);
    },
    addListOnClick(li) {
      const { event } = li.dataset;
      const data = JSON.parse(event);

      li.addEventListener('click', () => {
        this.$emit('onShowEventModal', data);
      });
    },
  },
}
</script>

<style>

</style>