<template>
  <div class="hap-header">
    <div class="container">
      <router-link to="/calendar">
        <i class="fa fa-chevron-left"></i>
      </router-link>
  
      <h4>Calender item toevoegen</h4>
  
      <div></div>
    </div>
  </div>

  <div class="container py-4">
    <div class="hap-form">
      <h4 class="hap-form__title">Algemeen</h4>
      <div class="hap-form-group">
        <label for="campName" class="form-label">Naam kamp</label>
        <input type="text" class="form-control" id="campName" />
      </div>
      <div class="hap-form-group">
        <label for="orgName" class="form-label">Naam organisatie</label>
        <input type="text" class="form-control" id="orgName" />
      </div>

      <h4 class="hap-form__title mt-4">Periode</h4>
      
      <VueDatePicker
        range
        format="MM/dd/yyyy"
        v-model="dateRange">
      </VueDatePicker>

      <h4 class="hap-form__title mt-4">Voor welk kind is dit event</h4>

      <div class="hap-form-check hap-form-check__friends mb-4">
        <div
          v-for="friend in friends"
          :key="`friend-check-${friend.id}`"
          class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            :value="friend.id"
            :id="`friend-check-${friend.id}`">
          <label class="form-check-label" :for="`friend-check-${friend.id}`">
            <img :src="friend.imageUrl || require('@/assets/images/calendar-default-avatar.png')" :alt="friend.title">
            {{ friend.name }}
          </label>
        </div>
      </div>

      <button type="button" class="btn-theme mb-3">
        Opslaan
      </button>
      <div class="text-center">
        <span
          class="color-primary cursor-pointer font-16 font-opensans-600"
        >Annuleren</span>
      </div>
    </div>
  </div>
</template>

<script src="./index.js"></script>