<template>
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12 col-md-5 col-lg-5 p-0 position-relative">
          <div class="gradient-bg-mobile">
            <h2 class="mt-5">Happy<span>Go</span></h2>
            <p class="mb-0">Welkom bij de beste kampzoeker van België!</p>
            <div class="login-illustration">
              <img class="login-shape" :src="shape" alt="shape-img"/>
            </div>
          </div>
          <div class="gradient-bg-desktop d-flex justify-content-center align-items-center">
            <div class="text-center">
              <h2>Happy<span>Go</span></h2>
              <p class="mb-0">Welkom bij de beste kampzoeker van België!</p>
              <div class="login-illustration">
                <img class="login-shape" :src="shape" alt="shape-img"/>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-5 col-lg-5 offset-md-1 offset-lg-1 mt-3 position-relative">
            <div class="mt-5 text-center p-2 center-desktop" v-if="successmessage">
            <p class="color-black font-opensans-400 font-16">{{ successmessage }}</p>
            <div class="btn-outline-primary cursor-pointer color-primary mb-3" @click="RedirectTo('/')">
              Doorgaan naar Inloggen
            </div>
          </div>
            
         
        </div>
      </div>
    </div>
  </template>
  
  <script src="./index.js"></script>