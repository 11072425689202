import Logo from '@/assets/images/LogoInverted.png';
import Avatar4 from '@/assets/images/Avatar4.png';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import GreenShape from '@/assets/images/GreenShape2.png';
import WaitingListIllustration from '@/assets/images/WaitingListIllustration.png';
import WaitingListShape from '@/assets/images/WaitingListShape.png';
import DeactivateAccount from '@/assets/images/DeactivateAccount.png';
import ToastManager from '@/components/ToastMessages/ToastManager.js';
import MobileNavigation from '@/components/MobileNavigation.vue';
import FallbackAvatar from '@/assets/images/Avatars.png';
import ToastError from '@/assets/images/ToastError.png';
import FullPageLoader from '../../components/FullPageLoader.vue';
import 'swiper/swiper-bundle.css';
import { fetchCampDetails, fetchOrganizationDetails, MarkAsFavorite, fetchAllRegistrationsFriends, shareCamps } from '@/services/apiService';
import { useRoute } from 'vue-router';
import { useAuthStore } from '@/store/authStore';
import ToastMessage from '@/components/ToastMessages/Generic/GenericToastManager';

export default {
    components: {
        Swiper,
        SwiperSlide,
        FullPageLoader,
        MobileNavigation,  
    },
    mounted() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.loadMap();
    },
    data() {
    return {
      Logo,
      WaitingListIllustration,
      WaitingListShape,
      DeactivateAccount,
      FallbackAvatar,
      favorite: false,
      GreenShape,
      Avatar4,
      loading: false,
      campDetails: null,
      registeredCampDetails: null,
      orgDetails: null,
      imgPath: process.env.VUE_APP_IMG_PATH, 
      imgPathCamp: process.env.VUE_APP_IMG_PATH_CAMP, 
      cdnPath: process.env.VUE_APP_CDN_PATH, 
      isExpanded: false,
      unsubscribe: false,
      loggedIn: false,
      isOpen: false,
      selectedImage: null,
      fullyBooked: false,
      waitingList: false,
      breakpoints: {
            1200: {
                slidesPerView: 4,
                loopedSlides: 4,
                spaceBetween: 10
            },
            1024: {
                slidesPerView: 4,
                loopedSlides: 3,
                spaceBetween: 10
            },
            768: {
                slidesPerView: 2,
                loopedSlides: 2,
                spaceBetween: 10
            },
            675: {
                slidesPerView: 1,
                loopedSlides: 1,
                spaceBetween: 20
            }
        },
        swiperOptions: {
            pagination: {
              el: '.swiper-pagination',
              clickable: true,
            },
            loop: true,
            autoplay: {
              delay: 3000,
              disableOnInteraction: false,
            },
            centeredSlides: true,
        },
        map: null,
        marker: null,
        map2: null,
        marker2: null,
        checkedItems: {},
        selectedIds: [],
        friends: [],
        selectedChild: [],
        lastRoute: ''
    };
  },
  computed: {
    truncatedText() {
      return this.campDetails?.description.length > 100 ? this.campDetails?.description.substring(0, 170) + '...' : this.campDetails?.description;
    },
  },
  created() {
    const route = useRoute();
    this.fetchCampDetail(route.params.id);
    const authStore = useAuthStore(); 
    this.lastRoute = authStore.lastRoute;
    this.fetchAllRegisteredChilds(authStore.user.parent.family_id);
  },
  methods: {
    async fetchCampDetail(id) {
      this.loading = true;
      const authStore = useAuthStore(); 

      // fetchRegisteredCampDetails(id, authStore.user?.parent?.app_user_id)
      //   .then((response) => {
      //     if (
      //       response
      //       && response.data
      //       && response.data.booking
      //       && response.data.booking.length > 0
      //       && response.data.booking[0].details
      //     ) {
      //       this.registeredCampDetails = response.data.booking[0].details;
      //     }
      //   });

      fetchCampDetails(id, authStore.user?.parent?.app_user_id)
      .then((response) => {
        this.loading = false;
        this.campDetails = response.data;
        if (response.data?.is_favorite === 'Yes') {
          this.favorite = true
        } else {
          this.favorite = false
        }
        if (response.data.status == "Volzet") {
          this.fullyBooked = true;
        }
        if (this.campDetails.media.cover.length > 0) {
          this.selectedImage = this.cdnPath + 'width=1116,height=480,fit=cover,format=auto,metadata=none' + this.imgPathCamp + this.campDetails.media.cover[0].img_url;
        } else {
          this.selectedImage = require('@/assets/images/NoCoverLg.png')
        }
      })
      .catch((error) => {
        this.loading = false;
        console.log(error.message);
      });
    },
    fetchOrganization() {
      this.loading = true;
      fetchOrganizationDetails(this.campDetails?.organization_detail?.id)
      .then((response) => {
        this.loading = false;
        this.orgDetails = response.data;
      })
      .catch((error) => {
        this.loading = false;
        console.log(error.message);
      });
    },
    imageUrl(img) {
      const imgPathCamp = process.env.VUE_APP_IMG_PATH_CAMP;
      const cdnPath = process.env.VUE_APP_CDN_PATH; 
      return cdnPath + 'width=50,height=50,fit=cover,format=auto,metadata=none' + imgPathCamp + img;
    },
    calculateYearsPassed(dateString) {
      // Parse the input date
      const inputDate = new Date(dateString);
    
      // Get the current date
      const currentDate = new Date();
    
      // Calculate the difference in years
      let yearsPassed = currentDate.getFullYear() - inputDate.getFullYear();
    
      // Check if the current date is before the given date this year
      const monthDifference = currentDate.getMonth() - inputDate.getMonth();
      const dayDifference = currentDate.getDate() - inputDate.getDate();
    
      // If the current date is before the given date this year, subtract 1 year
      if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
        yearsPassed--;
      }
    
      return yearsPassed;
    },
    displayCampPrice(subGroups) {
      if (subGroups?.length > 1) {
        let lowestPrice = Math.min(...subGroups.map(item => parseFloat(item.price !== null && item.price !== '' ? item.price : 0)));
        return `${lowestPrice.toFixed(2)}`;
      } else if (subGroups?.length === 1) {
        return `${parseFloat(subGroups[0].price).toFixed(2)}`;
      }
    },
    displayCampUsers(subGroups) {
      if (subGroups.length > 1) {
        let min_age = Math.min(...subGroups.map(item => item.min_age));
        return `Vanaf ${min_age} jaar`;
      } else if (subGroups.length === 1) {
        return `${(subGroups[0].min_age)} - ${(subGroups[0].max_age)} jaar`;
      }
    },
    displayCampDate(campDates) {
      // Check if the campDates array is empty or doesn't have the expected structure
      if (!campDates || campDates.length === 0) return "No dates available";
  
      const camp = campDates[0];
  
      // Parse the dates
      const minDate = new Date(camp.min_start_date);
      const maxDate = new Date(camp.max_start_date);
  
      // Check if the min and max dates are the same
      if (minDate.getTime() === maxDate.getTime()) {
          return this.formatDate(minDate);
      } else {
          return `${this.formatDate(minDate)} - ${this.formatDate(maxDate)}`;
      }
    },
    displayPeriodDate(campDates) {
      // Check if the campDates array is empty or doesn't have the expected structure
      if (!campDates || campDates.length === 0) return "No dates available";
  
      const camp = campDates[0];
  
      // Parse the dates
      const minDate = new Date(camp.min_start_date);
      const maxDate = new Date(camp.max_start_date);
  
      // Check if the min and max dates are the same
      if (minDate.getTime() === maxDate.getTime()) {
          return this.formatPeriodDate(minDate);
      } else {
          return `${this.formatPeriodDate(minDate)} - ${this.formatPeriodDate(maxDate)}`;
      }
    },
    formatPeriodDate(date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
    displayCareTime(start_time, end_time) {
      // Convert time strings to Date objects
      const start = new Date(`1970-01-01T${start_time}`); // Use a fixed date to convert time
      const end = new Date(`1970-01-01T${end_time}`);
  
      // Format time as "h:mm" or "h" if minutes are 0
      const formattedStart = this.formatTime(start);
      const formattedEnd = this.formatTime(end);
  
      return `Vanaf ${formattedStart} Tot ${formattedEnd}`;
    },
    formatDate(date) {
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        return date.toLocaleDateString('nl-NL', options);
    },
    displayCampTime(campData) {
      // Extract start_time and end_time from the input
      const { start_time, end_time } = campData;
  
      // Convert time strings to Date objects
      const start = new Date(`1970-01-01T${start_time}`); // Use a fixed date to convert time
      const end = new Date(`1970-01-01T${end_time}`);
  
      // Format time as "h:mm" or "h" if minutes are 0
      const formattedStart = this.formatTime(start);
      const formattedEnd = this.formatTime(end);
  
      return `${formattedStart} - ${formattedEnd}`;
    },
    formatTime(date) {
        // Extract hours and minutes
        let hours = date.getHours();
        let minutes = date.getMinutes();
    
        // Format time in "h:mm" or "h" if minutes are 0
        if (minutes === 0) {
            return `${hours}u`;
        } else {
            return `${hours}:${minutes < 10 ? '0' + minutes : minutes}u`;
        }
    },  
    displayVanaf(subGroups) {
      if (subGroups?.length > 1) {
        return true;
      } else if (subGroups?.length === 1) {
        return false;
      }
    },
    capacityCounter() {
      const capacity = this.campDetails.capacity || 0;
      const utilized = this.campDetails.utilized || 0;

      return capacity - utilized;
    },
    RedirectToGoogleMaps(){
      const lat = 51.2162;
      const lng = 4.4893;
      const googleMapsUrl = `https://www.google.com/maps?q=${lat},${lng}`;
      window.open(googleMapsUrl, '_blank');
    },
    onCampClicked() {
      this.$router.push(`/enrollment?campId=${this.campDetails.id}`);
    },
    openWebsite (url) {
      if(url) {
        window.open(url, '_blank')
      }
    },
    toggleExpand() {
        this.isExpanded = !this.isExpanded;
        this.isOpen = !this.isOpen;
    },
    Unsubscribe() {
        this.unsubscribe = !this.unsubscribe;
    },
    selectImage(image) {
      this.selectedImage = this.cdnPath + 'width=1116,height=480,fit=cover,format=auto,metadata=none' + this.imgPathCamp + image; // Update the selected image
    },
    shareOption (){
      if (navigator.share) {
        navigator.share({
          title: "HappyGo",
          text: this.campDetails?.name,
          url: window.location.href,
        })
        .then(() => {
          console.log('Share successful');
        })
        .catch((error) => {
          console.error('Error sharing:', error);
        });
      } else {
        // Fallback for browsers that do not support the Web Share API
        // alert('Sharing is not supported in this browser.');
      }
    },
    checkedFavorite() {
        this.loading = true;
        let formData = new FormData()
        const authStore = useAuthStore(); 
        formData.append("app_user_id", authStore.user?.parent?.app_user_id)
        formData.append("camp_id", this.campDetails.id)

        MarkAsFavorite(formData)
        .then((response) => {
          if (response.status == 'success') {
            this.loading = false;
            this.favorite = !this.favorite;
            const anchorTag = true;
            const anchorText = "Ga naar favorieten";
            const anchorUrl = "/registrations/3";
            const bgColor = "#F0F3DE"; //Gray Color
            ToastManager.show(response.message, 'success', bgColor, 'heart', anchorTag, anchorText, anchorUrl);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    async loadMap() {
      try {
        await this.loadGoogleMapsScript();
        this.initMap();
      } catch (err) {
        console.error('Google Maps script could not be loaded.', err);
      }
    },
    async loadGoogleMapsScript() {
      return new Promise((resolve, reject) => {
        // Check if Google Maps is already loaded
        if (window.google && window.google.maps) {
          resolve(); // Google Maps is already loaded
        } else {
          // Create script element to load Google Maps
          const script = document.createElement('script');
          script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyB41DRUbKWJHPxaFjMAwdrzWzbVKartNGg&callback=initMap`;
          script.async = true;
          script.defer = true;
          script.onerror = () => reject(new Error('Google Maps script failed to load.'));
          document.head.appendChild(script);
  
          // Define the callback function for when Google Maps is ready
          window.initMap = () => {
            resolve(); // Resolve when the script has loaded and initMap is called
          };
        }
      });
    },
    async initMap() {
      try {
        // Wait for Google Maps to be fully loaded
        const { Map } = await window.google.maps.importLibrary("maps");
        const { AdvancedMarkerElement } = await window.google.maps.importLibrary("marker");
  
        // Determine the correct container element
        let mapEl = document.getElementById('map');
        let mapEl2 = document.getElementById('map2');
        if (window.innerWidth > 992) {
          mapEl = document.querySelectorAll('.map-container')[1];
          mapEl2 = document.querySelectorAll('.map-container')[1];
        }
  
        // Initialize the map
        this.map = new Map(mapEl, {
          center: { lat: 51.2162, lng: 4.4893 },
          zoom: 14,
          mapId: "4504f8b37365c3d0", // Replace with your actual map ID if necessary
        });
        // Initialize the map
        this.map2 = new Map(mapEl2, {
          center: { lat: 51.2162, lng: 4.4893 },
          zoom: 14,
          mapId: "4504f8b37365c3d0", // Replace with your actual map ID if necessary
        });
  
        // Create and place the advanced marker
        this.marker = new AdvancedMarkerElement({
          map: this.map,
          position: { lat: 51.2162, lng: 4.4893 },
        });
        // Create and place the advanced marker
        this.marker2 = new AdvancedMarkerElement({
          map: this.map2,
          position: { lat: 51.2162, lng: 4.4893 },
        });
  
      } catch (error) {
        console.error('Error initializing the map:', error);
      }
    },
    RedirectTo(path) {
        this.$router.push(path);
    },
    async fetchAllRegisteredChilds(familyId) {
      fetchAllRegistrationsFriends(familyId)
      .then((response) => {
        this.friends = response.data;
      })
      .catch((error) => {
        console.log(error.message);
      });
    },
    sendRequest() {
      const authStore = useAuthStore(); 

      const friendArr = [];
      // Loop through the array
      this.selectedChild.forEach(item => {
        friendArr.push({
          child_id: item.id, // the selected child id
          family_id: item.family_id // the family_id from the parent object
        });
      });

      let params = {
        family_id: authStore.user.parent.family_id,
        parent_id: authStore.user.parent.id,
        url: window.location.href,
        camp_name: this.campDetails.name,
        // enrolled_childs: [1,2,3,4],
        friends: friendArr
      };

      this.loading = true;
      shareCamps(params)
      .then((response) => {
        this.loading = false;
        if (response.status == 'success') {
          this.$refs.closeIcon.click();
          ToastMessage.show(response.message, 'success');
        }
      })
      .catch((error) => {
        this.loading = false;
        ToastMessage.show(error.message, 'error');
      });
    },
    handleCheckboxChange(id) {
        // Check if the checkbox is checked
      if (this.checkedItems[id]) {
        // If checked, add the id to the array
        this.selectedIds.push(id);
      } else {
        // If unchecked, remove the id from the array
        this.selectedIds = this.selectedIds.filter(item => item !== id);
      }

      if (this.selectedIds?.length > 2) {
        // const anchorTag = false;
        // const anchorText = "";
        // const anchorUrl = "";
        // const bgColor = "#FD6767"; //Gray Color
        // ToastManager.show('limitatie max 2 gezinnen lorem ', 'error', bgColor, ToastError, anchorTag, anchorText, anchorUrl);
      }

      console.log(this.selectedIds); // Log the current selected IDs for debugging
    },
    isActivityChecked(id) {
      return this.selectedChild.some(child => child.id === id);
    },
  
    toggleActivity(child) {
      const activitySelected = this.isActivityChecked(child.id);
  
      if (activitySelected) {
        // If already selected, uncheck it (remove from the selected array)
        this.selectedChild = this.selectedChild.filter(childId => childId.id !== child.id);
      } else {
        // If not selected, check it (add to the selected array)
        this.selectedChild.push(child);
        if (this.selectedChild?.length > 2) {
          const anchorTag = false;
          const anchorText = "";
          const anchorUrl = "";
          const bgColor = "#FD6767"; //Gray Color
          ToastManager.show('Je kan maximaal 2 vriendjes per kamp mee inschrijven. ', 'error', bgColor, ToastError, anchorTag, anchorText, anchorUrl);
        }
      }
    },
    getPicture(picture) {
      if (picture?.cover.length > 0) {
        return this.cdnPath + 'width=1116,height=480,fit=cover,format=auto,metadata=none' + this.imgPathCamp + picture?.cover[0].img_url;
      } else {
        return require('@/assets/images/NoCover.png');
      }
    },
    getProfilePicture(picture) {
      return picture ? this.cdnPath + 'width=200,height=200,fit=cover,format=auto,metadata=none' + this.imgPathCamp + picture : require('@/assets/images/ChildAvatar.png');
    },
    getLogo(picture) {
      return this.imgPath + picture;
    },
    getDesktopGallery(picture) {
      return this.cdnPath + 'width=200,height=200,fit=cover,format=auto,metadata=none' + this.imgPathCamp + picture;
    }
  }
}