<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 col-md-5 col-lg-5 p-0 position-relative">
        <div class="gradient-bg-mobile">
          <h2 class="mt-5">Happy<span>Go</span></h2>
          <p class="mb-0">Welkom bij de beste kampzoeker van België!</p>
          <div class="login-illustration">
            <img class="login-shape" :src="shape" alt="shape-img"/>
          </div>
        </div>
        <div class="gradient-bg-desktop d-flex justify-content-center align-items-center">
          <div class="text-center">
            <h2>Happy<span>Go</span></h2>
            <p class="mb-0">Welkom bij de beste kampzoeker van België!</p>
            <div class="login-illustration">
              <img class="login-shape" :src="shape" alt="shape-img"/>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-5 col-lg-5 mt-5 d-flex align-items-center">
        <div v-if="errorMessage" class="text-validation d-flex align-items-start mt-1">            
              <svg width="15" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.8295 5.8295C3.39017 6.26884 3.39017 6.98116 3.8295 7.4205L6.40901 10L3.8295 12.5795C3.39017 13.0188 3.39017 13.7312 3.8295 14.1705C4.26884 14.6098 4.98116 14.6098 5.4205 14.1705L8 11.591L10.5795 14.1705C11.0188 14.6098 11.7312 14.6098 12.1705 14.1705C12.6098 13.7312 12.6098 13.0188 12.1705 12.5795L9.59099 10L12.1705 7.4205C12.6098 6.98116 12.6098 6.26884 12.1705 5.8295C11.7312 5.39017 11.0188 5.39017 10.5795 5.8295L8 8.40901L5.4205 5.8295C4.98116 5.39017 4.26884 5.39017 3.8295 5.8295Z" fill="#F00000"/>
              </svg>
              {{ errorMessage }}
            </div>

          <div class="mt-5 text-center p-2" v-if="successmessage">
            <p class="color-black font-opensans-400 font-16">{{ successmessage }}</p>
            <div class="btn-outline-primary mb-3">
            <router-link to="/">Doorgaan naar Inloggen</router-link>
           </div>
          </div>
          
       
      </div>
    </div>
  </div>
</template>

<script src="./index.js"></script>