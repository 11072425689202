const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export const API_ENDPOINTS = {
  LOGIN: `${API_BASE_URL}/user/login`,
  LOGOUT: `${API_BASE_URL}/user/logout`,
  REGISTER: `${API_BASE_URL}/user/register`,
  VERIFY: `${API_BASE_URL}/user/verify-account`,
  FOGOT_PASSWORD: `${API_BASE_URL}/user/forgot-password`,
  RESET_PASSWORD: `${API_BASE_URL}/user/reset-password`,
  //Family Endpoints
  FAMILY_LIST: `${API_BASE_URL}/family/list`,
  FAMILY_FRIENDS_LIST: `${API_BASE_URL}/registration/get-all-friends`,
  FRIENDS_LIST: `${API_BASE_URL}/family/list`,
  EDIT_FAMILY_NAME: `${API_BASE_URL}/family/update-family-name`,
  EDIT_FAMILY_REGISTRATION_NUMBER: `${API_BASE_URL}/family/update-registration_no`,
  ADD_FAMILY: `${API_BASE_URL}/family/add`,
  ADD_ACCOUNT: `${API_BASE_URL}/family/add-update-bank-account`,
  UPDATE_FAMILY: `${API_BASE_URL}/family/update-profile`,
  UPDATE_CHILD: `${API_BASE_URL}/family/update-child`,
  UPDATE_CHILD_ACTIVITY: `${API_BASE_URL}/family/update-child-activites`,
  UPDATE_MEDICAL_INFO: `${API_BASE_URL}/family/update-child-medical-info`,
  ADD_CHILD: `${API_BASE_URL}/family/add-child`,
  ADD_CHILD_ACTIVITY: `${API_BASE_URL}/family/add-child-activites`,
  ADD_MEDICAL_INFO: `${API_BASE_URL}/family/add-child-medical-info`,
  FAMILY_MEMBER_DATA: `${API_BASE_URL}/family/family-member-by-id`,
  FETCH_CHILD_DATA: `${API_BASE_URL}/family/get-child-info`,
  GET_CHILD_LIST: `${API_BASE_URL}/family/get-child-list`,
  DELETE_PROFILE: `${API_BASE_URL}/family/delete-profile`,
  GET_ALL_ACTIVITIES: `${API_BASE_URL}/activites`,
  GET_ALL_LOCATIONS: `${API_BASE_URL}/postcodes`,
  GET_ALL_ORGANIZATION: `${API_BASE_URL}/org/list`,
  // Notifications Endpoints
  NOTIFICATION: `${API_BASE_URL}/notfication`,
  DELETE_NOTIFICATION: `${API_BASE_URL}/notfication/delete`,
  //Camps Endpoints
  CAMP: `${API_BASE_URL}/camp`,
  ALL_CAMPS: `${API_BASE_URL}/camp/list`,
  ALL_SEARCH_CAMPS: `${API_BASE_URL}/camps/list`,
  CAMP_DETAILS: `${API_BASE_URL}/camp/detail`,
  REGISTERED_CAMP_DETAILS: `${API_BASE_URL}/registration/booking-detail`,
  CAMP_ORGANIZATION: `${API_BASE_URL}/camp/organization`,
  CAMP_OVERVIEW: `${API_BASE_URL}/camp-overview/camp`,
  CAMP_OVERVIEW_ORGANIZATION: `${API_BASE_URL}/camp-overview/organization`,
  MARK_AS_FAVORITE: `${API_BASE_URL}/camp/mark_favorite`,
  //Account Settings Endpoints
  CHANGE_PASSWORD: `${API_BASE_URL}/user/change-password`,
  DELETE_ACCOUNT: `${API_BASE_URL}/user/delete-account`,
  DEACTIVATE_ACCOUNT: `${API_BASE_URL}/user/deactivate-account`,
  //Registrations Endpoints
  GET_ALL_REGISTRATIONS: `${API_BASE_URL}/registration/get-all`,
  GET_ALL_BOOKING_REGISTRATIONS: `${API_BASE_URL}/registration/get-child-bookings`,
  GET_ALL_REGISTRATIONS_CHILD: `${API_BASE_URL}/registration/get-childern`,
  GET_ALL_REGISTRATIONS_FRIENDS: `${API_BASE_URL}/registration/get-all-friends`,
  SHARE_CAMP: `${API_BASE_URL}/registration/share-camp`,
  GET_ALL_ENROLLED_CHILDS: `${API_BASE_URL}/registration/get-all-enrolled-child`,
  UNSUBSCRIBE_CAMP: `${API_BASE_URL}/registration/unsubscribe`,
  UNSUBSCRIBE_CAMP_WAITINGLIST: `${API_BASE_URL}/registration/unsubscribe-watchlist`,
  //Friend Endpoints
  SEND_REQUEST: `${API_BASE_URL}/family/friend-request`,
  GET_FRIEND_REQUEST: `${API_BASE_URL}/family/friend-request`,
  ACCEPT_FRIEND_REQUEST: `${API_BASE_URL}/family/friend-request/accept`,
  REJECT_FRIEND_REQUEST: `${API_BASE_URL}/family/friend-request/reject`,
  // Calendar Endpoints
  GET_EVENTS: `${API_BASE_URL}/calendar/get-all-events`,
  //Consents Endpoints
  GET_CONSENTS: `${API_BASE_URL}/parent/consents`,
  SET_CONSENTS: `${API_BASE_URL}/parent/consents/update`,
  //Settings Endpoints
  GET_SETTINGS: `${API_BASE_URL}/settings`,
};