<template>
  <div id="app">
    <Header v-if="isAuthenticated" />
    <router-view></router-view>
  </div>
</template>

<script>
import Header from './components/Header.vue';

export default {
  name: 'App',
  components: {
    Header,
  },
  data() {
    return {
      isAuthenticated: false,
    };
  },
  created() {
    this.checkAuthentication();
  },
  methods: {
    checkAuthentication() {
      const token = localStorage.getItem('authToken');
      this.isAuthenticated = !!token;
    },
  },
}
</script>