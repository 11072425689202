import Logo from '@/assets/images/LogoInverted.png';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import GreenShape from '@/assets/images/GreenShape2.png';
import FullPageLoader from '../../components/FullPageLoader.vue';
import 'swiper/swiper-bundle.css';
import { fetchPreviewCampDetails, fetchPreviewOrganizationDetails } from '@/services/apiService';
import { useRoute } from 'vue-router';
import WaitingListIllustration from '@/assets/images/WaitingListIllustration.png';
import WaitingListShape from '@/assets/images/WaitingListShape.png';

export default {
    components: {
        Swiper,
        SwiperSlide,
        FullPageLoader,
    },
    mounted() {
      this.loadMap();
    },
    data() {
    return {
      Logo,
      GreenShape,
      WaitingListIllustration,
      WaitingListShape,
      loading: false,
      campDetails: null,
      orgDetails: null,
      selectedImage: null,
      imgPath: process.env.VUE_APP_IMG_PATH, 
      imgPathCamp: process.env.VUE_APP_IMG_PATH_CAMP, 
      cdnPath: process.env.VUE_APP_CDN_PATH, 
      isExpanded: false,
      loggedIn: false,
      isOpen: false,
      breakpoints: {
          1200: {
              slidesPerView: 4,
              loopedSlides: 4,
              spaceBetween: 10
          },
          1024: {
              slidesPerView: 4,
              loopedSlides: 3,
              spaceBetween: 10
          },
          768: {
              slidesPerView: 2,
              loopedSlides: 2,
              spaceBetween: 10
          },
          675: {
              slidesPerView: 1,
              loopedSlides: 1,
              spaceBetween: 20
          }
      },
      swiperOptions: {
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          loop: true,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
          centeredSlides: true,
      },
      map: null,
      marker: null,
      map2: null,
      marker2: null,
    };
  },
  computed: {
    truncatedText() {
      return this.campDetails?.description.length > 100 ? this.campDetails?.description.substring(0, 170) + '...' : this.campDetails?.description;
    },
  },
  created() {
    const route = useRoute();
    this.fetchCampPreview(route.params.id);
  },
  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
      this.isOpen = !this.isOpen;
    },
    openWebsite (url) {
      if(url) {
        window.open(url, '_blank')
      }
    },
    async fetchCampPreview(id) {
      this.loading = true;
      fetchPreviewCampDetails(id)
      .then((response) => {
        this.loading = false;
        this.campDetails = response.data;
        if (response.data.status == "Volzet") {
          this.fullyBooked = true;
        }
        if (this.campDetails.media.cover.length > 0) {
          this.selectedImage = this.cdnPath + 'width=1116,height=480,fit=cover,format=auto,metadata=none' + this.imgPathCamp + this.campDetails.media.cover[0].img_url;
        } else {
          this.selectedImage = require('@/assets/images/NoCoverLg.png')
        }
      })
      .catch((error) => {
        this.loading = false;
        console.log(error.message);
      });
    },
    fetchOrganization() {
      this.loading = true;
      fetchPreviewOrganizationDetails(this.campDetails?.organization_detail?.id)
      .then((response) => {
        this.loading = false;
        this.orgDetails = response.data;
      })
      .catch((error) => {
        this.loading = false;
        console.log(error.message);
      });
    },
    calculateYearsPassed(dateString) {
        // Parse the input date
        const inputDate = new Date(dateString);
      
        // Get the current date
        const currentDate = new Date();
      
        // Calculate the difference in years
        let yearsPassed = currentDate.getFullYear() - inputDate.getFullYear();
      
        // Check if the current date is before the given date this year
        const monthDifference = currentDate.getMonth() - inputDate.getMonth();
        const dayDifference = currentDate.getDate() - inputDate.getDate();
      
        // If the current date is before the given date this year, subtract 1 year
        if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
          yearsPassed--;
        }
      
        return yearsPassed;
    },
    displayCampPrice(subGroups) {
      if (subGroups?.length > 1) {
        let lowestPrice = Math.min(...subGroups.map(item => parseFloat(item.price !== null && item.price !== '' ? item.price : 0)));
        return `${lowestPrice.toFixed(2)}`;
      } else if (subGroups?.length === 1) {
        return `${parseFloat(subGroups[0].price).toFixed(2)}`;
      }
    },
    displayCampUsers(subGroups) {
      if (subGroups.length > 1) {
        let min_age = Math.min(...subGroups.map(item => item.min_age));
        return `Vanaf ${min_age} jaar`;
      } else if (subGroups.length === 1) {
        return `${(subGroups[0].min_age)} - ${(subGroups[0].max_age)} jaar`;
      }
    },
    displayCampDate(campDates) {
      // Check if the campDates array is empty or doesn't have the expected structure
      if (!campDates || campDates.length === 0) return "No dates available";
  
      const camp = campDates[0];
  
      // Parse the dates
      const minDate = new Date(camp.min_start_date);
      const maxDate = new Date(camp.max_start_date);
  
      // Check if the min and max dates are the same
      if (minDate.getTime() === maxDate.getTime()) {
          return this.formatDate(minDate);
      } else {
          return `${this.formatDate(minDate)} - ${this.formatDate(maxDate)}`;
      }
    },
    formatDate(date) {
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        return date.toLocaleDateString('nl-NL', options);
    },
    displayPeriodDate(campDates) {
      // Check if the campDates array is empty or doesn't have the expected structure
      if (!campDates || campDates.length === 0) return "No dates available";
  
      const camp = campDates[0];
  
      // Parse the dates
      const minDate = new Date(camp.min_start_date);
      const maxDate = new Date(camp.max_start_date);
  
      // Check if the min and max dates are the same
      if (minDate.getTime() === maxDate.getTime()) {
          return this.formatPeriodDate(minDate);
      } else {
          return `${this.formatPeriodDate(minDate)} - ${this.formatPeriodDate(maxDate)}`;
      }
    },
    formatPeriodDate(date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
    displayCampTime(campData) {
      // Extract start_time and end_time from the input
      const { start_time, end_time } = campData;
  
      // Convert time strings to Date objects
      const start = new Date(`1970-01-01T${start_time}`); // Use a fixed date to convert time
      const end = new Date(`1970-01-01T${end_time}`);
  
      // Format time as "h:mm" or "h" if minutes are 0
      const formattedStart = this.formatTime(start);
      const formattedEnd = this.formatTime(end);
  
      return `${formattedStart} - ${formattedEnd}`;
    },
    displayCareTime(start_time, end_time) {
      // Convert time strings to Date objects
      const start = new Date(`1970-01-01T${start_time}`); // Use a fixed date to convert time
      const end = new Date(`1970-01-01T${end_time}`);
  
      // Format time as "h:mm" or "h" if minutes are 0
      const formattedStart = this.formatTime(start);
      const formattedEnd = this.formatTime(end);
  
      return `Vanaf ${formattedStart} Tot ${formattedEnd}`;
    },
    formatTime(date) {
        // Extract hours and minutes
        let hours = date.getHours();
        let minutes = date.getMinutes();
    
        // Format time in "h:mm" or "h" if minutes are 0
        if (minutes === 0) {
            return `${hours}u`;
        } else {
            return `${hours}:${minutes < 10 ? '0' + minutes : minutes}u`;
        }
    },  
    displayVanaf(subGroups) {
      if (subGroups?.length > 1) {
        return true;
      } else if (subGroups?.length === 1) {
        return false;
      }
    },
    shareOption (){
      if (navigator.share) {
        navigator.share({
          title: "Sharing",
          text: "Sharing text",
          url: "",
        })
        .then(() => {
          console.log('Share successful');
        })
        .catch((error) => {
          console.error('Error sharing:', error);
        });
      } else {
        // Fallback for browsers that do not support the Web Share API
        // alert('Sharing is not supported in this browser.');
      }
    },
    RedirectToGoogleMaps(){
      const lat = 51.2162;
      const lng = 4.4893;
      const googleMapsUrl = `https://www.google.com/maps?q=${lat},${lng}`;
      window.open(googleMapsUrl, '_blank');
    },
    async loadMap() {
      try {
        await this.loadGoogleMapsScript();
        this.initMap();
      } catch (err) {
        console.error('Google Maps script could not be loaded.', err);
      }
    },
    async loadGoogleMapsScript() {
      return new Promise((resolve, reject) => {
        // Check if Google Maps is already loaded
        if (window.google && window.google.maps) {
          resolve(); // Google Maps is already loaded
        } else {
          // Create script element to load Google Maps
          const script = document.createElement('script');
          script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyB41DRUbKWJHPxaFjMAwdrzWzbVKartNGg&callback=initMap`;
          script.async = true;
          script.defer = true;
          script.onerror = () => reject(new Error('Google Maps script failed to load.'));
          document.head.appendChild(script);
  
          // Define the callback function for when Google Maps is ready
          window.initMap = () => {
            resolve(); // Resolve when the script has loaded and initMap is called
          };
        }
      });
    },
    async initMap() {
      try {
        // Wait for Google Maps to be fully loaded
        const { Map } = await window.google.maps.importLibrary("maps");
        const { AdvancedMarkerElement } = await window.google.maps.importLibrary("marker");
  
        // Determine the correct container element
        let mapEl = document.getElementById('map');
        let mapEl2 = document.getElementById('map2');
        if (window.innerWidth > 992) {
          mapEl = document.querySelectorAll('.map-container')[1];
          mapEl2 = document.querySelectorAll('.map-container')[1];
        }
  
        // Initialize the map
        this.map = new Map(mapEl, {
          center: { lat: 51.2162, lng: 4.4893 },
          zoom: 14,
          mapId: "4504f8b37365c3d0", // Replace with your actual map ID if necessary
        });
        // Initialize the map
        this.map2 = new Map(mapEl2, {
          center: { lat: 51.2162, lng: 4.4893 },
          zoom: 14,
          mapId: "4504f8b37365c3d0", // Replace with your actual map ID if necessary
        });
  
        // Create and place the advanced marker
        this.marker = new AdvancedMarkerElement({
          map: this.map,
          position: { lat: 51.2162, lng: 4.4893 },
        });
        // Create and place the advanced marker
        this.marker2 = new AdvancedMarkerElement({
          map: this.map2,
          position: { lat: 51.2162, lng: 4.4893 },
        });
  
      } catch (error) {
        console.error('Error initializing the map:', error);
      }
    },
    onCampClicked() {
      this.$router.push(`/enrollment?campId=${this.campDetails.id}`);
    },
    RedirectTo(path) {
        this.$router.push(path);
    },
    getPicture(picture) {
      if (picture?.cover.length > 0) {
        return this.cdnPath + 'width=1116,height=480,fit=cover,format=auto,metadata=none' + this.imgPathCamp + picture?.cover[0].img_url;
      } else {
        return require('@/assets/images/NoCover.png');
      }
    },
    getLogo(picture) {
      return this.imgPath + picture;
    },
    selectImage(image) {
      this.selectedImage = this.cdnPath + 'width=1116,height=480,fit=cover,format=auto,metadata=none' + this.imgPathCamp + image; // Update the selected image
    },
    getDesktopGallery(picture) {
      return this.cdnPath + 'width=200,height=200,fit=cover,format=auto,metadata=none' + this.imgPathCamp + picture;
    }
  }
}