<template>
  <div class="hap-calendar-filters">
    <!--<button @click="setFilter('all')">Alle</button>-->
    <button @click="setFilter('registered')" class="green font-quicksand-600">Ingeschreven</button>
    <button @click="setFilter('watchlist')" class="orange font-quicksand-600">Wachtlijst</button>
    <button @click="setFilter('friends')" class="yellow font-quicksand-600">Vriendjes</button>
    <button @click="setFilter('favorite')" class="purple font-quicksand-600">Favorieten</button>
  </div>

  <FullCalendar ref="calendarEl" :options="calendarOptions" />
</template>

<script>
import { toRaw } from 'vue';
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';

export default {
  props: {
    events: Array,
  },
  components: {
    FullCalendar,
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin],
        timeZone: 'Europe/Amsterdam',
        locale: 'nl',
        initialView: 'dayGridMonth',
        firstDay: 1,
        events: [],
        headerToolbar: {
          left: 'prev',
          center: 'title',
          right: 'next',
        },
        titleFormat: {
          month: 'long',
          year: 'numeric',
        },
        eventContent: function (arg) {
          const data = arg.event;
          const { extendedProps: props } = data;

          const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
          const dateStart = new Date(data.start);
          const dateEnd = new Date(data.end);

          const diffDays = Math.round(Math.abs((dateStart - dateEnd) / oneDay)) + 1;

          let title = '';

          if (diffDays > 1) {
            title = `<span>${data.title}</span>`;
          }

          const imgPathCamp = process.env.VUE_APP_IMG_PATH_CAMP;
          const cdnPath = process.env.VUE_APP_CDN_PATH; 

          const html = `
            <li title="${data.title}" class="fc-list-event w-100 justify-content-center ${props.category}">
              ${title}
              <div class="${props.category === 'favorite' && diffDays > 1 && (!props.children || !props.children.length) ? 'pr-2' : ''}">
                ${props.category === 'watchlist' && diffDays > 2
                  ? '<span class="badge">Wachtlijst</span>' : '' }
                ${props.category === 'favorite' && (!props.children || !props.children.length)
                  ? '<i class="fa-regular fa-heart"></i>' : '' }
                ${props.children && props.children.length
                  ? `
                    <div class="fc-list-week__event-friends">
                      ${props.children.map((friend, index) => {
                        let imgEl = '';
                        let imgUrl = friend.profile_image
                          ? cdnPath + 'width=50,height=50,fit=cover,format=auto,metadata=none' + imgPathCamp + friend.profile_image
                          : require('@/assets/images/calendar-default-avatar.png');
                        const endCount = diffDays > 1 ? 2 : 1;
                        if (index < endCount) {
                          imgEl += `<img
                            class="item-${index+1} ${friend.profile_image ? 'bordered' : ''}"
                            src="${imgUrl}"
                            title="${friend.name || friend.friend_name || data.title}"
                            alt="${friend.name || friend.friend_name || data.title}"
                            height="20" />`;
                        }

                        if (index === endCount) {
                          imgEl += `<div
                            class="item-${index+1} more-friends"
                            title="${friend.name || data.title}"
                          >+${props.children && props.children.length - endCount}</div>`;
                        }

                        return imgEl;
                      }).join('')}
                    </div>
                  `
                  : ''}
              </div>
            </li>
          `;
          return { html };
        },
      },
      calendar: null,
    };
  },
  watch: {
    events(newEvents) {
      const events = toRaw(newEvents)[0];

      this.displayEvents(events);
    },
  },
  mounted() {
    const events = toRaw(this.events)[0];
    this.displayEvents(events);
  },
  methods: {
    displayEvents(events) {
      // if (!events || !events.length) return;

      const self = this;
      this.calendarOptions.events = events;
      this.calendarOptions.eventDidMount = (arg) => {
        const data = arg.event;
        const el = arg.el;
        el.addEventListener('click', () => self.onClickEvent(data));
      };

      if (this.$refs.calendarEl) {
        this.calendar = this.$refs.calendarEl.calendar;
      }
    },
    setFilter(category) {
      this.calendarOptions.events = [];

      if (category === 'all') {
        this.calendarOptions.events = this.events;

        return;
      }

      this.calendarOptions.events = this.events[0].filter((ev) => {
        return ev.category === category;
      });
    },
    onClickEvent(ev) {
      this.$emit('onShowEventModal', ev);
    }
  },
};
</script>

<style>
</style>