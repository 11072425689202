<template>
    <FullPageLoader :isLoading="loading" />

    <div class="header-mobile-profile">
        <div class="header-mobile">
            <img class="imageClass" src="@/assets/images/AppCompoundShape.png" alt="top-header"/>
        </div>
        <div class="header-shape-text">
            <router-link class="back-arrow" to="/more">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0607 7.06066C16.6464 6.47487 16.6464 5.52513 16.0607 4.93934C15.4749 4.35355 14.5251 4.35355 13.9393 4.93934L7.93934 10.9393C7.35355 11.5251 7.35355 12.4749 7.93934 13.0607L13.9393 19.0607C14.5251 19.6464 15.4749 19.6464 16.0607 19.0607C16.6464 18.4749 16.6464 17.5251 16.0607 16.9393L11.1213 12L16.0607 7.06066Z" fill="white"/>
                    <mask id="mask0_4603_993" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="7" y="4" width="10" height="16">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0607 7.06066C16.6464 6.47487 16.6464 5.52513 16.0607 4.93934C15.4749 4.35355 14.5251 4.35355 13.9393 4.93934L7.93934 10.9393C7.35355 11.5251 7.35355 12.4749 7.93934 13.0607L13.9393 19.0607C14.5251 19.6464 15.4749 19.6464 16.0607 19.0607C16.6464 18.4749 16.6464 17.5251 16.0607 16.9393L11.1213 12L16.0607 7.06066Z" fill="white"/>
                    </mask>
                    <g mask="url(#mask0_4603_993)">
                    </g>
                </svg>
            </router-link>
        </div>
        <div class="header-shape-text d-flex justify-content-center align-items-center">
            <h4 class="font-20 font-quicksand-600 mb-0">Connectie maken!</h4>
        </div>
    </div>
    <div class="container mt-3 overflow-auto">
        <div class="show-desktop">
            <div>
                <router-link to="/more" class="d-flex align-items-center">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7071 4.70711C11.0976 4.31658 11.0976 3.68342 10.7071 3.29289C10.3166 2.90237 9.68342 2.90237 9.29289 3.29289L5.29289 7.29289C4.90237 7.68342 4.90237 8.31658 5.29289 8.70711L9.29289 12.7071C9.68342 13.0976 10.3166 13.0976 10.7071 12.7071C11.0976 12.3166 11.0976 11.6834 10.7071 11.2929L7.41421 8L10.7071 4.70711Z" fill="url(#paint0_linear_5796_16232)"/>
                    <mask id="mask0_5796_16232" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="5" y="3" width="6" height="10">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7071 4.70711C11.0976 4.31658 11.0976 3.68342 10.7071 3.29289C10.3166 2.90237 9.68342 2.90237 9.29289 3.29289L5.29289 7.29289C4.90237 7.68342 4.90237 8.31658 5.29289 8.70711L9.29289 12.7071C9.68342 13.0976 10.3166 13.0976 10.7071 12.7071C11.0976 12.3166 11.0976 11.6834 10.7071 11.2929L7.41421 8L10.7071 4.70711Z" fill="white"/>
                    </mask>
                    <g mask="url(#mask0_5796_16232)">
                    </g>
                    <defs>
                    <linearGradient id="paint0_linear_5796_16232" x1="5" y1="3" x2="13.8235" y2="8.29412" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FC425C"/>
                    <stop offset="1" stop-color="#FE8C71"/>
                    </linearGradient>
                    </defs>
                    </svg>
                    <span class="margin-left-5 color-primary2 font-16 font-opensans-600">
                        Terug
                    </span>
                </router-link>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mt-4 mb-3">
                <h4 class="font-20 font-quicksand-600 mb-0 text-center">{{details?.parent?.first_name}} wil een connectie maken!</h4>
            </div>
            <div class="col-12 mt-4 mb-3">
                <div class="d-flex justify-content-center">
                    <div>
                        <div class="d-flex justify-content-center w-100">
                            <div class="family-card-image">
                                <img class="imageClass" :src="getProfilePicture(details?.child?.profile_image)" alt="profile-image"/>
                            </div>
                        </div>
                        <h5 class="font-20 font-quicksand-600 color-black mt-2">{{details?.child?.first_name}} {{details?.child?.last_name}}</h5>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <p class="font-16 font-opensans-400 text-center">Welk kind mag gelinked worden met {{details?.request_to_parent?.first_name}}?</p>
            </div>     
            <div class="col-6 col-lg-3"
                v-for="child in familyChild"
                :key="child.id" @click="toggleActivity(child.id)">
                <div class="family-card" :class="{ 'border-red': isActivityChecked(child.id) && details?.status === 'pending', 'opacity-50': isMaxSelected && !isActivityChecked(child.id) && details?.status === 'pending' }">
                    <div>
                        <div class="family-card-image position-relative">
                            <img class="imageClass" :src="getProfilePicture(child.profile_image, 4)" alt="profile-image"/>
                            <div class="radio-group checkbox-lg-group d-flex mb-2" v-if="details?.status === 'pending'">
                                <input type="checkbox" class="cursor-pointer custom-checkbox-lg mt-2" 
                                :checked="isActivityChecked(child.id)"
                                :disabled="isMaxSelected && !isActivityChecked(child.id)">
                            </div>
                        </div>
                        <h5>{{ child.first_name + ' ' + child.last_name }}</h5>
                    </div>
                </div>
            </div>          
            <div class="col-12 show-mobile mt-3" v-if="details?.status === 'pending'">
                <button type="button" class="btn-theme mt-2" @click="acceptRequest">Verzoek accepteren</button>
            </div>                                 
            <div class="col-12 show-mobile mt-2">
                <button type="button" class="btn-theme-outline btn-theme-outline-hover-white color-primary mt-2" @click="RedirectTo('/view-request-child-profile/' + details?.child_id)">Bekijk profiel</button>
            </div>                                 
            <div class="col-12 show-mobile text-center mt-3 mb-3" v-if="details?.status === 'pending'">
                <span class="color-primary font-16 font-opensans-500 cursor-pointer" data-bs-toggle="modal" data-bs-target="#deleteModal">Verzoek negeren</span>
            </div>                        
            <div class="show-till-ipad mb-5">
                <div class="d-flex justify-content-center align-items-center">
                    <button type="button" class="btn-theme w-auto mt-2 margin-right-10" v-if="details?.status === 'pending'" @click="acceptRequest">Verzoek accepteren</button>
                    <button type="button" class="margin-right-10 w-auto btn-theme-outline btn-theme-outline-hover-white color-primary mt-2" @click="RedirectTo('/view-request-child-profile/' + details?.child_id)">Bekijk profiel</button>
                    <span class="color-primary font-16 font-opensans-500 cursor-pointer" v-if="details?.status === 'pending'" data-bs-toggle="modal" data-bs-target="#deleteModal">Verzoek negeren</span>
                </div>     
            </div>    
        </div>
    </div>

    <!-- Delete Account Modal -->
    <div class="modal fade font-quicksand-500" id="deleteModal" tabindex="-1" aria-labelledby="deleteModal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="position-relative">
                        <a class="modal-cross-icon cursor-pointer" data-bs-dismiss="modal">
                            <svg width="19" height="22" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.8295 4.3295C3.39017 4.76884 3.39017 5.48116 3.8295 5.9205L6.40901 8.5L3.8295 11.0795C3.39017 11.5188 3.39017 12.2312 3.8295 12.6705C4.26884 13.1098 4.98116 13.1098 5.4205 12.6705L8 10.091L10.5795 12.6705C11.0188 13.1098 11.7312 13.1098 12.1705 12.6705C12.6098 12.2312 12.6098 11.5188 12.1705 11.0795L9.59099 8.5L12.1705 5.9205C12.6098 5.48116 12.6098 4.76884 12.1705 4.3295C11.7312 3.89017 11.0188 3.89017 10.5795 4.3295L8 6.90901L5.4205 4.3295C4.98116 3.89017 4.26884 3.89017 3.8295 4.3295Z" fill="#4A4A4A"/>
                            </svg>
                        </a>
                    </div>
                    <div class="container">
                        <div class="text-center">
                            <h5 class="mt-3 modal-title font-quicksand-600 font-24 mb-1" id="descriptionModalLabel">Ben je zeker dat je Mike wil verwijderen als vriend?</h5>
                            <!-- <p class="font-16 font-opensans-400">Ben je zeker dat je jouw account wil deactiveren? Je account zal binnen de 12 uur offline gehaald worden.</p> -->
                        </div>
                        <div class="d-flex justify-content-center align-items-center">
                            <div>
                                <img class="imageClass" src="../../assets/images/DeleteAccount.png" alt="deactivate-account-pic"/>
                            </div>
                        </div>
                        <div class="text-center mb-3">
                            <button type="button" class="btn-theme mt-2" @click="rejectRequest" data-bs-dismiss="modal">Vriend verwijderen</button>
                        </div>
                        <div class="text-center mb-3">
                            <span class="color-primary font-16 font-opensans-600" data-bs-dismiss="modal">Annuleer</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="./index.js"></script>