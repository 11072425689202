import ChildAvatar from '../../assets/images/ChildAvatar.png';
import ParentAvatar from '../../assets/images/ParentAvatar.png';
import OtherAvatar from '../../assets/images/OtherAvatar.png';
import MobileNavigation from '../../components/MobileNavigation.vue';
import FullPageLoader from '../../components/FullPageLoader.vue';
import { getAllChildrenList, getAllFamilyFriends, getAllFamilyMembers, sendFriendRequest, updateFamilyName } from '@/services/apiService';
import { useAuthStore } from '@/store/authStore';
import ToastMessage from '@/components/ToastMessages/Generic/GenericToastManager.js';

export default {
  components: {
    MobileNavigation,
    FullPageLoader
  },
  data() {
    return {
      showProfileViewFamily: true,
      showProfileViewFriends: true,
      otherLogin: false,
      familyMembersAvatars: [
        { id: 1, name: 'Kind', pic: ChildAvatar },
        { id: 2, name: 'Ouder', pic: ParentAvatar },
        { id: 3, name: 'Andere', pic: OtherAvatar },
      ],
      friends: [],
      familyChild: [],
      email: '',
      emailError: '',
      childrenError: '',
      checkedItems: {},
      familyMembers: [],
      otherMembers: [],
      Members: [],
      familyName: '',
      familyNameError: '',
      userInfo: '',
      selectedMember: null,
      selectedIndex: 0,
      loading: false,
      imgPath: process.env.VUE_APP_IMG_PATH, 
      imgPathCamp: process.env.VUE_APP_IMG_PATH_CAMP, 
      cdnPath: process.env.VUE_APP_CDN_PATH, 
      placeholder: ParentAvatar,
      placeholderOther: OtherAvatar,
      placeholderChild: ChildAvatar,
      currentTab: 0,
      selectedChild: [],
      tabs: [
        { id: 'family-tab-pane', label: 'Mijn gezin' },
        { id: 'friends-tab-pane', label: 'Mijn vrienden' }
      ],
    };
  },
  created() {
    const authStore = useAuthStore(); 
    this.userInfo = authStore.user;
    if (authStore?.notificationsInterval == null) {
      authStore.startNotificationsInterval(authStore.user?.id);
    }

    if (this.userInfo.parent?.account_no) {
      this.showProfileViewFamily = false;
    }
    sessionStorage.removeItem('family_id');
    this.fetchFamilyMembers();
    this.fetchFriends(authStore.user?.parent?.family_id);
  },
  computed: {
    isMaxSelected() {
      return this.selectedChild.length >= 1;
    },
  },
  methods: {
    isActivityChecked(id) {
      return this.selectedChild.includes(id);
    },
    toggleActivity(id) {
      const activitySelected = this.selectedChild.includes(id);

      if (this.isMaxSelected && !activitySelected) {
        // If the max selection is reached and the activity is not already selected, do nothing
        return;
      }

      if (activitySelected) {
        // If already selected, uncheck it
        this.selectedChild = this.selectedChild.filter(childId => childId !== id);
      } else {
        // If not selected, check it
        this.selectedChild.push(id);
      }
    },
    setTab(index) {
      this.currentTab = index;
    }, 
    async fetchFamilyMembers() {
      this.loading = true;
      getAllFamilyMembers(this.userInfo.id)
      .then((response) => {
        this.loading = false;
        this.familyMembers = response.data;
        // let friendsWithChildren = [];
        // if (response.data.length > 0) {
        //   response.data[0].children.forEach(child => {
        //       // For each child, iterate over their friends
        //       child.friends.forEach(friend => {
        //           // Check if the friend already exists in the friends array
        //           let friendEntry = friendsWithChildren.find(f => f.friend_id === friend.friend_id);
                  
        //           // If the friend doesn't exist in the array, add a new entry
        //           if (!friendEntry) {
        //               friendsWithChildren.push({
        //                   friend_id: friend.friend_id,
        //                   friend_first_name: friend.friend_first_name,
        //                   friend_last_name: friend.friend_last_name,
        //                   children: [] // Initialize an empty children array
        //               });
        //               friendEntry = friendsWithChildren[friendsWithChildren.length - 1]; // Get the newly added friend entry
        //           }
                  
        //           // Add the current child to the friend's children list (if not already added)
        //           const existingChild = friendEntry.children.find(c => c.id === child.id);
        //           if (!existingChild) {
        //               friendEntry.children.push({
        //                   id: child.id,
        //                   first_name: child.first_name,
        //                   last_name: child.last_name,
        //                   profile_image: child.profile_image
        //               });
        //           }
        //       });
        //   });
        // }

        // this.friends = friendsWithChildren;
        // if (friendsWithChildren.length > 0) {
        //   this.showProfileViewFriends = false
        // }
      })
      .catch(() => {
        this.loading = false;
        // console.log(error.message);
      });
    },
    async fetchFriends(id) {
      getAllFamilyFriends(id)
      .then((response) => {
        this.friends = response.data;
        if (this.friends.length > 0) {
          this.showProfileViewFriends = false
        }
      })
      .catch(() => {
        // console.log(error.message);
      });
    },
    setFamilyName(member, index) {
      this.selectedMember = member;
      this.selectedIndex = index
      this.familyName = this.selectedMember?.family_name;
    },
    setFamilyId(family_id) {
      sessionStorage.setItem('family_id', family_id)
    },
    getChildList() {
      this.loading = true;
      getAllChildrenList(this.userInfo.parent.family_id)
      .then((response) => {
        this.loading = false;
        this.familyChild = response.data;
      })
      .catch((error) => {
        this.loading = false;
        console.log(error.message);
      });
    },
    getMarginStyle(row) {
      if (row.length === 1) {
        return { marginRight: '0' };
      } else {
        return { marginRight: '-15px' };
      }
    },
    validate() {
      this.emailError = '';
      this.childrenError = '';
      
      if (!this.email) {
        this.emailError = 'E-mail is vereist';
      } else if (!this.validEmail(this.email)) {
        this.emailError = 'E-mail is ongeldig';
      }

      if (this.selectedChild.length < 1 && this.selectedChild.length > 1) {
        this.childrenError = 'Selecteer 1 kind';
      }
      return !(this.emailError || this.childrenError); // return true if no errors
    },
    validEmail(email) {
      // Regex to validate the basic structure of an email
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    
      // Check if the email matches the basic structure regex
      if (!emailRegex.test(email)) {
        return false;
      }
    
      // Check for consecutive dots
      if (/\.{2,}/.test(email)) {
        return false;
      }
    
      // Check if the email starts with a dot
      if (email.startsWith('.')) {
        return false;
      }
    
      // Check if the email ends with a dot
      if (email.endsWith('.')) {
        return false;
      }
    
      // Check if there is a dot immediately before the '@'
      const [localPart, domain] = email.split('@'); // Split the email at the '@' symbol
      if (localPart.endsWith('.') || domain.startsWith('.') || localPart.endsWith('-') || domain.startsWith('-')) {
        return false;
      }
      // If all checks pass, return true
      return true;
    },
    sendRequest() {
      if (this.validate()) {

        this.loading = true;
        let formData = new FormData()
        formData.append("email", this.email)
        formData.append("child_id", this.selectedChild[0])
        formData.append("parent_id", this.userInfo.parent.id)
        formData.append("family_id", this.userInfo.parent.family_id)

        sendFriendRequest(formData)
        .then((response) => {
          if (response.status == 'success') {
            this.$refs.friendCloseModal.click();
            this.loading = false;
            this.selectedChild = []
            this.email = ''
            ToastMessage.show(response.message, 'success');
          }
        })
        .catch((error) => {
          this.loading = false;
          ToastMessage.show(error.message, 'error');
        });
      }
    },
    redirectToFirstParentForm() {
      if(this.familyMembers.length > 0) {
        const authStore = useAuthStore()
        this.$router.push('/update-parent-profile/' + authStore.user?.parent?.id + "/0");
      }
    },
    redirectToForm(name) {
      if (name === "Ouder") {
        this.$router.push('/parent-profile-form');
      }
      if (name === "Andere") {
        this.$router.push('/other-profile-form');
      }
      if (name === "Kind") {
        this.$router.push('/child-profile-form');
      }
    },
    // handleCheckboxChange(id) {
    //   // Optional: Additional logic can be added here if needed
    //   if (this.checkedItems[id]) {
    //     if (!this.selectedIds.includes(id)) {
    //       this.selectedIds.push(id);
    //     }
    //   } else {
    //     this.selectedIds = this.selectedIds.filter(item => item !== id);
    //   }
    // },
    RedirectTo(path) {
      this.$router.push(path);
    },
    validateEditForm() {
      this.familyNameError = '';
      if (!this.familyName) {
        this.familyNameError = 'Vereist';
      }
  
      return !(this.familyNameError); // return true if no errors
    },
    handleEditFormSubmit() {
      if (this.validateEditForm()) {
        this.loading = true;
        let params = {
          family_id: this.selectedMember?.family_id,
          family_name: this.familyName,
          user_id:  this.userInfo.id
        }
        updateFamilyName(params)
        .then((response) => {
          if (response.status == 'success') {
            this.$refs.closeIcon.click();
            this.loading = false;
            this.fetchFamilyMembers();
            ToastMessage.show(response.message, 'success');
          }
        })
        .catch((error) => {
          this.loading = false;
          ToastMessage.show(error.message, 'error');
        });
      }
    },
    getProfilePicture(picture, role) {
      return picture ? this.cdnPath + 'width=200,height=200,fit=cover,format=auto,metadata=none' + this.imgPathCamp + picture : role === 3 ? this.placeholderOther : role === 4 ? this.placeholderChild : this.placeholder;
    },
  }
}