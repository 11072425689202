<template>
    <!-- Friend Request Modal -->
    <div class="modal fade font-opensans-400" id="FriendRequestModal" tabindex="-1" aria-labelledby="FriendRequestModal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="d-flex justify-content-center position-relative">
                        <div class="text-center">
                            <h5 class="mt-2 modal-title font-quicksand-600 font-24" id="descriptionModalLabel">Thomas wil een connectie maken!</h5>
                        </div>
                        <a class="modal-cross-icon cursor-pointer" data-bs-dismiss="modal">
                            <svg width="19" height="22" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.8295 4.3295C3.39017 4.76884 3.39017 5.48116 3.8295 5.9205L6.40901 8.5L3.8295 11.0795C3.39017 11.5188 3.39017 12.2312 3.8295 12.6705C4.26884 13.1098 4.98116 13.1098 5.4205 12.6705L8 10.091L10.5795 12.6705C11.0188 13.1098 11.7312 13.1098 12.1705 12.6705C12.6098 12.2312 12.6098 11.5188 12.1705 11.0795L9.59099 8.5L12.1705 5.9205C12.6098 5.48116 12.6098 4.76884 12.1705 4.3295C11.7312 3.89017 11.0188 3.89017 10.5795 4.3295L8 6.90901L5.4205 4.3295C4.98116 3.89017 4.26884 3.89017 3.8295 4.3295Z" fill="#4A4A4A"/>
                            </svg>
                        </a>
                    </div>
                      <div class="row">
                        <div class="col-12 mt-4 mb-3">
                            <div class="d-flex justify-content-center">
                                <div>
                                    <div class="">
                                        <img class="imageClass" src="../../assets/images/Avatar6.png" alt="profile-image"/>
                                    </div>
                                    <h5 class="font-20 font-quicksand-600 color-black mt-2">Mike Mintjens</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <p class="font-16 font-opensans-400 text-center">Welk kind mag gelinked worden met Thomas?</p>
                        </div>     
                        <div class="col-6"
                            v-for="item in friends"
                            :key="item.id">
                            <div class="family-card" :class="{ 'border-red': checkedItems[item.id] }">
                                <div>
                                    <div class="family-card-image position-relative">
                                        <img class="imageClass" :src="item.pic" alt="profile-image"/>
                                        <div class="radio-group checkbox-lg-group d-flex mb-2">
                                            <input type="checkbox" class="cursor-pointer custom-checkbox-lg mt-2" 
                                            :value="item.id"
                                            v-model="checkedItems[item.id]"
                                            @change="handleCheckboxChange(item.id)">
                                        </div>
                                    </div>
                                    <h5>{{ item.name }}</h5>
                                </div>
                            </div>
                        </div>          
                        <div class="col-12 mt-3">
                            <button type="button" class="btn-theme mt-2">Verzoek accepteren</button>
                        </div>                                 
                        <div class="col-12 mt-2">
                            <button type="button" class="btn-theme-outline btn-theme-outline-hover color-primary mt-2" @click="RedirectTo('view-child-profile/1')">Bekijk profiel</button>
                        </div>                                 
                        <div class="col-12 text-center mt-3 mb-3">
                            <span class="color-primary font-16 font-opensans-500 cursor-pointer" data-bs-toggle="modal" data-bs-target="#deleteModal">Verzoek negeren</span>
                        </div>                                 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Babysit from '../../assets/images/Babysit.png';
import Grandparent from '../../assets/images/Grandparent.png';

export default {
  data() {
    return {
        checkedItems: {},
        friends: [
            { id: 2, name: 'Linde De Laet', pic: Babysit },
            { id: 3, name: 'Pascale Thys', pic: Grandparent },
        ],
    };
  },
  methods: {
    handleCheckboxChange(id) {
      console.log(`Checkbox for item ${id} changed. Current state: ${this.checkedItems[id]}`);
    },
  }
}
</script>