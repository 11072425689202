import { AddFamilyMember } from '@/services/apiService';
import FullPageLoader from '../../../components/FullPageLoader.vue';
import { useAuthStore } from '@/store/authStore';
import ToastMessage from '@/components/ToastMessages/Generic/GenericToastManager';
import { Modal } from 'bootstrap';

export default {
  components: {
    FullPageLoader
  },
  data() {
    return {
        id: null,
        firstName: '',
        lastName: '',
        email: '',
        telephone: '',
        role: '',
        firstNameError: '',
        lastNameError: '',
        emailError: '',
        telephoneError: '',
        roleError: '',
        profilePictureError: '',
        isViewerAccount: true,
        isNotificationsChecked: true,
        fileName: '',
        fileSize: '',
        selectedFile: null,
        imageUrl: '',
        familyId: 0,
        userData: '',
        isUploading: false,
        loading: false,
        uploadProgress: '0 KB', // Initially set to 0 KB
        timeRemaining: 10, // Simulate a 10-second upload process
    };
  },
  mounted () {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  },
  created() {
    const authStore = useAuthStore(); 
    this.userData = authStore.user;
    this.familyId = sessionStorage.getItem('family_id');
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    deletePortfolio() {
      this.fileName = '';
      this.fileSize = 0;
      this.selectedFile = null;
      this.imageUrl = '';
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        // Set initial file info
        this.fileName = file.name;
        this.fileSize = (file.size / 1024 / 1024).toFixed(4); // Convert to MB
        this.selectedFile = file;

        // Simulate file upload process
        this.startUploadSimulation();
      } else {
        this.fileName = '';
        this.fileSize = 0;
      }
    },
    // Simulate the file upload process
    startUploadSimulation() {
      this.isUploading = true;
      this.uploadProgress = '0 KB';
      this.timeRemaining = 10; // Reset the time for each upload

      // Simulate upload progress over 2 seconds (adjust this for your needs)
      let simulatedProgress = 0;
      const interval = setInterval(() => {
        simulatedProgress += 100; // Increase progress in KB
        this.uploadProgress = `${simulatedProgress} KB / ${this.fileSize * 1024} KB`;
        this.timeRemaining--;

        if (this.timeRemaining <= 0) {
          // Stop the simulation after 2 seconds (this is where the "upload" finishes)
          clearInterval(interval);
          this.finishUpload();
        }
      }, 200); // Update every 200 ms for the upload simulation
    },

    // Finish the upload (image is now fully uploaded)
    finishUpload() {
      this.isUploading = false;
      this.imageUrl = URL.createObjectURL(this.selectedFile); // Create a URL for the selected file
    },
    updateNotificationCheckedState(event) {
      this.isNotificationsChecked = event.target.checked;
    },
    updateViewerCheckedState(event) {
      this.isViewerAccount = event.target.checked;
    },
    validate() {
      this.firstNameError = '';
      this.lastNameError = '';
      this.emailError = '';
      this.telephoneError = '';
      this.profilePictureError = '';
      
      if (!this.firstName) {
        this.firstNameError = 'Vereist';
      }
  
      if (!this.lastName) {
          this.lastNameError = 'Vereist';
      }
    
      if (!this.email) {
          this.emailError = 'Vereist';
      } else if (!this.validEmail(this.email)) {
        this.emailError = 'E-mail is ongeldig';
      }

     // Validate profile picture
     if (this.selectedFile) {
      const allowedTypes = ['image/jpeg', 'image/png'];
      if (!allowedTypes.includes(this.selectedFile.type)) {
        this.profilePictureError = 'Profielfoto moet een JPEG- of PNG-bestand zijn';
      }
    }

      return !(this.firstNameError || this.lastNameError || this.emailError || this.profilePictureError); // return true if no errors
    },
    validEmail(email) {
      const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/; // Simple regex for email validation
      return regex.test(email);
    },
    RedirectTo(path) {
      this.$router.push(path);
    },
    handleSubmit() {
      if (this.validate()) {
        this.loading = true;
        let formData = new FormData()
        formData.append("email", this.email)
        formData.append("first_name", this.firstName)
        formData.append("last_name", this.lastName)
        formData.append("phone_no", this.telephone)
        formData.append("member_role", this.role)
        formData.append("viewer_account", this.isViewerAccount ? 1 : 0)
        formData.append("notifications", this.isNotificationsChecked ? 1 : 0)
        formData.append("profile_picture", this.selectedFile)
        formData.append("parent_id", this.userData.parent?.id)
        formData.append("family_id", this.familyId)

        AddFamilyMember(formData, 3, this.familyId)
        .then((response) => {
          if (response.status == 'success') {
            this.loading = false;
            this.id = response.data?.id;
            // ToastMessage.show(response.message, 'success');
            const modal = new Modal(document.querySelector('#successModal'));
            modal.show();
          }
        })
        .catch((error) => {
          this.loading = false;
          window.scrollTo({ top: 0, behavior: 'smooth' });
          ToastMessage.show(error.message, 'error');
        });
      }
    }
  }
}