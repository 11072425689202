import AppCompoundShape from '@/assets/images/AppCompoundShape.png';
import Logo from '@/assets/images/LogoInverted.png';
import MobileNavigation from '@/components/MobileNavigation.vue';
import EmailPreferences from '@/components/Modals/EmailPreferences.vue';
import Subscription from '@/components/Modals/Subscription.vue';
import Contact from '@/components/Modals/Contact.vue';
import SocialSecurity from '@/components/Modals/SocialSecurity.vue';
import FullPageLoader from '@/components/FullPageLoader.vue';
import { useAuthStore } from '@/store/authStore';
import {
  logoutForAllUser,
  getNotifications,
  readNotification,
  getChildData,
  deleteNotification,
} from '@/services/apiService';

export default {
  components: {
    MobileNavigation,
    EmailPreferences,
    Contact,
    Subscription,
    FullPageLoader,
    SocialSecurity,
  },
  data() {
    return {
      AppCompoundShape,
      Logo,
      user: null,
      child: null,
      loading: false,
      notifications: [],
    };
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem('loggedin_user'));

    this.fetchNotifications();

    const urlParams = new URLSearchParams(window.location.search);
    const fillRegistrationNumber = urlParams.get('fillRegistrationNumber');
    const childId = urlParams.get('childId');

    if (fillRegistrationNumber && childId) {
      this.getChildInfo(childId);
    }
  },
  methods: {
    async fetchNotifications() {
      this.loading = true;

      if (!this.user) return;

      getNotifications(this.user.id)
        .then((response) => {
          this.loading = false;
          this.notifications = response.data;
          console.log(this.notifications);
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getChildInfo(id) {
      this.loading = true;

      try {
        const res = await getChildData(id);
        
        this.child = res.data;

        if (res && res.user && res.user.toLowerCase() !== 'yes') {
          const button = document.querySelector('#socialSecurityToggle');

          if (button) {
            button.click();
          }
        } else {
          console.log('Show info user already fill registration number');
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        this.loading = false;
      }
    },
    getNotificationIcon(icon) {
      let iconResult;

      try {
        iconResult = require(`@/assets/icons/ic-notification-${icon}.svg`);
      } catch (e) {
        iconResult = require('@/assets/icons/ic-notification-fallback.svg');
      }

      return iconResult;
    },
    onClickNotification(n) {
      if (n.url) {
        window.location.href = n.url;
      }

      if (!this.user) return;

      if (n.is_read == 0) {
        readNotification(n)
        .then(() => {
          this.fetchNotifications();
        })
        .catch((error) => {
          console.log(error.message);
        });
      }
    },
    removeNotification(notificationId) {
      this.loading = true;
      const authStore = useAuthStore()

      let formData = new FormData()
      formData.append('id', notificationId)
      formData.append('app_user_id', authStore.user?.parent?.app_user_id)
      deleteNotification(formData)
      .then(() => {
        this.loading = false;
        this.notifications = this.notifications.filter(notification => notification.id !== notificationId);
      })
      .catch((error) => {
        this.loading = false;
        console.log(error.message);
      });
    },
    RedirectTo(path) {
      this.$router.push(path);
    },
    logout(){
      const authStore = useAuthStore();
      
      logoutForAllUser()
        .then(() => {
          authStore.logout(); 
          window.location.href = "/"; // Redirect to login page
        })
        .catch((error) => {
          console.error('Login failed:', error);
          this.errorMessage = error.message || 'Inloggen mislukt. Probeer het opnieuw.';
        });
    },
    formatDate(date) {
      return new Intl.DateTimeFormat('nl-nl', { dateStyle: 'long' }).format(new Date(date));
    },
  }
}