import { register } from '../../services/apiService';
import FullPageLoader from '../../components/FullPageLoader.vue';

export default {
  components: {
    FullPageLoader,
  },
  data() {
    return {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        passwordVisible: false,
        confirmPasswordVisible: false,
        firstNameError: '',
        lastNameError: '',
        emailError: '',
        passwordError: [],
        confirmPasswordError: '',
        errorMessage: '',
        consent: false,
        loading: false,
        weakPassword: ''
    };
  },
  methods: {
    showPassword() {
        this.passwordVisible = !this.passwordVisible;
    },
    showConfirmPassword() {
        this.confirmPasswordVisible = !this.confirmPasswordVisible;
    },
    validate() {
        this.firstNameError = '';
        this.lastNameError = '';
        this.emailError = '';
        this.passwordError = [];
        this.confirmPasswordError = '';
        this.weakPassword = '';

        // Regex to check for at least one special character
        const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
        const capitalLetterRegex = /[A-Z]/;
        const numberRegex = /[0-9]/;
        
        if (!this.firstName) {
          this.firstNameError = 'Voornaam is required';
        }
        if (!this.lastName) {
          this.lastNameError = 'Naam is required';
        }

        if (!this.email) {
          this.emailError = 'E-mail is vereist';
        } else if (!this.validEmail(this.email)) {
          this.emailError = 'E-mail is ongeldig';
        }
        
        if (!this.password) {
          this.passwordError.push({error: true, message: 'Wachtwoord is vereist'});
        }
        if (this.password.length < 8) {
          this.passwordError.push({error: true, message: 'Je wachtwoord moet minstens 8 karakters bevatten'});
        } else {
          this.passwordError.push({error: false, message: 'Je wachtwoord moet minstens 8 karakters bevatten'});
        }
        if (!specialCharRegex.test(this.password)) {
          this.passwordError.push({error: true, message: 'Je wachtwoord moet minstens één speciaal karakter bevatten'});
        } else {
          this.passwordError.push({error: false, message: 'Je wachtwoord moet minstens één speciaal karakter bevatten'});
        }
        if (!capitalLetterRegex.test(this.password)) {
          this.passwordError.push({error: true, message: 'Je wachtwoord moet minstens één hoofdletter bevatten'});
        } else {
          this.passwordError.push({error: false, message: 'Je wachtwoord moet minstens één hoofdletter bevatten'});
        }
        if (!numberRegex.test(this.password)) {
          this.passwordError.push({error: true, message: 'Je wachtwoord moet minstens één cijfer bevatten'});
        } else {
          this.passwordError.push({error: false, message: 'Je wachtwoord moet minstens één cijfer bevatten'});
        }
    
        let countError = 0;
        this.passwordError.forEach(element => {
          if (element.error === true) {
            countError += 1;
          }
        });

        if (countError === 0) {
          this.weakPassword = 'strong'
        } else if (countError > 0 && countError <= 2) {
          this.weakPassword = 'medium'
        } else if (countError > 2) {
          this.weakPassword = 'weak'
        }

        if (!this.confirmPassword) {
            this.confirmPasswordError = 'Confirm Wachtwoord is vereist';
        } else if (this.password !== this.confirmPassword) {
            this.confirmPasswordError = 'Wachtwoorden komen niet overeen';
        }
  
        return !(this.emailError || this.passwordError.length === 0 || this.confirmPasswordError || this.firstNameError || this.lastNameError); // return true if no errors
    },
    validEmail(email) {
      // Regex to validate the basic structure of an email
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    
      // Check if the email matches the basic structure regex
      if (!emailRegex.test(email)) {
        return false;
      }
    
      // Check for consecutive dots
      if (/\.{2,}/.test(email)) {
        return false;
      }
    
      // Check if the email starts with a dot
      if (email.startsWith('.')) {
        return false;
      }
    
      // Check if the email ends with a dot
      if (email.endsWith('.')) {
        return false;
      }
    
      // Check if there is a dot immediately before the '@'
      const [localPart, domain] = email.split('@'); // Split the email at the '@' symbol
       if (localPart.endsWith('.') || domain.startsWith('.') || localPart.endsWith('-') || domain.startsWith('-')) {
        return false;
      }
      // If all checks pass, return true
      return true;
    },
    handleSubmit() {
      if (this.validate()) {
        const data = {
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          password: this.password
        }
           // Logic to handle login
           this.loading = true;
           register(data)
             .then((data) => {
               this.loading = false;
               sessionStorage.setItem('successMessage', data.message); // Store message temporarily
               this.$router.push('/success');
             })
             .catch((error) => {
               this.loading = false;
               this.errorMessage = error.message || 'Inloggen mislukt. Probeer het opnieuw.';
             });
      }
    }
  }
}