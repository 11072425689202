import { ref, toRaw } from 'vue';
import { Modal } from 'bootstrap';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import AppCompoundShape from '@/assets/images/AppCompoundShape.png';
import Logo from '@/assets/images/LogoInverted.png';
import NoCover from '@/assets/images/NoCover.png';

import MobileNavigation from '@/components/MobileNavigation.vue';
import FullPageLoader from '@/components/FullPageLoader.vue';

import WeekView from './WeekView';
import MonthView from './MonthView';
import YearView from './YearView';

import { useAuthStore } from '@/store/authStore';
import { getAllFamilyMembers, fetchCalendarEvents } from '@/services/apiService';

export default {
  components: {
    VueDatePicker,
    MobileNavigation,
    FullPageLoader,
    WeekView,
    MonthView,
    YearView,
  },
  data() {
    return {
      AppCompoundShape,
      Logo,
      NoCover,
      imgPathCamp: process.env.VUE_APP_IMG_PATH_CAMP,
      cdnPath: process.env.VUE_APP_CDN_PATH,
      userInfo: '',
      tabState: 1,
      weekViewKey: 0,
      monthViewKey: 0,
      events: [],
      eventsBk: [],
      children: [],
      eventModal: null,
      eventModalData: null,
      filterModal: null,
      filterDateRange: ref(),
      isLoading: false,
      isFiltered: false,
    };
  },
  created() {
    const authStore = useAuthStore(); 
    this.userInfo = authStore.user;

    this.fetchEvents();
    this.fetchFamilyMembers();
  },
  mounted() {
    this.eventModal = new Modal(document.querySelector('#eventModal'));
    this.filterModal = new Modal(document.querySelector('#filterModal'));
  },
  methods: {
    async fetchEvents(query = null) {
      this.isLoading = true;

      const { id, app_user_id, family_id } = this.userInfo.parent;

      try {
        const res = await fetchCalendarEvents({
          parentId: id,
          appUserId: app_user_id,
          familyId: family_id,
          params: query !== null ? query : {}
        });

        if (res && res.data) {
          this.events = res.data;
        }
      } catch (error) {
        console.log('ERROR Fetching calendar events: ', error);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchFamilyMembers() {
      this.isLoading = true;

      try {
        const response = await getAllFamilyMembers(this.userInfo.id);

        if (!response.data || !response.data[0]) return;

        this.children = [
          ...response.data[0].children.map((c) => ({
            ...c,
            selected: false,
          })),
        ];
      } catch (error) {
        console.log(error.message);
      } finally {
        this.isLoading = false;
      }
    },
    resetFilters() {
      this.isFiltered = false;
      this.filterDateRange = ref(null);
      this.children.forEach(child => {
        const checkbox = document.getElementById(`friend-check-${child.id}`);
        if (checkbox) {
          checkbox.checked = false; // Uncheck the checkbox
        }
      });
      this.fetchEvents();
    },
    doFilter() {
      if (this.filterDateRange) {
        this.isFiltered = true;
        const rawChildren = toRaw(this.children);
        const rawDate = toRaw(this.filterDateRange);
  
        const start_date = rawDate[0].toISOString().split('T')[0];
        const end_date = rawDate[1].toISOString().split('T')[0];
        const children = rawChildren.filter((c) => c.selected);
        
        this.fetchEvents({
          start_date,
          end_date,
          child_id: [...children.map((c) => c.id)].join(','),
        });
  
        this.toggleFilter();
      } else {
        this.isFiltered = true;
        const rawChildren = toRaw(this.children);
        const children = rawChildren.filter((c) => c.selected);
        
        this.fetchEvents({
          child_id: [...children.map((c) => c.id)].join(','),
        });
  
        this.toggleFilter();
      }
    },
    setDateEvent(id) {
      const filteredArr = this.events.flat().filter(item => item.booking_id === id);
      if (filteredArr?.length > 0) {
        const formatDate = (dateStr) => {
          const date = new Date(dateStr);
          const day = String(date.getDate()).padStart(2, '0');
          const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
          ];
          const month = monthNames[date.getMonth()]; // Get the month name in English
          const year = date.getFullYear();
          return `${day} ${month} ${year}`;
        };
    
        const startFormatted = formatDate(filteredArr[0]?.start);
        const endFormatted = formatDate(filteredArr[0]?.end);
        return `${startFormatted} - ${endFormatted}`;
      }
      return '-';
    },
    
    setTimeEvent(id) {
      const filteredArr = this.events.flat().filter(item => item.booking_id === id);
      if (filteredArr?.length > 0) {
        const formatTime = (dateStr) => {
          const date = new Date(dateStr);
          const hours = date.getHours();
          return `${hours}u`; // Just use the hour value and append 'u'
        };
    
        const startFormatted = formatTime(filteredArr[0]?.start);
        const endFormatted = formatTime(filteredArr[0]?.end);
        return `${startFormatted} - ${endFormatted}`;
      }
      return '-';
    },
    toggleChildFilter(index) {
      this.children[index].selected = !this.children[index].selected;
    },
    changeTabState(tab) {
      this.tabState = tab;

      if (tab === 1) {
        this.weekViewKey += 1;
      }

      if (tab === 2) {
        this.monthViewKey += 1;
      }
    },
    onShowEventModal(data) {
      this.eventModalData = data;
      this.eventModal.show();
    },
    formatDate(date) {
      return new Intl.DateTimeFormat('nl-nl', { dateStyle: 'long' }).format(new Date(date));
    },
    formatTime(date) {
      return new Date(date).getHours() + 'u';
    },
    isWatchList() {
      if (this.eventModalData && this.eventModalData.extendedProps.category === 'watchlist') {
        return true;
      }

      return false;
    },
    addEvent() {
      this.$router.push('/calendar/add-event');
    },
    toggleFilter() {
      this.filterModal.toggle();
    },
    imageUrl(url) {
      const cdn = process.env.VUE_APP_CDN_PATH;
      const path = process.env.VUE_APP_IMG_PATH_CAMP;
      return `${cdn}width=200,height=200,fit=cover,format=auto,metadata=none${path}${url}`;
    },
  }
}