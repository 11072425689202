<template>
    <FullPageLoader :isLoading="loading" />
    <div class="header-mobile">
        <img class="imageClass" src="@/assets/images/AppCompoundShape.png" alt="top-header"/>
    </div>
    <div class="header-shape-text">
        <router-link class="back-arrow" to="/">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0607 7.06066C16.6464 6.47487 16.6464 5.52513 16.0607 4.93934C15.4749 4.35355 14.5251 4.35355 13.9393 4.93934L7.93934 10.9393C7.35355 11.5251 7.35355 12.4749 7.93934 13.0607L13.9393 19.0607C14.5251 19.6464 15.4749 19.6464 16.0607 19.0607C16.6464 18.4749 16.6464 17.5251 16.0607 16.9393L11.1213 12L16.0607 7.06066Z" fill="white"/>
                <mask id="mask0_4603_993" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="7" y="4" width="10" height="16">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0607 7.06066C16.6464 6.47487 16.6464 5.52513 16.0607 4.93934C15.4749 4.35355 14.5251 4.35355 13.9393 4.93934L7.93934 10.9393C7.35355 11.5251 7.35355 12.4749 7.93934 13.0607L13.9393 19.0607C14.5251 19.6464 15.4749 19.6464 16.0607 19.0607C16.6464 18.4749 16.6464 17.5251 16.0607 16.9393L11.1213 12L16.0607 7.06066Z" fill="white"/>
                </mask>
                <g mask="url(#mask0_4603_993)">
                </g>
            </svg>
        </router-link>
    </div>
    <div class="header-shape-text d-flex justify-content-center align-items-center">
        <h4 class="font-20 font-quicksand-600 mb-0">Creëer nieuw account</h4>
    </div>
    <div class="container container-create-account mt-4 mt-md-6">
        <div class="row p-2">
            <div>
                <p class="color-black font-opensans-400 font-16">Maak snel een account aan en start met het bekijken van leuke kampen voor uw kind(eren)!</p>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12">
                <form @submit.prevent="handleSubmit" class="mt-3">
                  <div v-if="errorMessage" class="text-validation d-flex align-items-start mt-1">            
              <svg width="15" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.8295 5.8295C3.39017 6.26884 3.39017 6.98116 3.8295 7.4205L6.40901 10L3.8295 12.5795C3.39017 13.0188 3.39017 13.7312 3.8295 14.1705C4.26884 14.6098 4.98116 14.6098 5.4205 14.1705L8 11.591L10.5795 14.1705C11.0188 14.6098 11.7312 14.6098 12.1705 14.1705C12.6098 13.7312 12.6098 13.0188 12.1705 12.5795L9.59099 10L12.1705 7.4205C12.6098 6.98116 12.6098 6.26884 12.1705 5.8295C11.7312 5.39017 11.0188 5.39017 10.5795 5.8295L8 8.40901L5.4205 5.8295C4.98116 5.39017 4.26884 5.39017 3.8295 5.8295Z" fill="#F00000"/>
              </svg>
              {{ errorMessage }}
            </div>
                <h3 class="font-20 color-black font-quicksand-600 mt-3 mb-2">Gegevens</h3>
                <div class="row">
                  <div class="form-group col-12 col-md-6 col-lg-6">
                 
                  <label for="firstName" class="input-label">Voornaam</label>
                  <input type="text" class="form-control form-input" maxlength="50" v-model="firstName" />
                  <div v-if="firstNameError" class="text-validation d-flex align-items-center mt-1">            
                    <svg width="15" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.8295 5.8295C3.39017 6.26884 3.39017 6.98116 3.8295 7.4205L6.40901 10L3.8295 12.5795C3.39017 13.0188 3.39017 13.7312 3.8295 14.1705C4.26884 14.6098 4.98116 14.6098 5.4205 14.1705L8 11.591L10.5795 14.1705C11.0188 14.6098 11.7312 14.6098 12.1705 14.1705C12.6098 13.7312 12.6098 13.0188 12.1705 12.5795L9.59099 10L12.1705 7.4205C12.6098 6.98116 12.6098 6.26884 12.1705 5.8295C11.7312 5.39017 11.0188 5.39017 10.5795 5.8295L8 8.40901L5.4205 5.8295C4.98116 5.39017 4.26884 5.39017 3.8295 5.8295Z" fill="#F00000"/>
                    </svg>
                    {{ firstNameError }}
                  </div>
                </div>
                <div class="form-group col-12 col-md-6 col-lg-6">
                  <label for="lastName" class="input-label">Naam</label>
                  <input type="text" class="form-control form-input" maxlength="50" v-model="lastName" />
                  <div v-if="lastNameError" class="text-validation d-flex align-items-center mt-1">            
                    <svg width="15" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.8295 5.8295C3.39017 6.26884 3.39017 6.98116 3.8295 7.4205L6.40901 10L3.8295 12.5795C3.39017 13.0188 3.39017 13.7312 3.8295 14.1705C4.26884 14.6098 4.98116 14.6098 5.4205 14.1705L8 11.591L10.5795 14.1705C11.0188 14.6098 11.7312 14.6098 12.1705 14.1705C12.6098 13.7312 12.6098 13.0188 12.1705 12.5795L9.59099 10L12.1705 7.4205C12.6098 6.98116 12.6098 6.26884 12.1705 5.8295C11.7312 5.39017 11.0188 5.39017 10.5795 5.8295L8 8.40901L5.4205 5.8295C4.98116 5.39017 4.26884 5.39017 3.8295 5.8295Z" fill="#F00000"/>
                    </svg>
                    {{ lastNameError }}
                  </div>
                </div>
                <div class="form-group col-12 col-md-6 col-lg-6">
                  <label for="email" class="input-label">E-mailadres</label>
                  <input type="text" class="form-control form-input" v-model="email" />
                  <div v-if="emailError" class="text-validation d-flex align-items-center mt-1">            
                    <svg width="15" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.8295 5.8295C3.39017 6.26884 3.39017 6.98116 3.8295 7.4205L6.40901 10L3.8295 12.5795C3.39017 13.0188 3.39017 13.7312 3.8295 14.1705C4.26884 14.6098 4.98116 14.6098 5.4205 14.1705L8 11.591L10.5795 14.1705C11.0188 14.6098 11.7312 14.6098 12.1705 14.1705C12.6098 13.7312 12.6098 13.0188 12.1705 12.5795L9.59099 10L12.1705 7.4205C12.6098 6.98116 12.6098 6.26884 12.1705 5.8295C11.7312 5.39017 11.0188 5.39017 10.5795 5.8295L8 8.40901L5.4205 5.8295C4.98116 5.39017 4.26884 5.39017 3.8295 5.8295Z" fill="#F00000"/>
                    </svg>
                    {{ emailError }}
                  </div>
                </div>
                <h3 class="font-20 color-black font-quicksand-600 mt-4">Creëer wachtwoord</h3>
                <div class="form-group col-12 col-md-6 col-lg-6">
                    <label for="password" class="input-label">Nieuw wachtwoord</label>
                    <div class="password-group">              
                    <svg class="lock-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.3125 18.125C4.88281 18.125 4.5151 17.9736 4.20937 17.6708C3.90312 17.3674 3.75 17.003 3.75 16.5774V8.83929C3.75 8.41369 3.90312 8.04923 4.20937 7.74589C4.5151 7.44308 4.88281 7.29167 5.3125 7.29167H6.09375V5.74405C6.09375 4.67361 6.47474 3.76103 7.23672 3.00631C7.99818 2.2521 8.91927 1.875 10 1.875C11.0807 1.875 12.0021 2.2521 12.7641 3.00631C13.5255 3.76103 13.9062 4.67361 13.9062 5.74405V7.29167H14.6875C15.1172 7.29167 15.4852 7.44308 15.7914 7.74589C16.0971 8.04923 16.25 8.41369 16.25 8.83929V16.5774C16.25 17.003 16.0971 17.3674 15.7914 17.6708C15.4852 17.9736 15.1172 18.125 14.6875 18.125H5.3125ZM5.3125 16.5774H14.6875V8.83929H5.3125V16.5774ZM10 14.256C10.4297 14.256 10.7977 14.1045 11.1039 13.8017C11.4096 13.4984 11.5625 13.1339 11.5625 12.7083C11.5625 12.2827 11.4096 11.9183 11.1039 11.6149C10.7977 11.3121 10.4297 11.1607 10 11.1607C9.57031 11.1607 9.2026 11.3121 8.89688 11.6149C8.59063 11.9183 8.4375 12.2827 8.4375 12.7083C8.4375 13.1339 8.59063 13.4984 8.89688 13.8017C9.2026 14.1045 9.57031 14.256 10 14.256ZM7.65625 7.29167H12.3438V5.74405C12.3438 5.09921 12.1159 4.55109 11.6602 4.0997C11.2044 3.64831 10.651 3.42262 10 3.42262C9.34896 3.42262 8.79557 3.64831 8.33984 4.0997C7.88411 4.55109 7.65625 5.09921 7.65625 5.74405V7.29167Z" fill="#4A4A4A"/>
                    </svg>
                    <input :type="passwordVisible ? 'text' : 'password'" class="form-control form-input pl-left-40px" v-model="password" />
                    <!-- <div v-if="passwordError" class="text-validation d-flex align-items-center mt-1">            
                      <svg width="15" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.8295 5.8295C3.39017 6.26884 3.39017 6.98116 3.8295 7.4205L6.40901 10L3.8295 12.5795C3.39017 13.0188 3.39017 13.7312 3.8295 14.1705C4.26884 14.6098 4.98116 14.6098 5.4205 14.1705L8 11.591L10.5795 14.1705C11.0188 14.6098 11.7312 14.6098 12.1705 14.1705C12.6098 13.7312 12.6098 13.0188 12.1705 12.5795L9.59099 10L12.1705 7.4205C12.6098 6.98116 12.6098 6.26884 12.1705 5.8295C11.7312 5.39017 11.0188 5.39017 10.5795 5.8295L8 8.40901L5.4205 5.8295C4.98116 5.39017 4.26884 5.39017 3.8295 5.8295Z" fill="#F00000"/>
                      </svg>
                      {{ passwordError }}
                    </div> -->
                    <div v-if="weakPassword == 'weak'" class="text-validation-weak d-flex align-items-center mt-1">            
                      <svg width="15" height="15" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.69762 14.5C1.42682 14.5 1.22665 14.3796 1.09714 14.1389C0.967621 13.8981 0.967621 13.6574 1.09714 13.4167L7.63185 1.86111C7.76137 1.62037 7.96742 1.5 8.25 1.5C8.53258 1.5 8.73863 1.62037 8.86815 1.86111L15.4029 13.4167C15.5324 13.6574 15.5324 13.8981 15.4029 14.1389C15.2733 14.3796 15.0732 14.5 14.8024 14.5H1.69762ZM8.25 6.55556C8.04984 6.55556 7.88217 6.62465 7.747 6.76283C7.61136 6.9015 7.54354 7.07315 7.54354 7.27778V9.44444C7.54354 9.64907 7.61136 9.82048 7.747 9.95867C7.88217 10.0973 8.04984 10.1667 8.25 10.1667C8.45016 10.1667 8.61806 10.0973 8.7537 9.95867C8.88887 9.82048 8.95645 9.64907 8.95645 9.44444V7.27778C8.95645 7.07315 8.88887 6.9015 8.7537 6.76283C8.61806 6.62465 8.45016 6.55556 8.25 6.55556ZM8.25 12.3333C8.45016 12.3333 8.61806 12.264 8.7537 12.1253C8.88887 11.9871 8.95645 11.8157 8.95645 11.6111C8.95645 11.4065 8.88887 11.2351 8.7537 11.0969C8.61806 10.9582 8.45016 10.8889 8.25 10.8889C8.04984 10.8889 7.88217 10.9582 7.747 11.0969C7.61136 11.2351 7.54354 11.4065 7.54354 11.6111C7.54354 11.8157 7.61136 11.9871 7.747 12.1253C7.88217 12.264 8.04984 12.3333 8.25 12.3333ZM2.91626 13.0556H13.5837L8.25 3.66667L2.91626 13.0556Z" fill="#939393"/>
                      </svg>
                      <span class="pt-1">Zwak</span>
                    </div>   
                    <div v-if="weakPassword == 'medium'" class="text-validation-medium d-flex align-items-center mt-1">            
                      <svg width="15" height="15" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.69762 14.5C1.42682 14.5 1.22665 14.3796 1.09714 14.1389C0.967621 13.8981 0.967621 13.6574 1.09714 13.4167L7.63185 1.86111C7.76137 1.62037 7.96742 1.5 8.25 1.5C8.53258 1.5 8.73863 1.62037 8.86815 1.86111L15.4029 13.4167C15.5324 13.6574 15.5324 13.8981 15.4029 14.1389C15.2733 14.3796 15.0732 14.5 14.8024 14.5H1.69762ZM8.25 6.55556C8.04984 6.55556 7.88217 6.62465 7.747 6.76283C7.61136 6.9015 7.54354 7.07315 7.54354 7.27778V9.44444C7.54354 9.64907 7.61136 9.82048 7.747 9.95867C7.88217 10.0973 8.04984 10.1667 8.25 10.1667C8.45016 10.1667 8.61806 10.0973 8.7537 9.95867C8.88887 9.82048 8.95645 9.64907 8.95645 9.44444V7.27778C8.95645 7.07315 8.88887 6.9015 8.7537 6.76283C8.61806 6.62465 8.45016 6.55556 8.25 6.55556ZM8.25 12.3333C8.45016 12.3333 8.61806 12.264 8.7537 12.1253C8.88887 11.9871 8.95645 11.8157 8.95645 11.6111C8.95645 11.4065 8.88887 11.2351 8.7537 11.0969C8.61806 10.9582 8.45016 10.8889 8.25 10.8889C8.04984 10.8889 7.88217 10.9582 7.747 11.0969C7.61136 11.2351 7.54354 11.4065 7.54354 11.6111C7.54354 11.8157 7.61136 11.9871 7.747 12.1253C7.88217 12.264 8.04984 12.3333 8.25 12.3333ZM2.91626 13.0556H13.5837L8.25 3.66667L2.91626 13.0556Z" fill="#939393"/>
                      </svg>
                      <span class="pt-1">Medium</span>
                    </div>   
                    <div v-if="weakPassword == 'strong'" class="text-validation-strong d-flex align-items-center mt-1">            
                      <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2585 4.32906C11.6291 3.91954 12.2615 3.88795 12.671 4.2585C13.0805 4.62905 13.1121 5.26143 12.7415 5.67095L7.3125 11.671C6.90047 12.1263 6.17917 12.1062 5.7932 11.6285L3.22224 8.44694C2.87512 8.01738 2.94195 7.38775 3.37152 7.04063C3.80108 6.69351 4.43071 6.76034 4.77783 7.18991L6.61394 9.46211L11.2585 4.32906Z" fill="#76B683"/>
                          <mask id="mask0_3649_1010" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="3" y="4" width="10" height="8">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2585 4.32906C11.6291 3.91954 12.2615 3.88795 12.671 4.2585C13.0805 4.62905 13.1121 5.26143 12.7415 5.67095L7.3125 11.671C6.90047 12.1263 6.17917 12.1062 5.7932 11.6285L3.22224 8.44694C2.87512 8.01738 2.94195 7.38775 3.37152 7.04063C3.80108 6.69351 4.43071 6.76034 4.77783 7.18991L6.61394 9.46211L11.2585 4.32906Z" fill="white"/>
                          </mask>
                          <g mask="url(#mask0_3649_1010)">
                          </g>
                      </svg>
                      <span>Strek</span>
                    </div>   
                    <div v-for="(error, index) in passwordError" :key="index">      
                      <div v-if="error.error === true" class="text-validation d-flex align-items-center mt-1">            
                        <svg width="15" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M3.8295 5.8295C3.39017 6.26884 3.39017 6.98116 3.8295 7.4205L6.40901 10L3.8295 12.5795C3.39017 13.0188 3.39017 13.7312 3.8295 14.1705C4.26884 14.6098 4.98116 14.6098 5.4205 14.1705L8 11.591L10.5795 14.1705C11.0188 14.6098 11.7312 14.6098 12.1705 14.1705C12.6098 13.7312 12.6098 13.0188 12.1705 12.5795L9.59099 10L12.1705 7.4205C12.6098 6.98116 12.6098 6.26884 12.1705 5.8295C11.7312 5.39017 11.0188 5.39017 10.5795 5.8295L8 8.40901L5.4205 5.8295C4.98116 5.39017 4.26884 5.39017 3.8295 5.8295Z" fill="#F00000"/>
                        </svg>
                        {{ error.message }}
                      </div>
                      <div v-if="error.error === false" class="text-validation-success d-flex align-items-center mt-1">            
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2585 4.32906C11.6291 3.91954 12.2615 3.88795 12.671 4.2585C13.0805 4.62905 13.1121 5.26143 12.7415 5.67095L7.3125 11.671C6.90047 12.1263 6.17917 12.1062 5.7932 11.6285L3.22224 8.44694C2.87512 8.01738 2.94195 7.38775 3.37152 7.04063C3.80108 6.69351 4.43071 6.76034 4.77783 7.18991L6.61394 9.46211L11.2585 4.32906Z" fill="#76B683"/>
                          <mask id="mask0_3649_1010" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="3" y="4" width="10" height="8">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2585 4.32906C11.6291 3.91954 12.2615 3.88795 12.671 4.2585C13.0805 4.62905 13.1121 5.26143 12.7415 5.67095L7.3125 11.671C6.90047 12.1263 6.17917 12.1062 5.7932 11.6285L3.22224 8.44694C2.87512 8.01738 2.94195 7.38775 3.37152 7.04063C3.80108 6.69351 4.43071 6.76034 4.77783 7.18991L6.61394 9.46211L11.2585 4.32906Z" fill="white"/>
                          </mask>
                          <g mask="url(#mask0_3649_1010)">
                          </g>
                        </svg>
                        {{ error.message }}
                      </div>
                    </div>
                    <span class="eye-icon" @click="showPassword">
                        <img :src="require(`@/assets/icons/ic-eye${passwordVisible ? '' : '-slash'}.svg`)">
                    </span>
                    </div>
                </div>
                <div class="form-group col-12 col-md-6 col-lg-6">
                    <label for="password" class="input-label">Herhaal nieuw wachtwoord</label>
                    <div class="password-group">              
                    <svg class="lock-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.3125 18.125C4.88281 18.125 4.5151 17.9736 4.20937 17.6708C3.90312 17.3674 3.75 17.003 3.75 16.5774V8.83929C3.75 8.41369 3.90312 8.04923 4.20937 7.74589C4.5151 7.44308 4.88281 7.29167 5.3125 7.29167H6.09375V5.74405C6.09375 4.67361 6.47474 3.76103 7.23672 3.00631C7.99818 2.2521 8.91927 1.875 10 1.875C11.0807 1.875 12.0021 2.2521 12.7641 3.00631C13.5255 3.76103 13.9062 4.67361 13.9062 5.74405V7.29167H14.6875C15.1172 7.29167 15.4852 7.44308 15.7914 7.74589C16.0971 8.04923 16.25 8.41369 16.25 8.83929V16.5774C16.25 17.003 16.0971 17.3674 15.7914 17.6708C15.4852 17.9736 15.1172 18.125 14.6875 18.125H5.3125ZM5.3125 16.5774H14.6875V8.83929H5.3125V16.5774ZM10 14.256C10.4297 14.256 10.7977 14.1045 11.1039 13.8017C11.4096 13.4984 11.5625 13.1339 11.5625 12.7083C11.5625 12.2827 11.4096 11.9183 11.1039 11.6149C10.7977 11.3121 10.4297 11.1607 10 11.1607C9.57031 11.1607 9.2026 11.3121 8.89688 11.6149C8.59063 11.9183 8.4375 12.2827 8.4375 12.7083C8.4375 13.1339 8.59063 13.4984 8.89688 13.8017C9.2026 14.1045 9.57031 14.256 10 14.256ZM7.65625 7.29167H12.3438V5.74405C12.3438 5.09921 12.1159 4.55109 11.6602 4.0997C11.2044 3.64831 10.651 3.42262 10 3.42262C9.34896 3.42262 8.79557 3.64831 8.33984 4.0997C7.88411 4.55109 7.65625 5.09921 7.65625 5.74405V7.29167Z" fill="#4A4A4A"/>
                    </svg>
                    <input :type="confirmPasswordVisible ? 'text' : 'password'" class="form-control form-input pl-left-40px" v-model="confirmPassword" />
                    <div v-if="confirmPasswordError" class="text-validation d-flex align-items-center mt-1">            
                      <svg width="15" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.8295 5.8295C3.39017 6.26884 3.39017 6.98116 3.8295 7.4205L6.40901 10L3.8295 12.5795C3.39017 13.0188 3.39017 13.7312 3.8295 14.1705C4.26884 14.6098 4.98116 14.6098 5.4205 14.1705L8 11.591L10.5795 14.1705C11.0188 14.6098 11.7312 14.6098 12.1705 14.1705C12.6098 13.7312 12.6098 13.0188 12.1705 12.5795L9.59099 10L12.1705 7.4205C12.6098 6.98116 12.6098 6.26884 12.1705 5.8295C11.7312 5.39017 11.0188 5.39017 10.5795 5.8295L8 8.40901L5.4205 5.8295C4.98116 5.39017 4.26884 5.39017 3.8295 5.8295Z" fill="#F00000"/>
                      </svg>
                      {{ confirmPasswordError }}
                    </div>
                    <span class="eye-icon" @click="showConfirmPassword">
                        <img :src="require(`@/assets/icons/ic-eye${confirmPasswordVisible ? '' : '-slash'}.svg`)">
                    </span>
                    </div>
                </div>
                </div>
                <div class="btn-bottom-createaccount-mg">
                    <div class="d-flex mb-2">
                        <input type="checkbox" class="cursor-pointer custom-checkbox mt-2" id="terms" name="terms" v-model="consent">
                        <label for="terms" class="font-14 font-opensans-400 margin-left-10 mt-2 cursor-pointer">Ik geef toestemming dat ... </label>
                    </div>
                    <div class="text-end">
                    <button type="submit" class="btn-theme btn-max mt-2" :disabled="!consent">Account aanmaken</button>
                    </div>
                </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script src="./index.js"></script>