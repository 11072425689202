import { AddAccountNumber, getFamilyMemberData, UpdateFamilyMember } from '@/services/apiService';
import FullPageLoader from '../../../components/FullPageLoader.vue';
import { useAuthStore } from '@/store/authStore';
import ToastMessage from '@/components/ToastMessages/Generic/GenericToastManager';
import { useRoute } from 'vue-router';
import { Modal } from 'bootstrap';

export default {
  components: {
    FullPageLoader
  },
  data() {
    return {
        id: null,
        role: null,
        firstName: '',
        lastName: '',
        email: '',
        telephone: '',
        registerationNumber: '',
        accountNumber: '',
        streetName: '',
        busNumber: '',
        postcode: '',
        localAuthority: '',
        firstNameError: '',
        lastNameError: '',
        emailError: '',
        telephoneError: '',
        accountNumberError: '',
        profilePictureError: '',
        streetNameError: '',
        busNumberError: '',
        localAuthorityError: '',
        postcodeError: '',
        isFiscalCoParent: true,
        isNotificationsChecked: true,
        fileName: '',
        fileSize: '',
        selectedFile: null,
        imageUrl: '',
        isUploading: false,
        loading: false,
        imgPath: process.env.VUE_APP_IMG_PATH, 
        familyId: 0,
        app_user_id: 0,
        userData: '',
        uploadProgress: '0 KB', // Initially set to 0 KB
        timeRemaining: 10, // Simulate a 10-second upload process
        currentTab: 0,
        tabs: [
            { id: 'general-info-tab-pane', label: 'Algemeen' },
            { id: 'payment-tab-pane', label: 'Betalingsinfo' },
        ],
    };
  },
  created() {
    const authStore = useAuthStore(); 
    const route = useRoute();
    this.userData = authStore.user;
    this.id = route.params.id;
    this.currentTab = parseInt(route.params.tab);
    this.fetchFamilyMemberData(route.params.id);
  },
  mounted () {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  },
  methods: {
    async fetchFamilyMemberData(id) {
      this.loading = true;
      getFamilyMemberData(id)
      .then((response) => {
        this.loading = false;
        const data = response.data;
        if (data) {
          this.id = data?.id;
          this.role = data?.role;
          this.familyId = data?.family_id;
          this.app_user_id = data?.app_user_id;
          this.email = data?.email;
          this.firstName = data?.first_name;
          this.lastName = data?.last_name;
          this.telephone = data?.phone_no;
          this.streetName = data?.street_no;
          this.busNumber = data?.no_bus;
          this.postcode = data?.postcode;
          this.localAuthority = data?.local_authority;
          this.registerationNumber = data?.national_register_number !== null ? data?.national_register_number : '';
          this.accountNumber =  data?.account_no ? data?.account_no : '';
          if (data?.profile_picture){
            this.imageUrl = this.getProfilePicture(data?.profile_picture)
            const filename = data?.profile_picture?.split('/').pop();
            this.fileName = filename;
          }
          this.isFiscalCoParent = data?.co_parenting ===  1 ? true : false;
          this.isNotificationsChecked = data?.notifications ===  1 ? true : false;
        }
      })
      .catch((error) => {
        this.loading = false;
        console.log(error.message);
      });
    },
    setTab(index) {
      if (index === 1) {
        if (this.validate()) {
          this.handleSubmit()
          this.currentTab = index;
        }
      } else {
        this.currentTab = index;
      }
    },
    nextTab() {
        if (this.currentTab < this.tabs.length - 1) {
          this.currentTab += 1;
        }
    },
    prevTab() {
        if (this.currentTab > 0) {
          this.currentTab -= 1;
        }
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    deletePortfolio() {
      this.fileName = '';
      this.fileSize = 0;
      this.selectedFile = null;
      this.imageUrl = '';
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        // Set initial file info
        this.fileName = file.name;
        this.fileSize = (file.size / 1024 / 1024).toFixed(4); // Convert to MB
        this.selectedFile = file;

        // Simulate file upload process
        this.startUploadSimulation();
      } else {
        this.fileName = '';
        this.fileSize = 0;
      }
    },
    // Simulate the file upload process
    startUploadSimulation() {
      this.isUploading = true;
      this.uploadProgress = '0 KB';
      this.timeRemaining = 10; // Reset the time for each upload

      // Simulate upload progress over 2 seconds (adjust this for your needs)
      let simulatedProgress = 0;
      const interval = setInterval(() => {
        simulatedProgress += 100; // Increase progress in KB
        this.uploadProgress = `${simulatedProgress} KB / ${this.fileSize * 1024} KB`;
        this.timeRemaining--;

        if (this.timeRemaining <= 0) {
          // Stop the simulation after 2 seconds (this is where the "upload" finishes)
          clearInterval(interval);
          this.finishUpload();
        }
      }, 200); // Update every 200 ms for the upload simulation
    },

    // Finish the upload (image is now fully uploaded)
    finishUpload() {
      this.isUploading = false;
      this.imageUrl = URL.createObjectURL(this.selectedFile); // Create a URL for the selected file
    },
    updateNotificationCheckedState(event) {
      this.isNotificationsChecked = event.target.checked;
    },
    updateFiscalCheckedState(event) {
      this.isFiscalCoParent = event.target.checked;
    },
    validate() {
      this.firstNameError = '';
      this.lastNameError = '';
      this.emailError = '';
      this.telephoneError = '';
      this.profilePictureError = '';
      this.streetNameError = '';
      this.postcodeError = '';
      this.busNumberError = '';
      this.localAuthorityError = '';
      
      if (!this.firstName) {
        this.firstNameError = 'Required';
      }
  
      if (!this.lastName) {
          this.lastNameError = 'Required';
      }
    
      if (!this.email) {
          this.emailError = 'Required';
      } else if (!this.validEmail(this.email)) {
        this.emailError = 'Email is invalid';
      }
 
      if (!this.telephone) {
        this.telephoneError = 'Required';
      }
      
      if (!this.streetName) {
        this.streetNameError = 'Required';
      }
      if (!this.busNumber) {
        this.busNumberError = 'Required';
      }
      if (!this.postcode) {
        this.postcodeError = 'Required';
      }
      if (!this.localAuthority) {
        this.localAuthorityError = 'Required';
      }

      // Validate profile picture
      if (this.selectedFile) {
        const allowedTypes = ['image/jpeg', 'image/png'];
        if (!allowedTypes.includes(this.selectedFile.type)) {
          this.profilePictureError = 'Profile picture must be a JPEG, or PNG';
        }
      }

      return !(this.firstNameError || this.lastNameError || this.emailError || this.telephoneError || this.profilePictureError || this.streetNameError || this.busNumberError || this.postcodeError || this.localAuthorityError); // return true if no errors
    },
    validatePayment() {
      this.accountNumberError = '';
      
      if (!this.accountNumber) {
        this.accountNumberError = 'Required';
      }

      return !(this.accountNumberError); // return true if no errors
    },
    validEmail(email) {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple regex for email validation
      return regex.test(email);
    },
    RedirectTo(path) {
      this.$router.push(path);
    },
    handleSubmit() {
      if (this.validate()) {
        this.loading = true;
        let formData = new FormData()
        formData.append("email", this.email)
        formData.append("first_name", this.firstName)
        formData.append("last_name", this.lastName)
        formData.append("phone_no", this.telephone)
        formData.append("national_register_number", this.registerationNumber)
        formData.append("street_no", this.streetName)
        formData.append("no_bus", this.busNumber)
        formData.append("post_code", this.postcode)
        formData.append("local_authority", this.localAuthority)
        if (this.selectedFile) {
          formData.append("profile_picture", this.selectedFile)
        }        
        formData.append("co_parenting", this.isFiscalCoParent ? 1 : 0)
        formData.append("notifications", this.isNotificationsChecked ? 1 : 0)
        formData.append("parent_id", this.id)
        formData.append("app_user_id", this.app_user_id)
        formData.append("family_id", this.familyId)

        UpdateFamilyMember(formData, this.role)
        .then((response) => {
          if (response.status == 'success') {
            this.loading = false;
            ToastMessage.show(response.message, 'success');
            this.nextTab();
          }
        })
        .catch((error) => {
          this.loading = false;
          window.scrollTo({ top: 0, behavior: 'smooth' });
          ToastMessage.show(error.message, 'error');
        });
      }
    },
    getProfilePicture(picture) {
      return this.imgPath + picture;
    },
    handlePayment() {
      if (this.validatePayment()) {
        this.loading = true;
        let formData = new FormData()
        formData.append("id", this.id)
        formData.append("account_no", this.accountNumber)

        AddAccountNumber(formData)
        .then((response) => {
          if (response.status == 'success') {
            this.loading = false;
            if (response.data?.role === 1) {
              if (response.data?.account_no) {
                const authStore = useAuthStore(); // Initialize the auth store
                authStore.setUserData(response?.user);
              }
            }
            const modal = new Modal(document.querySelector('#successModal'));
            modal.show();
          }
        })
        .catch((error) => {
          this.loading = false;
          window.scrollTo({ top: 0, behavior: 'smooth' });
          ToastMessage.show(error.message, 'error');
        });
      }
    }
  }
}