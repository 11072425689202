import { ref } from 'vue';
import { Modal, Tooltip } from 'bootstrap';
import { SkeletonLoader } from 'vue3-loading-skeleton';
import 'vue3-loading-skeleton/dist/style.css';

import AppCompoundShape from '@/assets/images/AppCompoundShape.png';
import FallbackCover from '@/assets/images/NoCover.png';
import FallbackAvatar from '@/assets/images/Avatars.png';
import Org from '@/assets/images/Org.png';

import FullPageLoader from '@/components/FullPageLoader.vue';
import ToastMessage from '@/components/ToastMessages/Generic/GenericToastManager';

import { useAuthStore } from '@/store/authStore';
import {
  getAllFamilyMembers,
  fetchCampBookingDetails,
  campEnrollment,
  enrollmentPaymentCheck,
} from '@/services/apiService';

const Lunch = {
  NOT_INCLUDED: 3,
  INCLUDED: 1,
  TO_SELECT: 2,
};

export default {
  components: {
    SkeletonLoader,
    FullPageLoader,
  },
  data() {
    return {
      AppCompoundShape,
      FallbackCover,
      FallbackAvatar,
      Org,
      userInfo: '',
      currentTab: 0,
      tabs: [
        {
          id: 'explore-tab-pane',
          label: 'Voor wie',
        },
        {
          id: 'period-tab-pane',
          label: 'Periode',
          period: true,
        },
        {
          id: 'lunch-tab-pane',
          label: 'Lunch',
        },
        {
          id: 'confirmation-tab-pane',
          label: 'Bevestiging',
        },
        {
          id: 'payment-tab-pane',
          label: 'Betaling',
          hideInWatchlist: true,
        },
      ],
      kids: [],
      friends: [],
      camp: null,
      successData: null,
      subGroupModal: ref(),
      friendModal: ref(),
      selectedKidIndex: ref(-1),
      selectedFriendIndex: ref(-1),
      subGroupState: null,
      consentChecked: false,
      isSuccess: false,
      isLoading: false,
      isCheckingPayment: false,
      isShowToast: true,
      isFullNoWatchlist: false,
      isBookPerDay: false,
      selectedKidsAllWatchlist: false,
      orderId: null,
      Lunch,
    };
  },
  computed: {
    disableNextButton() {
      if (
        // empty state
        (this.currentTab === 0 && !this.kids.length)
        // 1st step & subgroup not selected yet
        || (this.currentTab === 0 && this.kids.every((k) => k.subGroups.every((k => !k.selected))))
      ) {
        return true;
      }

      if (this.currentTab === 1 && this.isHasSlots && this.isBookPerDay) {
        return this.camp.slots.every((s) => !s.selected);
      }

      if (this.currentTab === (this.isHasSlots ? 3 : 2)) {
        return !this.consentChecked;
      }

      return false;
    },
    nextButton() {
      if (this.isAllWatchlist && this.currentTab === 3) {
        return 'Zet op wachtlijst';
      }

      const text = {
        3: 'Ga naar betalen',
        // 3: 'Bevestig inschrijving',
      };

      return text[this.currentTab] || 'Volgende';
    },
    title() {
      if (this.isSuccess) {
        return this.isWatchlist && !this.isSomeSubGroupWatchlist ? 'Bevestiging' : 'Overzicht';
      }

      return 'Inschrijven';
    },
    isWatchlist() {
      return this.camp
        && this.camp.booking_header
        && this.camp.booking_header.watchlist
        && this.camp.booking_header.watchlist === 1
        && this.camp.age_groups.every((g) => this.isWatchlistVacancy(g));
    },
    isSomeWatchlist() {
      return !this.isFullBook && this.isWatchlist && this.camp && this.camp.age_groups.some((s) => this.isWatchlistVacancy(s)) && this.kids.some((k) => k.selected && k.watchlist);
    },
    isAllWatchlist() {
      return !this.isFullBook && this.isWatchlist && this.camp && this.camp.age_groups.some((s) => this.isWatchlistVacancy(s)) && this.kids.every((k) => k.selected && k.watchlist);
    },
    isFullBook() {
      if (
        this.camp
        && this.camp.booking_header
        && this.camp.booking_header.watchlist === 2
        && this.camp.age_groups.every((ag) => ag.vacancy < 1)
      ) {
        return true;
      }

      return false;
    },
    isHasSlots() {
      return this.camp && this.camp.slots && this.camp.slots.length > 0 && this.camp.slots.some((s) => s.id && s.price);
    },
    totalSelectedSlots() {
      let price = 0;
      this.camp.slots.forEach((s) => {
        if (s.selected) {
          price += s.price;
        }
      });
      return price;
    },
    subGroupWatchlist() {
      return this.camp.age_groups.find((s) => this.isWatchlistVacancy(s));
    },
    tabMenus() {
      if (this.isAllWatchlist) {
        return this.tabs.filter((t) => !t.hideInWatchlist);
      }

      if (this.isHasSlots) {
        if (this.isSomeSubGroupWatchlist) {
          return this.tabs.filter((t) => t.watchlist !== false);
        }

        return this.tabs;
      }

      return this.tabs.filter((t) => !t.period);
    },
    firstSelectedChild() {
      return this.kids.find((k) => k.selected);
    },
    closeLink() {
      if (this.isSuccess) {
        return '/registrations/1'
      }

      return '/search';
    }
  },
  created() {
    const authStore = useAuthStore(); 
    this.userInfo = authStore.user;
  },
  async mounted() {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    this.subGroupModal = new Modal(document.querySelector('#subGroupModal'));
    this.friendModal = new Modal(document.querySelector('#friendModal'));

    this.getCampDetail();

    const urlParams = new URLSearchParams(window.location.search);
    const orderId = urlParams.get('orderId');

    if (orderId) {
      this.orderId = orderId;
      this.checkPayment(orderId);
    }

    // check save to calendar / ics
    // var icsMSG = "BEGIN:VCALENDAR\nVERSION:2.0\nPRODID:-//Our Company//NONSGML v1.0//EN\nBEGIN:VEVENT\nUID:me@google.com\nDTSTAMP:20120315T170000Z\nATTENDEE;CN=My Self ;RSVP=TRUE:MAILTO:me@gmail.com\nORGANIZER;CN=Me:MAILTO::me@gmail.com\nDTSTART:" + msgData1 +"\nDTEND:" + msgData2 +"\nLOCATION:" + msgData3 + "\nSUMMARY:Our Meeting Office\nEND:VEVENT\nEND:VCALENDAR";
    // window.open('data:text/calendar;charset=utf8,' + encodeURIComponent(icsMSG));
  },
  methods: {
    async getCampDetail() {
      const urlParams = new URLSearchParams(window.location.search);
      const id = urlParams.get('campId');

      this.isLoading = true;
      fetchCampBookingDetails(id)
        .then((response) => {
          if (response.data) {
            this.isLoading = false;
            this.camp = {
              ...response.data,
              age_groups: [
                ...response.data.age_groups.map((ag) => ({
                  ...ag,
                  occupiedBy: [],
                }))
              ],
              slots: [
                ...response.data.slots.map((s) => ({
                  ...s,
                  selected: false,
                }))
              ],
            };
            this.fetchFamilyMembers();
          }
        })
        .catch((error) => {
          this.isLoading = false;
          ToastMessage.show(error.message, 'error');
          console.log(error.message);
        });
    },
    async fetchFamilyMembers() {
      this.isLoading = true;
      getAllFamilyMembers(this.userInfo.id)
      .then((response) => {
        this.isLoading = false;

        const kids = [];
        const friends = [];

        if (
          response
          && response.data
          && response.data.length > 0
          && this.camp
        ) {
          const data = response.data;

          data.forEach((fam) => {
            if (fam.children && fam.children.length > 0) {
              fam.children.forEach((c) => {
                kids.push({
                  ...c,
                  friends: [
                    ...c.friends.map((f) => ({
                      ...f,
                      lunch: this.camp.period.is_lunch_provided === Lunch.INCLUDED ? true : false,
                      selected: false,
                    })),
                  ],
                  subGroups: [
                    ...this.camp.age_groups.map((s) => ({
                      ...s,
                      selected: false,
                      watchlist: this.isWatchlist && this.isWatchlistVacancy(s),
                    })),
                  ],
                  slots: [
                    ...this.camp.slots.map((s) => ({
                      ...s,
                      selected: false,
                    })),
                  ],
                  lunch: this.camp.period.is_lunch_provided === Lunch.INCLUDED ? true : false,
                  selected: false,
                  bookingPerDay: this.camp && this.camp.period.is_booking_per_day_possible === 'yes' && this.camp.slots.some((s) => s.id && s.price),
                  disabled: c.age >= this.camp.booking_header.min_age && c.age <= this.camp.booking_header.max_age ? 'no' : 'yes',
                });
    
                if (c.friends && c.friends.length > 0) {
                  c.friends.forEach((f) => {
                    friends.push({
                      ...f,
                      watchlist: false,
                      lunch: this.camp.period.is_lunch_provided === Lunch.INCLUDED ? true : false,
                      selected: false,
                    });
                  });
                }
              });
            }
          });

          this.kids = kids;

          const key = 'friend_id';
          const uniqueFriends = [...new Map(friends.map(item => [item[key], item])).values()]
          this.friends = uniqueFriends;
        }
      })
      .catch((error) => {
        this.isLoading = false;
        ToastMessage.show(error.message, 'error');
        console.log(error.message);
      });
    },
    goToChildForm() {
      sessionStorage.setItem('family_id', this.camp?.booking_header?.family_id)
      this.$router.push('/child-profile-form'); 
    },
    async doEnrollment() {
      this.isLoading = true;

      let kids = this.kids.filter((k) => k.selected);
      let friendsRaw =  this.friends.filter((f) => f.selected);

      let children = [];
      let friends =  [];
      const child = kids[kids.length - 1];
      const isBookPerDay = this.isBookPerDay;
      const slots = this.camp.slots.filter((s) => s.selected);
      
      if (friendsRaw.length > 0) {
        const subgroup = this.firstSelectedChild.subGroups.find((s) => s.selected);

        friendsRaw.forEach((f) => {
          const friendPayload = {
            // name: f.friend_first_name, // to delete
            parent_id: String(f.parent_id),
            child_id: String(f.friend_id),
            // subgroup: String(subgroup.name), // to delete
            subgroup_id: String(subgroup.id),
            lunch_included: f.lunch ? 1 : 0,
            period: isBookPerDay ? 'yes' : 'no',
          };

          if (isBookPerDay) {
            Object.assign(friendPayload, { slots: [...slots.map((s) => s.id)] })
          }

          friends.push(friendPayload);
        });
      } else {
        friends = [];
      }

      kids.forEach((k) => {
        const sg = k.subGroups.find((s) => s.selected);
        const childPayload = {
          // name: k.first_name, // to delete
          child_id: String(k.id), //96,93,90 (your children ids)
          // subgroup: String(sg.name), // to delete
          subgroup_id: String(sg.id), //15,16 (the camp's subgroup id) 15 = min20 max40 cap2  
          lunch_included: k.lunch ? 1 : 0, //1 or 0
          period: isBookPerDay ? 'yes' : 'no', //yes or no
        };

        if (isBookPerDay) {
          Object.assign(childPayload, { slots: [...slots.map((s) => s.id)] })
        }

        children.push(childPayload);
      });

      const bookPayload = {
        family_id: String(child.family_id),
        parent_id: String(child.parent_id),
        children,
      };

      if (friends && friends.length > 0) {
        Object.assign(bookPayload, { friends });
      }

      const payload = {
        campDetails: {
          organization_id: String(this.camp.booking_header.organization_id),
          camp_id: String(this.camp.booking_header.camp_id),
          lunch_price: this.camp.period.lunch_price,
        },
        bookings: [
          bookPayload,
        ],
      };

      localStorage.setItem('last_enrollment', JSON.stringify(payload));

      // console.log(campEnrollment, payload);

      try {
        const res = await campEnrollment(payload);

        if (kids.every((k) => k.watchlist)) {
          this.selectedKidsAllWatchlist = true;
          this.isSuccess = true;

          return;
        }

        if (res && res.paymentURL) {
          this.selectedKidsAllWatchlist = false;
          // console.log(res);
          window.location.href = res.paymentURL;
          // this.isSuccess = true;
        }
      } catch (error) {
        ToastMessage.show(error, 'error');
      } finally {
        this.isLoading = false;
      }
    },
    async checkPayment(orderId) {
      this.isCheckingPayment = true;

      try {
        const res = await enrollmentPaymentCheck({ orderId });

        if (res && res.data) {
          this.successData = res.data;
          // this.successData = {
          //   camp_booking_total: "7.00",
          //   camp_booking_details: {
          //     id: 1388,
          //     organization_id: 1556,
          //     booking_code: "30b827de-195c-4cda-aad2-2fcfc1bd5e6c",
          //     parent_id: 19,
          //     payment_method: 1,
          //     discount: "0.00",
          //     total: "7.00",
          //     status: 3,
          //     details: [
          //       {
          //         id: 1376,
          //         organization_id: 1556,
          //         camp_booking_id: 1388,
          //         camp_id: 4400,
          //         subgroup_id: 6735,
          //         child_id: 67,
          //         payment_method: 0,
          //         camp_price: "4.00",
          //         discount: "0.00",
          //         lunch_included: 1,
          //         lunch_price: "3.00",
          //         price: "0.00",
          //         status: 0,
          //         type: 0,
          //         created_at: "2024-12-13T10:32:02.000000Z",
          //         updated_at: "2024-12-13T10:32:02.000000Z",
          //         schedules: [
          //           {
          //             id: 4567,
          //             camp_booking_detail_id: 1376,
          //             date: "2024-12-12",
          //             created_at: "2024-12-13T10:32:02.000000Z",
          //             updated_at: "2024-12-13T10:32:02.000000Z"
          //           },
          //           {
          //             id: 4568,
          //             camp_booking_detail_id: 1376,
          //             date: "2024-12-13",
          //             created_at: "2024-12-13T10:32:02.000000Z",
          //             updated_at: "2024-12-13T10:32:02.000000Z"
          //           },
          //           {
          //             id: 4569,
          //             camp_booking_detail_id: 1376,
          //             date: "2024-12-14",
          //             created_at: "2024-12-13T10:32:02.000000Z",
          //             updated_at: "2024-12-13T10:32:02.000000Z"
          //           }
          //         ],
          //         child: {
          //           id: 67,
          //           first_name: "Virgil",
          //           last_name: "Van Dijk",
          //           profile_image: null
          //         },
          //         subgroup: {
          //           id: 6735,
          //           name: "G3"
          //         }
          //       }
          //     ]
          //   },
          //   payment_method: "bancontact"
          // };
          this.isSuccess = true;

          localStorage.setItem('payment_check', JSON.stringify(this.successData));
        }
      } catch (error) {
        ToastMessage.show(error.message, 'error');
        // this.successData = {
        //   camp_booking_total: "7.00",
        //   camp_booking_details: {
        //     id: 1388,
        //     organization_id: 1556,
        //     booking_code: "30b827de-195c-4cda-aad2-2fcfc1bd5e6c",
        //     parent_id: 19,
        //     payment_method: 1,
        //     discount: "0.00",
        //     total: "7.00",
        //     status: 3,
        //     details: [
        //       {
        //         id: 1376,
        //         organization_id: 1556,
        //         camp_booking_id: 1388,
        //         camp_id: 4400,
        //         subgroup_id: 6735,
        //         child_id: 67,
        //         payment_method: 0,
        //         camp_price: "4.00",
        //         discount: "0.00",
        //         lunch_included: 1,
        //         lunch_price: "3.00",
        //         price: "0.00",
        //         status: 0,
        //         type: 0,
        //         created_at: "2024-12-13T10:32:02.000000Z",
        //         updated_at: "2024-12-13T10:32:02.000000Z",
        //         schedules: [
        //           {
        //             id: 4567,
        //             camp_booking_detail_id: 1376,
        //             date: "2024-12-12",
        //             created_at: "2024-12-13T10:32:02.000000Z",
        //             updated_at: "2024-12-13T10:32:02.000000Z"
        //           },
        //           {
        //             id: 4568,
        //             camp_booking_detail_id: 1376,
        //             date: "2024-12-13",
        //             created_at: "2024-12-13T10:32:02.000000Z",
        //             updated_at: "2024-12-13T10:32:02.000000Z"
        //           },
        //           {
        //             id: 4569,
        //             camp_booking_detail_id: 1376,
        //             date: "2024-12-14",
        //             created_at: "2024-12-13T10:32:02.000000Z",
        //             updated_at: "2024-12-13T10:32:02.000000Z"
        //           }
        //         ],
        //         child: {
        //           id: 67,
        //           first_name: "Virgil",
        //           last_name: "Van Dijk",
        //           profile_image: null
        //         },
        //         subgroup: {
        //           id: 6735,
        //           name: "G3"
        //         }
        //       }
        //     ]
        //   },
        //   payment_method: "bancontact"
        // };
        // this.isSuccess = true;
        localStorage.setItem('payment_check', JSON.stringify(this.successData));
        if (error.data) {
          localStorage.setItem('payment_check', JSON.stringify(error.data));
        }
      } finally {
        this.isCheckingPayment = false;
      }
    },
    lunchPrice(price) {
      if (!this.isBookPerDay) {
        return price * this.camp.period.no_of_days;
      }

      if (
        this.isBookPerDay
        && this.camp.slots.filter((s) => s.selected).length
      ) {
        return price * this.camp.slots.filter((s) => s.selected).length;
      }

      return price;

    },
    displayCampDate(slots) {
      // Check if the campDates array is empty or doesn't have the expected structure
      if (!slots || !slots.length) return "No dates available";
  
      // Parse the dates
      const minDate = new Date(slots[0].start_date);
      const maxDate = new Date(slots[slots.length - 1].start_date);
      const isSameYear = minDate.getYear() === maxDate.getYear();
      const isSameMonth = minDate.getMonth() === maxDate.getMonth();
  
      // Check if the min and max dates are the same
      if (minDate.getTime() === maxDate.getTime()) {
        return this.formatDate(minDate);
      } else if (isSameYear) {
        const strSplitted = this.formatDate(minDate).split(' ');
        return `${strSplitted[0]} ${isSameMonth ? '' : strSplitted[1]} - ${this.formatDate(maxDate)}`;
      } else {
        return `${this.formatDate(minDate)} - ${this.formatDate(maxDate)}`;
      }
    },
    formatDate(date) {
      const options = { day: 'numeric', month: 'long', year: 'numeric' };
      return date.toLocaleDateString('nl-NL', options);
    },
    formatTime(date) {
      return new Date(date).getHours() + 'u';
    },
    formatDay(date) {
      const day = new Intl.DateTimeFormat('nl-nl', {
        weekday: 'long',
      });

      return day.format(new Date(date));
    },
    imageUrl(url) {
      const cdn = process.env.VUE_APP_CDN_PATH;
      const path = process.env.VUE_APP_IMG_PATH_CAMP;
      return `${cdn}width=200,height=200,fit=cover,format=auto,metadata=none${path}${url}`;
    },
    setTab(index) {
      this.currentTab = index;
    },
    next() {
      const tooltipInfoEl = document.querySelector('#tooltipInfo');
      console.log('TO DO tooltip', tooltipInfoEl);
      if (tooltipInfoEl) {
        new Tooltip(tooltipInfoEl);
      }

      if (this.currentTab === (this.isHasSlots ? 3 : 2)) {
        this.doEnrollment();

        return;
      }

      this.currentTab += 1;
    },
    back() {
      this.currentTab -= 1;
    },
    selectFullPeriod() {
      this.camp.slots = this.camp.slots.map((s) => ({
        ...s,
        selected: false,
      }));
      this.isBookPerDay = false;
    },
    selectGroup(index) {
      if (this.kids[index].disabled === 'yes') return;

      this.selectedKidIndex = index;

      if (this.kids[index].selected && this.kids[index].subGroups.every((s) => !s.selected)) {
        this.kids[index].selected = false;

        // this.selectSubGroup(this.camp.age_groups[0]);
        // this.confirmSelectSubgroup();

        // return;
      }

      // DESELECT
      if (this.kids[index].selected) {
        if (this.kids.filter((k) => k.selected).length === 1) {
          this.friends.forEach((f, i) => {
            if (f.selected) this.toggleFriend(i);
          });
        }

        const currentGroupId = this.kids[index].subGroups.find((s) => s.selected).id;

        this.camp.age_groups = this.camp.age_groups.map((ag) => {
          if (ag.id === currentGroupId) {
            return ({
              ...ag,
              occupiedBy: ag.occupiedBy.filter((o) => o !== this.kids[index].id),
            });
          }

          return ag;
        });

        const isWatchlistCamp = this.camp.booking_header.watchlist === 1;
        
        // KIDS
        this.kids = this.kids.map((k, i) => {
          if (!k.selected) return k;

          // CURRENT KID
          if (index === i) {
            return ({
              ...k,
              selected: false,
              watchlist: false,
              subGroups: k.subGroups.map((s) => ({
                ...s,
                watchlist: false,
                selected: false,
              })),
            });
          }

          // OTHER KIDS - RECHECK WATCHLIST
          const selectedGroup = k.subGroups.find((ag) => ag.selected);
          const curGroup = this.camp.age_groups.find((ag) => ag.id === selectedGroup.id);
          const watchlist = isWatchlistCamp && (curGroup.occupiedBy.indexOf(k.id) + 1) > curGroup.vacancy;

          return ({
            ...k,
            watchlist,
            subGroups: k.subGroups.map((s) => {
              if (s.selected) {
                return ({
                  ...s,
                  watchlist,
                });
              }

              return s;
            }),
          });
        });

        // FRIENDS
        if (this.friends && this.friends.length > 0 && this.friends.some((f) => f.selected)) {
          this.friends = this.friends.map((k) => {
            if (!k.selected) return k;
  
            const selectedGroup = this.firstSelectedChild.subGroups.find((ag) => ag.selected);
            const curGroup = this.camp.age_groups.find((ag) => ag.id === selectedGroup.id);
            const watchlist = isWatchlistCamp && (curGroup.occupiedBy.indexOf(k.friend_id) + 1) > curGroup.vacancy;
  
            // RECHECK WATCHLIST
            return ({
              ...k,
              watchlist,
              subGroups: this.firstSelectedChild.subGroups.map((s) => {
                if (s.selected) {
                  return ({
                    ...s,
                    watchlist,
                  });
                }
  
                return s;
              }),
            });
          });
        }

        return;
      }
      // END DESELECT

      if (this.kids[index].disabled === 'yes' || !this.isKidEligibleForCamp(this.kids[index])) {
        return;
      }

      if (this.camp.age_groups.length === 1 && this.kids[index].subGroups.every((s) => !s.selected)) {
        this.selectSubGroup(this.camp.age_groups[0]);
        this.confirmSelectSubgroup();

        return;
      }

      this.subGroupModal.show();
    },
    selectSubGroup(sg) {
      this.subGroupState = this.camp.age_groups.find((ag) => ag.id === sg.id);
    },
    confirmSelectSubgroup() {
      if (!this.subGroupState) return;

      const sg = this.subGroupState;
      const isWatchlistCamp = this.camp.booking_header.watchlist === 1;
      let curGroup = this.camp.age_groups.find((ag) => ag.id === sg.id);
      let isWatchlist = isWatchlistCamp && curGroup.vacancy <= curGroup.occupiedBy.length;

      // PRIORITIZE CHILDREN OVER FRIENDS - shuffleFriend
      if (isWatchlist && this.friends.some((f) => f.selected && !f.watchlist)) {
        const watchlistFriends = this.friends.filter((f) => f.selected && !f.watchlist);
        const friendToShuffle = watchlistFriends[0];
        const friendToShuffleIndex = this.friends.map((f) => f.id).indexOf(friendToShuffle.id);

        this.toggleFriend(friendToShuffleIndex);
        this.selectSubGroup(curGroup);
        this.confirmSelectSubgroup();
        this.toggleFriend(friendToShuffleIndex);

        return
      }

      isWatchlist = isWatchlistCamp && curGroup.vacancy <= curGroup.occupiedBy.length;

      this.kids[this.selectedKidIndex] = {
        ...this.kids[this.selectedKidIndex],
        subGroups: this.kids[this.selectedKidIndex].subGroups.map((s) => ({
          ...s,
          selected: s.id === sg.id,
          watchlist: s.id === sg.id
            ? isWatchlist
            : false,
        })),
        selected: true,
        watchlist: isWatchlist,
      };

      const currentKidId = this.kids[this.selectedKidIndex].id;

      this.camp.age_groups = this.camp.age_groups.map((ag) => {
        const alreadyOccupied = ag.occupiedBy.includes(currentKidId);
        if (ag.id === this.subGroupState.id && !alreadyOccupied) {
          return ({
            ...ag,
            // vacancy: ag.vacancy - 1,
            occupiedBy: [...ag.occupiedBy, currentKidId],
          });
        }
        
        return ({...ag});
      });

      this.subGroupState = null;
      this.subGroupModal.hide();
    },
    selectSlots(slotIndex) {
      this.camp.slots[slotIndex].selected = !this.camp.slots[slotIndex].selected;
    },
    totalSummary() {
      let total = 0;  

      const selectedKids = this.kids.filter((k) => k.selected && !k.watchlist);
      
      selectedKids.forEach((k) => {
        if (!this.firstSelectedChild) return;
        
        const subGroupPrice = this.ageGroupSelected(k) ? parseFloat(this.ageGroupSelected(k).price) : 0;
        const lunchPrice = k.lunch ? parseFloat(this.camp.period.lunch_price) : 0;

        total += this.isBookPerDay
          ? this.totalSelectedSlots + (lunchPrice * this.camp.slots.filter((s) => s.selected).length)
          : (subGroupPrice + (lunchPrice * this.camp.period.no_of_days));
      });

      return total;
    },
    ageGroupSelected(kid) {
      return kid.subGroups.find((s) => s.selected);
    },
    isKidEligibleForCamp(kid) {
      if (!kid) return false;

      return !kid.upcoming_bookings.some((ub) => ub.camp_id === this.camp.booking_header.camp_id);
    },
    isKidAgeEligibleForCamp(kid) {
      if (!kid) return false;

      return kid.age >= this.camp.booking_header.min_age && kid.age <= this.camp.booking_header.max_age;
    },
    isKidEligibleForGroup(kid, sg) {
      if (!kid || !sg) return false;

      // return kid.age >= sg.min_age && kid.age <= sg.max_age && (!this.isWatchlist ? sg.vacancy > 0 : true);
      return kid.age >= sg.min_age && kid.age <= sg.max_age;
    },
    isDisabledGroup(sg) {
      const isWatchlistCamp = this.camp.booking_header.watchlist === 1;
      const group = this.camp.age_groups.find((ag) => ag.id === sg.id);

      return !isWatchlistCamp && group.vacancy === group.occupiedBy.length;
    },
    toggleLunch(index) {
      this.kids[index] = {
        ...this.kids[index],
        lunch: !this.kids[index].lunch,
      };
    },
    toggleLunchFriend(index) {
      this.friends[index].lunch = !this.friends[index].lunch;
    },
    toggleFriend(index) {
      this.selectedFriendIndex = index;

      if (!this.isKidEligibleForCamp(this.friends[index])) {
        this.friends[index].selected = false;
        return;
      }

      this.friends[index].selected = !this.friends[index].selected;
      
      const isWatchlistCamp = this.camp.booking_header.watchlist === 1;
      let curGroup = this.firstSelectedChild.subGroups.find((s) => s.selected);
      let occupiedGroup = this.camp.age_groups.find((ag) => ag.id === curGroup.id);
      const currentKidId = this.friends[index].friend_id;

      // DESELECT
      if (occupiedGroup.occupiedBy.includes(currentKidId)) {
        this.camp.age_groups = this.camp.age_groups.map((ag) => {
          if (ag.id === curGroup.id) {
            return ({
              ...ag,
              occupiedBy: ag.occupiedBy.filter((o) => o !== currentKidId),
            });
          }

          return ag;
        });

        occupiedGroup = this.camp.age_groups.find((ag) => ag.id === curGroup.id);

        // FRIENDS
        if (this.friends && this.friends.length > 0 && this.friends.some((f) => f.selected)) {
          this.friends = this.friends.map((k) => {
            if (!k.selected) return k;
  
            const watchlist = isWatchlistCamp && (occupiedGroup.occupiedBy.indexOf(k.friend_id) + 1) > occupiedGroup.vacancy;
  
            // RECHECK WATCHLIST
            return ({
              ...k,
              watchlist,
              subGroups: this.firstSelectedChild.subGroups.map((s) => {
                if (s.selected) {
                  return ({
                    ...s,
                    watchlist,
                  });
                }
  
                return s;
              }),
            });
          });
        }

        // KIDS
        this.kids = this.kids.map((k) => {
          if (!k.selected) return (k);

          const selectedGroup = k.subGroups.find((ag) => ag.selected);

          if (selectedGroup.id !== occupiedGroup.id) return (k);

          // KIDS - RECHECK WATCHLIST
          const watchlist = isWatchlistCamp && (occupiedGroup.occupiedBy.indexOf(k.id) + 1) > occupiedGroup.vacancy;

          return ({
            ...k,
            watchlist,
            subGroups: k.subGroups.map((s) => {
              if (s.selected && selectedGroup.id === occupiedGroup.id) {
                return ({
                  ...s,
                  watchlist,
                });
              }

              return s;
            }),
          });
        });

        return;
      }
      // END DESELECT

      this.camp.age_groups = this.camp.age_groups.map((ag) => {
        const alreadyOccupied = ag.occupiedBy.includes(currentKidId);
        if (ag.id === curGroup.id && !alreadyOccupied) {
          return ({
            ...ag,
            occupiedBy: [...ag.occupiedBy, currentKidId],
          });
        }
        
        return ({...ag});
      });

      this.friends[index].watchlist = isWatchlistCamp && occupiedGroup.vacancy <= occupiedGroup.occupiedBy.length;

      // UPDATE KIDS
      occupiedGroup = this.camp.age_groups.find((ag) => ag.id === curGroup.id);

      this.kids = this.kids.map((k) => {
        if (!k.selected) return k;

        return ({
          ...k,
          subGroups: k.subGroups.map((s) => {
            if (s.id !== occupiedGroup.id && !s.selected) return s;

            return ({
              ...s,
              watchlist: isWatchlistCamp && (occupiedGroup.occupiedBy.indexOf(k.id) + 1) > occupiedGroup.vacancy,
            })
          }),
          watchlist: isWatchlistCamp && (occupiedGroup.occupiedBy.indexOf(k.id) + 1) > occupiedGroup.vacancy,
        })
      });
    },
    isWatchlistVacancy(sg) {
      const group = this.camp.age_groups.find((ag) => ag.id === sg.id);
      if (
        this.camp
        && this.camp.booking_header
        && this.camp.booking_header.watchlist
        && this.camp.booking_header.watchlist === 1
        && group.vacancy < 1
      ) {
        return true;
      }

      if (group.occupiedBy && group.occupiedBy.length > 0) {
        return group.occupiedBy.length > group.vacancy;
      }

      return false;
    },
    onCloseGroupModal() {
      this.subGroupModal.hide();
    },
    onSelectFriend() {
      this.kids[this.selectedKidIndex].selected = true;
      this.friendModal.hide();
    },
    shareOption (){
      if (navigator.share) {
        navigator.share({
          title: "HappyGo",
          text: this.camp?.booking_header?.camp_name,
          url: window.location.href,
        })
        .then(() => {
          console.log('Share successful');
        })
        .catch((error) => {
          console.error('Error sharing:', error);
        });
      } else {
        // Fallback for browsers that do not support the Web Share API
        // alert('Sharing is not supported in this browser.');
      }
    },
    finishEnrollment() {
      if (this.selectedKidsAllWatchlist) {
        this.$router.push('/registrations/1');

        return;
      }

      this.$router.push(`/enrollment-rrn?campId=${this.camp.booking_header.camp_id}`);
    },
  }
}