import { resetPassword } from '../../services/apiService';
import FullPageLoader from '../../components/FullPageLoader.vue';

export default {
  components: {
    FullPageLoader,
  },
  data() {
    return {
        password: '',
        passwordVisible: false,
        passwordError: '',
        confirmPassword: '',
        confirmPasswordVisible: false,
        confirmPasswordError: '',
        errorMessage: '',
        successMessage: '',
        token: '',
        loading: false,
    };
  },
  mounted() {
    this.token = this.$route.query.token;
    // console.log("Token:", this.token);
  },
  methods: {
    showPassword() {
        this.passwordVisible = !this.passwordVisible;
    },
    showConfirmPassword() {
        this.confirmPasswordVisible = !this.confirmPasswordVisible;
    },
    validate() {
        this.passwordError = '';
        this.confirmPasswordError = '';

        // Regex to check for at least one special character
        const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
        const capitalLetterRegex = /[A-Z]/;
        const numberRegex = /[0-9]/;
        
        if (!this.password) {
          this.passwordError = 'Wachtwoord is vereist';
        } else if (this.password.length < 8) {
          this.passwordError = 'Je wachtwoord moet minstens 8 karakters bevatten';
        } else if (!specialCharRegex.test(this.password)) {
          this.passwordError = 'Je wachtwoord moet minstens één speciaal karakter bevatten';
        } else if (!capitalLetterRegex.test(this.password)) {
          this.passwordError = 'Je wachtwoord moet minstens één hoofdletter bevatten ';
        } else if (!numberRegex.test(this.password)) {
          this.passwordError = 'Je wachtwoord moet minstens één cijfer bevatten';
        }
    
        if (!this.confirmPassword) {
            this.confirmPasswordError = 'Confirm Wachtwoord is vereist';
        } else if (this.confirmPassword.length < 8) {
            this.confirmPasswordError = 'Je wachtwoord moet minstens 8 karakters bevatten';
        } else if (this.password !== this.confirmPassword) {
            this.confirmPasswordError = 'Wachtwoorden komen niet overeen';
        }
  
        return !(this.passwordError || this.confirmPasswordError); // return true if no errors
    },
    handleSubmit() {
      if (this.validate()) {
        // console.log('TOKEN:', this.token);
        // console.log('Data:', { password: this.password });
        const data = {
          token: this.token,
          password: this.password
        }
           // Logic to handle login
          //  console.log('Reset Password with:', data);
           this.loading = true;
           resetPassword(data)
             .then((data) => {
              // console.log('Email successful:', data);
              this.loading = false;
              sessionStorage.setItem('successMessage', data.message); // Store message temporarily
              this.$router.push('/success');
             })
             .catch((error) => {
               this.loading = false;
               this.errorMessage = error.message || 'Inloggen mislukt. Probeer het opnieuw.';
             });
      }
    }
  }
}