import { useAuthStore } from '@/store/authStore';
import FullPageLoader from '../../../components/FullPageLoader.vue';
import ToastMessage from '../../../components/ToastMessages/Generic/GenericToastManager';
import { ChangePassword, DeactivateAccount, DeleteAccount } from '@/services/apiService';

export default {
  components: {
    FullPageLoader,
  },
    data() {
      return {
          loading: false,
          userData: null,
          password: '',
          passwordVisible: false,
          passwordError: '',
          currentPassword: '',
          currentPasswordVisible: false,
          currentPasswordError: '',
          confirmPassword: '',
          confirmPasswordVisible: false,
          confirmPasswordError: '',
      };
    },
    created() {
      const authStore = useAuthStore(); 
      this.userData = authStore.user;
    },
    methods: {
      showPassword() {
          this.passwordVisible = !this.passwordVisible;
      },
      showConfirmPassword() {
          this.confirmPasswordVisible = !this.confirmPasswordVisible;
      },
      showCurrentPassword() {
          this.currentPasswordVisible = !this.currentPasswordVisible;
      },
      validate() {
          this.passwordError = '';
          this.confirmPasswordError = '';
          this.currentPasswordError = '';
  
          // Regex to check for at least one special character
          const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
          const capitalLetterRegex = /[A-Z]/;
          const numberRegex = /[0-9]/;
          
          if (!this.currentPassword) {
            this.currentPasswordError = 'Wachtwoord is vereist';
          }

          if (!this.password) {
            this.passwordError = 'Wachtwoord is vereist';
          } else if (this.password.length < 8) {
            this.passwordError = 'Je wachtwoord moet minstens 8 karakters bevatten';
          } else if (!specialCharRegex.test(this.password)) {
            this.passwordError = 'Je wachtwoord moet minstens één speciaal karakter bevatten';
          } else if (!capitalLetterRegex.test(this.password)) {
            this.passwordError = 'Je wachtwoord moet minstens één hoofdletter bevatten ';
          } else if (!numberRegex.test(this.password)) {
            this.passwordError = 'Je wachtwoord moet minstens één cijfer bevatten';
          }
      
          if (!this.confirmPassword) {
              this.confirmPasswordError = 'Confirm Wachtwoord is vereist';
          } else if (this.confirmPassword.length < 8) {
              this.confirmPasswordError = 'Je wachtwoord moet minstens 8 karakters bevatten';
          } else if (this.password !== this.confirmPassword) {
              this.confirmPasswordError = 'Wachtwoorden komen niet overeen';
          }
          return !(this.currentPasswordError || this.passwordError || this.confirmPasswordError); // return true if no errors
      },
      handleSubmit() {
        if (this.validate()) {
        this.loading = true;

        let params = {
          user_id: this.userData.id,
          old_password: this.currentPassword,
          password: this.password
        }
        ChangePassword(params)
        .then((response) => {
          if (response.status == 'success') {
            this.loading = false;
            this.password = '';
            this.currentPassword = '';
            this.confirmPassword = '';
            ToastMessage.show(response.message, 'success');
          }
        })
        .catch((error) => {
          this.loading = false;
          ToastMessage.show(error.message, 'error');
        });
        }
      },
      logout(){
        const authStore = useAuthStore();
        authStore.logout(); 
        window.location.href = "/";
      },
      deactivateAccount() {
        this.loading = true;
        let params = {
          user_id: this.userData.id,
        }

        DeactivateAccount(params)
        .then((response) => {
          if (response.status == 'success') {
            this.loading = false;
            ToastMessage.show(response.message, 'success');
            this.logout();
          }
        })
        .catch((error) => {
          this.loading = false;
          ToastMessage.show(error.message, 'error');
        });
      },
      deleteAccount() {
        this.loading = true;
        let params = {
          user_id: this.userData.id,
        }
        DeleteAccount(params)
        .then((response) => {
          if (response.status == 'success') {
            this.loading = false;
            ToastMessage.show(response.message, 'success');
            this.logout();
          }
        })
        .catch((error) => {
          this.loading = false;
          ToastMessage.show(error.message, 'error');
        });
      }
    }
  }