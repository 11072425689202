import { ref, toRaw } from 'vue';
import Datepicker from 'vue3-datepicker';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import { Modal } from 'bootstrap';
import MultiRangeSlider from "multi-range-slider-vue";

import FullPageLoader from '@/components/FullPageLoader.vue';
import Org from '@/assets/images/Org.png';
import SearchedEvent1 from '@/assets/images/SearchedEvent1.png';
import SearchedEvent2 from '@/assets/images/SearchedEvent2.png';
import MobileNavigation from '@/components/MobileNavigation.vue';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import {
  fetchAllCamps,
  fetchSearchCamps,
  getAllActivities,
  getAllLocations,
  getAllOrganization,
  getNotifications,
  readNotification,
} from '@/services/apiService';
import "multi-range-slider-vue/MultiRangeSliderBlack.css";
import "multi-range-slider-vue/MultiRangeSliderBarOnly.css";
import 'swiper/swiper-bundle.css';

export default {
  components: {
    VueDatePicker,
    MobileNavigation,
    MultiRangeSlider,
    FullPageLoader,
    Swiper,
    SwiperSlide,  
    Datepicker 
  },
  setup() {
    const selectedDate = ref(null); // Using ref for reactivity
    return {
      selectedDate,
    };
  },
  data() {
    return {
      user: null,
      loading: false,
      notification: null,
      showNotification: true,
      imgPath: process.env.VUE_APP_IMG_PATH, 
      imgPathCamp: process.env.VUE_APP_IMG_PATH_CAMP, 
      cdnPath: process.env.VUE_APP_CDN_PATH, 
      campsData: [],
      otherCampsData: [],
      selectedOption: 'Meest relevant',
      search: '',
      isOpen: false,
      popularCamps: [],
      recentCamps: [],
      filterDateRange: ref(),
      campsInArea: [
        { id: 1, name: 'Adventure & Hockey Fun', date: '15 juli - 19 juli 2024', time: '9u - 14u', location: 'Sporthal Diepvenneke, Vosselaar', users: '6 - 12 jaar', startingPrice: true, price: '125.00', logo: Org, status: false, cover: SearchedEvent1},
        { id: 2, name: 'Smurfentennis', date: '15 juli - 19 juli 2024', time: '9u - 14u', location: 'Sporthal Diepvenneke, Vosselaar', users: '6 - 12 jaar', startingPrice: false, price: '125.00', logo: Org, status: false, cover: SearchedEvent2},
        { id: 3, name: 'Adventure & Hockey Fun', date: '15 juli - 19 juli 2024', time: '9u - 14u', location: 'Sporthal Diepvenneke, Vosselaar', users: '6 - 12 jaar', startingPrice: false, price: '125.00', logo: false, status: false, cover: null},
      ],
      items: [
        'https://via.placeholder.com/600x400/FF5733/FFFFFF?text=Slide+1',
        'https://via.placeholder.com/600x400/33FF57/FFFFFF?text=Slide+2',
        'https://via.placeholder.com/600x400/3357FF/FFFFFF?text=Slide+3',
      ],
      breakpoints: {
        1200: {
            slidesPerView: 4.25,
            loopedSlides: 4,
            spaceBetween: 10
        },
        1024: {
            slidesPerView: 4.25,
            loopedSlides: 3,
            spaceBetween: 10
        },
        768: {
            slidesPerView: 2,
            loopedSlides: 2,
            spaceBetween: 10
        },
        675: {
            slidesPerView: 1,
            loopedSlides: 1,
            spaceBetween: 20
        }
        },
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        centeredSlides: true,
      },
      selectedSortValue: null,
      location: '',
      sortOptions: [
        { value: 'most_relevant', label: 'Meest relevant' },
        { value: 'popular', label: 'Populair' },
        { value: 'new', label: 'Nieuw' },
        { value: 'lowest_price', label: 'Laagste prijs' },
        { value: 'in_my_neighborhood', label: 'In mijn buurt' }
      ],
      selectedLocationValue: [],
      locationOptions: [],
      locationBackupOptions: [],
      showMoreLocation: false,
      selectedPeriodValue: [],
      periodOptions: [
        { value: 1, label: 'Herfstvakantie' },
        { value: 2, label: 'Kerstvakantie' },
        { value: 3, label: 'Krokusvakantie' },
        { value: 4, label: 'Paasvakantie' },
        { value: 5, label: 'Zomervakantie' },
        { value: 6, label: 'Zomervakantie 2' },
        { value: 7, label: 'Zomervakantie 3' },
      ],
      showMorePeriod: false,
      selectedActivitiesValue: [],
      activitiesOptions: [],
      activitiesBackupOptions: [],
      showMoreActivities: false,
      organization: '',
      selectedOrganizationValue: [],
      organizationOptions: [],
      organizationBackupOptions: [],
      showMoreOrganization: false,
      minAgeValue: 0,
      maxAgeValue: 18,
      minPriceValue: 0,
      maxPriceValue: 1000,
      responsiveFilterModal: null,
      searching: false,
      searchedResult: [],
      nextPageUrl: null,
      currentPage: 1,
      isOvernightStay: false,
      isLunch: false,
      isUitpas: false,
      isHappyGoCamp: false,
      isDaycare: false,
      isFiltered: false
    };
  },
  computed: {
    displayedLocationOptions() {
      return this.showMoreLocation ? this.locationOptions : this.locationOptions.slice(0, 5);
    },
    displayedPeriodOptions() {
      return this.showMorePeriod ? this.periodOptions : this.periodOptions.slice(0, 5);
    },
    displayedActivitiesOptions() {
      return this.showMoreActivities ? this.activitiesOptions : this.activitiesOptions.slice(0, 5);
    },
    displayedOrganizationOptions() {
      return this.showMoreOrganization ? this.organizationOptions : this.organizationOptions.slice(0, 5);
    },
    displayedFilterCount() {
      const selectedOrganizationCount = Array.isArray(this.selectedOrganizationValue) ? this.selectedOrganizationValue.length : 0;
      const selectedActivitiesCount = Array.isArray(this.selectedActivitiesValue) ? this.selectedActivitiesValue.length : 0;
      const selectedLocationCount = Array.isArray(this.selectedLocationValue) ? this.selectedLocationValue.length : 0;
      const selectedSortCount = this.selectedSortValue ? 1 : 0;
      const selectedOvernight = this.isOvernightStay ? 1 : 0;
      const selectedLunch = this.isLunch ? 1 : 0;
      const selectedUitpas = this.isUitpas ? 1 : 0;
      const selectedHappyGo = this.isHappyGoCamp ? 1 : 0;
      const selectedDaycare = this.isDaycare ? 1 : 0;
      const selectedPeriod = this.filterDateRange ? 1 : 0;
      const selectedPrice = this.minPriceValue > 0 || this.maxPriceValue < 1000 ? 1 : 0;
      const selectedAge = this.minAgeValue > 0 || this.maxAgeValue < 18 ? 1 : 0;
      // Sum the counts of all selected filters
      return selectedOrganizationCount + selectedActivitiesCount + selectedSortCount + selectedLocationCount +
      selectedOvernight + selectedLunch + selectedUitpas + selectedHappyGo + selectedDaycare + selectedPeriod
      + selectedPrice + selectedAge;
    }
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.user = JSON.parse(localStorage.getItem('loggedin_user'));
    this.responsiveFilterModal = new Modal(document.querySelector('#responsiveFilterModal'));
    // window.addEventListener('scroll', this.handleScroll);
  },
  created() {
    this.fetchNotifications();
    this.fetchPopularAndRecentCamps();
    this.fetchAllOrganization();
    this.fetchAllActivities();
    this.fetchAllLocations();
  },
  // beforeDestroy() {
  //   // Remove the event listener when the component is destroyed
  //   window.removeEventListener('scroll', this.handleScroll);
  // },
  methods: {
    async fetchPopularAndRecentCamps() {
      this.loading = true;
      fetchSearchCamps()
      .then((response) => {
        this.loading = false;
        this.popularCamps = response.data?.popular_camps;
        this.recentCamps = response.data?.recent_camps;
      })
      .catch((error) => {
        this.loading = false;
        console.log(error.message);
      });
    },
    formatDatePeriod(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based, so add 1
      const day = date.getDate().toString().padStart(2, '0'); // Ensure two-digit day
    
      return `${year}-${month}-${day}`;
    },
    async fetchCamps(page) {
      this.loading = true;
      let filters = `&camp_name=${this.search}`;
      if(this.isFiltered) {
        if (this.selectedOrganizationValue.length > 0) {
          this.selectedOrganizationValue.forEach((element, index) => {
            filters += `&organization[${index}]=${element}`;
          });
        }
        if (this.selectedActivitiesValue.length > 0) {
          this.selectedActivitiesValue.forEach((element, index) => {
            filters += `&activities[${index}]=${element}`;
          });
        }
        if (this.selectedLocationValue.length > 0) {
          this.selectedLocationValue.forEach((element, index) => {
            filters += `&location[${index}]=${element}`;
          });
        }
        if (this.selectedSortValue !== null) {
            filters += `&sort=${this.selectedSortValue}`;
        }
        if(this.filterDateRange) {
          const rawDate = toRaw(this.filterDateRange);

          const start_date = rawDate[0].toISOString().split('T')[0];
          const end_date = rawDate[1].toISOString().split('T')[0];
          filters += `&start_date=${start_date}&end_date=${end_date}`
        }

        if (this.isOvernightStay) {
          filters += `&tags[overnight]=${this.isOvernightStay}`
        }
        if (this.isDaycare) {
          filters += `&tags[daycare]=${this.isDaycare}`
        }
        if (this.isLunch) {
          filters += `&tags[lunch]=${this.isLunch}`
        }
        if (this.isUitpas) {
          filters += `&tags[uitpas]=${this.isUitpas}`
        }
        if (this.isHappyGoCamp) {
          filters += `&tags[happygo]=${this.isHappyGoCamp}`
        }
        if (this.minPriceValue > 0 || this.maxPriceValue < 1000) {
          filters += `&price[min]=${this.minPriceValue}&price[max]=${this.maxPriceValue}`;
        }
        if (this.minAgeValue > 0 || this.maxAgeValue < 18) {
          filters += `&min_age=${this.minAgeValue}&max_age=${this.maxAgeValue}`;
        }
      }
      fetchAllCamps(page, filters)
      .then((response) => {
        this.loading = false;
        if (response.data.paginated_camps.length === 0) {
          this.campsData = [];
          this.otherCampsData = response.data.additional_camps;
          this.nextPageUrl = null;
          this.currentPage = 1;
        } else {
          if (this.isFiltered) {
            this.campsData = response.data.paginated_camps?.data;
            // Update nextPageUrl and currentPage
            this.nextPageUrl = response.data.paginated_camps?.next_page_url;
            this.currentPage = response.data.paginated_camps?.current_page;
          } 
          else if (this.search !== '') {
            this.campsData = response.data.paginated_camps?.data;
            // Update nextPageUrl and currentPage
            this.nextPageUrl = response.data.paginated_camps?.next_page_url;
            this.currentPage = response.data.paginated_camps?.current_page;
          } else {
            // console.log("this.campsData", this.campsData)
            // Append the new items to the list
            this.campsData.push(...response.data.paginated_camps.data);

            // Update nextPageUrl and currentPage
            this.nextPageUrl = response.data.paginated_camps?.next_page_url;
            this.currentPage = response.data.paginated_camps?.current_page;
          }
        }
      })
      .catch((error) => {
        this.loading = false;
        console.log(error.message);
      });
    },
    filterCamps() {
      this.isFiltered = true;
      this.fetchCamps(1);
    },
    fetchAllOrganization() {
      getAllOrganization()
      .then((response) => {
        this.organizationOptions = response;
        this.organizationBackupOptions = response;
      }).catch((error) => {
        console.error("Error fetching organizations:", error);
      });
    },
    fetchAllActivities() {
      getAllActivities()
      .then((response) => {
        this.activitiesOptions = response.data;
        this.activitiesBackupOptions = response.data;
      }).catch((error) => {
        console.error("Error fetching activities:", error);
      });
    },
    fetchAllLocations() {
      getAllLocations()
      .then((response) => {
        this.locationOptions = response.data;
        this.locationBackupOptions = response.data;
      }).catch((error) => {
        console.error("Error fetching locations:", error);
      });
    },
    // Handle the scroll event
    handleScroll() {
      const threshold = 200; // Set this to the number of pixels before reaching the bottom

      // Check if the user has scrolled to within a threshold distance from the bottom
      const bottomOfPage = document.documentElement.scrollHeight - document.documentElement.scrollTop - window.innerHeight <= threshold;
    
      // If we've scrolled to the bottom and there is a next page URL, and we're not already loading data
      if (bottomOfPage && this.nextPageUrl && !this.loading) {
        // Extract the page number from the next_page_url
        const pageNumberMatch = this.nextPageUrl.match(/page=(\d+)/);
        if (pageNumberMatch) {
          const nextPageNumber = pageNumberMatch[1];
          // Fetch data for the next page using the page number
          this.fetchCamps(nextPageNumber);
        }
      }
    },
    updateHappyGoCampCheckedState(event) {
      this.isHappyGoCamp = event.target.checked;
    },
    updateUitpasCheckedState(event) {
      this.isUitpas = event.target.checked;
    },
    updateOvernightStayCheckedState(event) {
      this.isOvernightStay = event.target.checked;
    },
    updateDaycareCheckedState(event) {
      this.isDaycare = event.target.checked;
    },
    updateLunchCheckedState(event) {
      this.isLunch = event.target.checked;
    },
    handleOrgChange() {
      if (this.organization.trim() === '') {
        // If search input is empty, show all options (from the backup)
        this.organizationOptions = this.organizationBackupOptions;
      } else {
        // Otherwise, filter the options based on the input value
        this.organizationOptions = this.organizationBackupOptions.filter(option =>
          option.name.toLowerCase().includes(this.organization.toLowerCase())
        );
      }
    },
    handleLocationChange() {
      if (this.location === '') {
        // If search input is empty, show all options (from the backup)
        this.locationOptions = this.locationBackupOptions;
      } else {
        // Otherwise, filter the options based on the input value
        this.locationOptions = this.locationBackupOptions.filter(option =>
          option.postcode == this.location
        );
      }
    },
    displayCampPrice(subGroups) {
      if (subGroups.length > 1) {
        let lowestPrice = Math.min(...subGroups.map(item => parseFloat(item.total_amount !== null && item.total_amount !== '' ? item.total_amount : 0)));
        return `${lowestPrice.toFixed(2)}`;
      } else if (subGroups.length === 1) {
        return `${parseFloat(subGroups[0].total_amount).toFixed(2)}`;
      }
    },
    displaySearchedCampPrice(subGroups) {
      if (subGroups.length > 1) {
        let lowestPrice = Math.min(...subGroups.map(item => parseFloat(item.price !== null && item.price !== '' ? item.price : 0)));
        return `${lowestPrice.toFixed(2)}`;
      } else if (subGroups.length === 1) {
        return `${parseFloat(subGroups[0].price).toFixed(2)}`;
      }
    },
    displayCampUsers(subGroups) {
      if (subGroups.length > 1) {
        let min_age = Math.min(...subGroups.map(item => item.min_age));
        return `Vanaf ${min_age} jaar`;
      } else if (subGroups.length === 1) {
        return `${(subGroups[0].min_age)} - ${(subGroups[0].max_age)} jaar`;
      }
    },
    displayCampDate(campDates) {
      // Check if the campDates array is empty or doesn't have the expected structure
      if (!campDates || campDates.length === 0) return "No dates available";
  
      const camp = campDates[0];
  
      // Parse the dates
      const minDate = new Date(camp.min_start_date);
      const maxDate = new Date(camp.max_start_date);
  
      // Check if the min and max dates are the same
      if (minDate.getTime() === maxDate.getTime()) {
          return this.formatDate(minDate);
      } else {
          return `${this.formatDate(minDate)} - ${this.formatDate(maxDate)}`;
      }
    },
    formatDate(date) {
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        return date.toLocaleDateString('nl-NL', options);
    },
    displayCampTime(campData) {
      // Extract start_time and end_time from the input
      const { start_time, end_time } = campData;
  
      // Convert time strings to Date objects
      const start = new Date(`1970-01-01T${start_time}`); // Use a fixed date to convert time
      const end = new Date(`1970-01-01T${end_time}`);
  
      // Format time as "h:mm" or "h" if minutes are 0
      const formattedStart = this.formatTime(start);
      const formattedEnd = this.formatTime(end);
  
      return `${formattedStart} - ${formattedEnd}`;
    },
    formatTime(date) {
        // Extract hours and minutes
        let hours = date.getHours();
        let minutes = date.getMinutes();
    
        // Format time in "h:mm" or "h" if minutes are 0
        if (minutes === 0) {
            return `${hours}u`;
        } else {
            return `${hours}:${minutes < 10 ? '0' + minutes : minutes}u`;
        }
    },  
    displayVanaf(subGroups) {
      if (subGroups.length > 1) {
        return true;
      } else if (subGroups.length === 1) {
        return false;
      }
    },
    async fetchNotifications() {
      this.loading = true;

      if (!this.user) return;

      getNotifications(this.user.id)
        .then((response) => {
          this.loading = false;
          const firstUnreadNotif = response.data && response.data.find((n) => n.is_read === 0);
          if (firstUnreadNotif) {
            this.notification = firstUnreadNotif;
            this.showNotification = true;
            
            return;
          }

          this.notification = null;
          this.showNotification = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.message);
        });
    },
    getNotificationIcon(icon) {
      let iconResult;

      try {
        iconResult = require(`@/assets/icons/ic-notification-${icon}.svg`);
      } catch (e) {
        iconResult = require('@/assets/icons/ic-notification-fallback.svg');
      }

      return iconResult;
    },
    onClickNotification(n) {
      if (n.url) {
        window.open(n.url);
      }

      if (!this.user) return;

      readNotification(n)
        .then(() => {
          this.fetchNotifications();
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
    update_AgeValues(e) {
      this.minAgeValue = e.minValue;
      this.maxAgeValue = e.maxValue;
    },
    update_PriceValues(e) {
      this.minPriceValue = e.minValue;
      this.maxPriceValue = e.maxValue;
    },
    toggleLocationShowMore() {
      this.showMoreLocation = !this.showMoreLocation;
    },
    togglePeriodShowMore() {
      this.showMorePeriod = !this.showMorePeriod;
    },
    toggleActivitiesShowMore() {
      this.showMoreActivities = !this.showMoreActivities;
    },
    toggleOrganizationShowMore() {
      this.showMoreOrganization = !this.showMoreOrganization;
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    // Method to toggle the selection of an option (for multiple selections)
    selectLocationOption(value) {
      const index = this.selectedLocationValue.indexOf(value);
      if (index === -1) {
        // Add the option to the selected values array if it's not already selected
        this.selectedLocationValue.push(value);
      } else {
        // Remove the option from the selected values array if it's already selected
        this.selectedLocationValue.splice(index, 1);
      }
    },
    // Method to check if an option is selected
    isLocationSelected(value) {
      return this.selectedLocationValue.includes(value);
    },
    selectPeriodOption(value) {
      const index = this.selectedPeriodValue.indexOf(value);
      if (index === -1) {
        // Add the option to the selected values array if it's not already selected
        this.selectedPeriodValue.push(value);
      } else {
        // Remove the option from the selected values array if it's already selected
        this.selectedPeriodValue.splice(index, 1);
      }
    },
    // Method to check if an option is selected
    isPeriodSelected(value) {
      return this.selectedPeriodValue.includes(value);
    },
    selectActivitiesOption(value) {
      const index = this.selectedActivitiesValue.indexOf(value);
      if (index === -1) {
        // Add the option to the selected values array if it's not already selected
        this.selectedActivitiesValue.push(value);
      } else {
        // Remove the option from the selected values array if it's already selected
        this.selectedActivitiesValue.splice(index, 1);
      }
    },
    // Method to check if an option is selected
    isActivitiesSelected(value) {
      return this.selectedActivitiesValue.includes(value);
    },
    selectOrganizationOption(value) {
      const index = this.selectedOrganizationValue.indexOf(value);
      if (index === -1) {
        // Add the option to the selected values array if it's not already selected
        this.selectedOrganizationValue.push(value);
      } else {
        // Remove the option from the selected values array if it's already selected
        this.selectedOrganizationValue.splice(index, 1);
      }
    },
    // Method to check if an option is selected
    isOrganizationSelected(value) {
      return this.selectedOrganizationValue.includes(value);
    },
    selectSortOption(value) {
      if (this.selectedSortValue === value) {
        this.selectedSortValue = ''
      } else {
        this.selectedSortValue = value;
      }
    },
    resetFilters() {
      this.isFiltered = false
      this.selectedSortValue = null
      this.selectedLocationValue = []
      this.selectedActivitiesValue = []
      this.selectedPeriodValue = []
      this.selectedDate = null;
      this.filterDateRange = ref()
      this.selectedOrganizationValue = []
      this.location = ''
      this.organization = ''
      this.minAgeValue = 0;
      this.maxAgeValue = 18;
      this.minPriceValue = 0;
      this.maxPriceValue = 1000;
      this.campsData = []
      this.fetchCamps(1)
    },
    toggleFilter() {
      this.responsiveFilterModal.toggle();
    },
    handleFocus() {
      if (this.search == '') {
        this.fetchCamps(1)
      }
      this.searching = true
    },
    handleBlur() {
      this.searching = false
    },
    handleChange() {
      if (this.search == '') {
        this.campsData = []
      }
      this.fetchCamps(1)
    },
    clearSearchField() {
        this.searching = false
        this.search = ''
        this.campsData = []
        this.fetchCamps(1)
    },
    RedirectTo(path) {
      this.$router.push(path);
    },
    getPicture(picture) {
      return picture ? this.cdnPath + 'width=472,height=144,fit=cover,format=auto,metadata=none' + this.imgPathCamp + picture : require('@/assets/images/NoCover.png');
    },
    getLogo(picture) {
      return this.imgPath + picture;
    },
  },
  watch: {
    minAgeValue(newValue) {
      if (newValue == "") { 
        this.minAgeValue = 0;
      }
      if (newValue < 0) {
        this.minAgeValue = 0;
      } else if (newValue > 18) {
        this.minAgeValue = 18;
      }
    },
    maxAgeValue(newValue) {
      if (newValue == "") { 
        this.maxAgeValue = 18;
      }
      if (newValue < 0) {
        this.maxAgeValue = 0;
      } else if (newValue > 18) {
        this.maxAgeValue = 18;
      }
    },
    minPriceValue(newValue) {
      if (newValue == "") { 
        this.minPriceValue = 0;
      }
      if (newValue < 0) {
        this.minPriceValue = 0;
      } else if (newValue > 1000) {
        this.minPriceValue = 1000;
      }
    },
    maxPriceValue(newValue) {
      if (newValue == "") { 
        this.maxPriceValue = 1000;
      }
      if (newValue < 0) {
        this.maxPriceValue = 0;
      } else if (newValue > 1000) {
        this.maxPriceValue = 1000;
      }
    }
  }
}