// src/services/apiService.js
import axios from 'axios';
import { API_ENDPOINTS } from '../config/constants';

const apiClient = axios.create({
    baseURL: API_ENDPOINTS.BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Automatically add auth token if available
apiClient.interceptors.request.use((config) => {
    const token = localStorage.getItem('authToken');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

// Login function
export const login = async (email, password) => {
    try {
        const response = await apiClient.post(API_ENDPOINTS.LOGIN, { email, password });
        // Check if the response indicates an error
        if (response.data.status === 'error') {
            throw new Error(response.data.message); // Throw an error with the message from the API
        }

        return response.data; // Return the data if the login is successful
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Inloggen mislukt. Probeer het opnieuw.'); // Use fallback message
    }
};
// Register function
export const register = async (dataArr) => {
    try {
        const response = await apiClient.post(API_ENDPOINTS.REGISTER, dataArr);
        // Check if the response indicates an error
        console.log("RESPONSE:", response.data);
        if (response.data.status === 'error') {
            throw new Error(response.data.message); // Throw an error with the message from the API
        }

        return response.data; // Return the data if the login is successful
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Registration failed. Please try again.'); // Use fallback message
    }
};
// Verify function
export const verifyAccount = async (dataArr) => {
    try {
        const response = await apiClient.post(API_ENDPOINTS.VERIFY, dataArr);
        // Check if the response indicates an error
        console.log("RESPONSE:", response.data);
        if (response.data.status === 'error') {
            throw new Error(response.data.message); // Throw an error with the message from the API
        }

        return response.data; // Return the data if the login is successful
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Registration failed. Please try again.'); // Use fallback message
    }
};

// fogot function
export const forgotPassword = async (dataArr) => {
    try {
        const response = await apiClient.post(API_ENDPOINTS.FOGOT_PASSWORD, dataArr);
        // Check if the response indicates an error
        console.log("RESPONSE:", response.data);
        if (response.data.status === 'error') {
            throw new Error(response.data.message); // Throw an error with the message from the API
        }

        return response.data; // Return the data if the login is successful
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Registration failed. Please try again.'); // Use fallback message
    }
};


// fogot function
export const resetPassword = async (dataArr) => {
    try {
        const response = await apiClient.post(API_ENDPOINTS.RESET_PASSWORD, dataArr);
        // Check if the response indicates an error
        console.log("RESPONSE:", response.data);
        if (response.data.status === 'error') {
            throw new Error(response.data.message); // Throw an error with the message from the API
        }

        return response.data; // Return the data if the login is successful
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Registration failed. Please try again.'); // Use fallback message
    }
};

export const logoutForAllUser = async () => {
    try {
        const response = await apiClient.post(API_ENDPOINTS.LOGOUT, {});
        // Check if the response indicates an error
        if (response.data.status === 'error') {
            throw new Error(response.data.message); // Throw an error with the message from the API
        }

        return response.data; // Return the data if the login is successful
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Inloggen mislukt. Probeer het opnieuw.'); // Use fallback message
    }
};
// Add more API functions as needed

// Family List function
export const getAllFamilyMembers = async (id) => {
    try {
        const response = await apiClient.get(API_ENDPOINTS.FAMILY_LIST + "?user_id=" + id);
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Family Friends List function
export const getAllFamilyFriends = async (id) => {
    try {
        const response = await apiClient.get(API_ENDPOINTS.FAMILY_FRIENDS_LIST + "/" + id);
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Friends List function
export const getAllFriends = async (id) => {
    try {
        const response = await apiClient.get(API_ENDPOINTS.FRIENDS_LIST + "?user_id=" + id);
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Update Family Name
export const updateFamilyName = async (params) => {
    try {
        const response = await apiClient.post(API_ENDPOINTS.EDIT_FAMILY_NAME, params);
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Update Family Registration Number
export const updateFamilyRegistrationNumber = async (params) => {
    try {
        const response = await apiClient.post(API_ENDPOINTS.EDIT_FAMILY_REGISTRATION_NUMBER, params);
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Add Family
export const AddFamilyMember = async (params, role, id) => {
    try {
        const response = await apiClient.post(API_ENDPOINTS.ADD_FAMILY + '/' + role + '/' + id, params, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Add Account Details
export const AddAccountNumber = async (params) => {
    try {
        const response = await apiClient.post(API_ENDPOINTS.ADD_ACCOUNT, params, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Update Family
export const UpdateFamilyMember = async (params, id) => {
    try {
        const response = await apiClient.post(API_ENDPOINTS.UPDATE_FAMILY + '/' + id, params, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Update Child
export const UpdateChild = async (params) => {
    try {
        const response = await apiClient.post(API_ENDPOINTS.UPDATE_CHILD, params, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Update Child Activity
export const UpdateChildActivities = async (params) => {
    try {
        const response = await apiClient.post(API_ENDPOINTS.UPDATE_CHILD_ACTIVITY, params, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Update Child Medical Info
export const UpdateChildMedicalInfo = async (params) => {
    try {
        const response = await apiClient.post(API_ENDPOINTS.UPDATE_MEDICAL_INFO, params, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Add Child
export const AddChild = async (params) => {
    try {
        const response = await apiClient.post(API_ENDPOINTS.ADD_CHILD, params, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Add Child Activity
export const AddChildActivity = async (params) => {
    try {
        const response = await apiClient.post(API_ENDPOINTS.ADD_CHILD_ACTIVITY, params, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Add Medical Info
export const AddMedicalInfo = async (params) => {
    try {
        const response = await apiClient.post(API_ENDPOINTS.ADD_MEDICAL_INFO, params, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Family Member function
export const getFamilyMemberData = async (id) => {
    try {
        const response = await apiClient.get(API_ENDPOINTS.FAMILY_MEMBER_DATA + "/" + id);
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Family Member function
export const getChildData = async (id) => {
    try {
        const response = await apiClient.get(API_ENDPOINTS.FETCH_CHILD_DATA + "/" + id);
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Family Member function
export const getAllChildrenList = async (id) => {
    try {
        const response = await apiClient.get(API_ENDPOINTS.GET_CHILD_LIST + "/" + id);
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Delete Family Member
export const DeleteFamily = async (params) => {
    try {
        const response = await apiClient.post(API_ENDPOINTS.DELETE_PROFILE, params, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Send Friend Request
export const sendFriendRequest = async (params) => {
    try {
        const response = await apiClient.post(API_ENDPOINTS.SEND_REQUEST, params, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Accept Friend Request
export const acceptFriendRequest = async (params) => {
    try {
        const response = await apiClient.post(API_ENDPOINTS.ACCEPT_FRIEND_REQUEST, params, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Reject Friend Request
export const rejectFriendRequest = async (id) => {
    try {
        const response = await apiClient.get(API_ENDPOINTS.REJECT_FRIEND_REQUEST + '/' + id);
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Get Friend Request Details
export const fetchFriendRequestDetails = async (id) => {
    try {
        const response = await apiClient.get(API_ENDPOINTS.GET_FRIEND_REQUEST + '/' + id);
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Get All Activities
export const getAllActivities = async () => {
    try {
        const response = await apiClient.get(API_ENDPOINTS.GET_ALL_ACTIVITIES);
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Get All Activities
export const getAllLocations = async () => {
    try {
        const response = await apiClient.get(API_ENDPOINTS.GET_ALL_LOCATIONS);
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Get All Organization
export const getAllOrganization = async () => {
    try {
        const response = await apiClient.get(API_ENDPOINTS.GET_ALL_ORGANIZATION);
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// NOTIFICATIONS
export const getNotifications = async (userId) => {
    try {
        const response = await apiClient.get(`${API_ENDPOINTS.NOTIFICATION}/list/${userId}`);
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

export const readNotification = async (params) => {
    try {
        const response = await apiClient.post(`${API_ENDPOINTS.NOTIFICATION}/read/${params.id}`, params, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

export const readAllNotification = async (userId) => {
    try {
        const response = await apiClient.post(`${API_ENDPOINTS.NOTIFICATION}/read-all/${userId}`, {}, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

export const deleteNotification = async (param) => {
    try {
        const response = await apiClient.post(`${API_ENDPOINTS.DELETE_NOTIFICATION}`, param, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};
// END NOTIFICATIONS


// Camps List function
export const fetchAllCamps = async (page, filters) => {
    try {
        const response = await apiClient.get(API_ENDPOINTS.ALL_CAMPS + '?page=' + page + filters);
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Camps List function
export const fetchSearchCamps = async () => {
    try {
        const response = await apiClient.get(API_ENDPOINTS.ALL_SEARCH_CAMPS);
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Camps Details function
export const fetchCampDetails = async (id, appUserId) => {
    try {
        const response = await apiClient.get(API_ENDPOINTS.CAMP_DETAILS + '/' + id + '/' + appUserId);
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Registered Camps Details function
export const fetchRegisteredCampDetails = async (id, appUserId) => {
    try {
        const response = await apiClient.get(API_ENDPOINTS.REGISTERED_CAMP_DETAILS + '/' + id + '/' + appUserId);
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

export const fetchCampBookingDetails = async (id) => {
    try {
        const response = await apiClient.get(API_ENDPOINTS.CAMP + '/booking-detail/' + id);
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

export const campEnrollment = async (params) => {
    try {
        const response = await apiClient.post(API_ENDPOINTS.CAMP + '/enroll', params);
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || error.response?.data?.errors || 'Please try again.'); // Use fallback message
    }
};

export const enrollmentPaymentCheck = async (params) => {
    try {
        const response = await apiClient.post(API_ENDPOINTS.CAMP + '/payment-check', params);
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

export const enrollmentCompleteRegistration = async (params) => {
    try {
        const response = await apiClient.post(API_ENDPOINTS.CAMP + '/complete-registration', params);
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Camps Details function
export const fetchPreviewCampDetails = async (id) => {
    try {
        const response = await apiClient.get(API_ENDPOINTS.CAMP_OVERVIEW + '/' + id);
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Camps Details function
export const fetchPreviewOrganizationDetails = async (id) => {
    try {
        const response = await apiClient.get(API_ENDPOINTS.CAMP_OVERVIEW_ORGANIZATION + '/' + id);
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Organization Details function
export const fetchOrganizationDetails = async (id) => {
    try {
        const response = await apiClient.get(API_ENDPOINTS.CAMP_ORGANIZATION + '/' + id);
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

//Account Settings

// Change Password
export const ChangePassword = async (params) => {
    try {
        const response = await apiClient.post(API_ENDPOINTS.CHANGE_PASSWORD, params);
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Delete Account
export const DeleteAccount = async (params) => {
    try {
        const response = await apiClient.post(API_ENDPOINTS.DELETE_ACCOUNT, params);
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Deactivate Account
export const DeactivateAccount = async (params) => {
    try {
        const response = await apiClient.post(API_ENDPOINTS.DEACTIVATE_ACCOUNT, params);
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Mark As Favorite
export const MarkAsFavorite = async (params) => {
    try {
        const response = await apiClient.post(API_ENDPOINTS.MARK_AS_FAVORITE, params, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

//Registrations
// Fetch All Registrations function
export const fetchAllRegistrations = async (parentId, appUserId, filter) => {
    try {
        const response = await apiClient.get(API_ENDPOINTS.GET_ALL_REGISTRATIONS + '/' + parentId + '/' + appUserId + filter);
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};
// Fetch All Booking Registrations function
export const fetchAllBookingRegistrations = async (parentId, appUserId, childId) => {
    try {
        const response = await apiClient.get(API_ENDPOINTS.GET_ALL_BOOKING_REGISTRATIONS + '/' + parentId + '/' + appUserId + '/' + childId);
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Fetch All Registrations Child function
export const fetchAllRegistrationsChilds = async (familyid) => {
    try {
        const response = await apiClient.get(API_ENDPOINTS.GET_ALL_REGISTRATIONS_CHILD + '/' + familyid);
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Fetch All Enrolled Child function
export const fetchAllEnrolledChilds = async (bookingId) => {
    try {
        const response = await apiClient.get(API_ENDPOINTS.GET_ALL_ENROLLED_CHILDS + '/' + bookingId);
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Share Camps
export const shareCamps = async (params) => {
    try {
        const response = await apiClient.post(API_ENDPOINTS.SHARE_CAMP, params);
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Unsubscribe Camps
export const unsubscribeCamps = async (params) => {
    try {
        const response = await apiClient.post(API_ENDPOINTS.UNSUBSCRIBE_CAMP, params);
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Unsubscribe Camps Waiting List
export const unsubscribeCampsWaitingList = async (params) => {
    try {
        const response = await apiClient.post(API_ENDPOINTS.UNSUBSCRIBE_CAMP_WAITINGLIST, params);
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Fetch All Registrations Friends function
export const fetchAllRegistrationsFriends = async (familyid) => {
    try {
        const response = await apiClient.get(API_ENDPOINTS.GET_ALL_REGISTRATIONS_FRIENDS + '/' + familyid);
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Fetch All Calendar Events
export const fetchCalendarEvents = async ({ parentId, appUserId, familyId, params }) => {
    try {
        const response = await apiClient.get(`${API_ENDPOINTS.GET_EVENTS}/${parentId}/${appUserId}/${familyId}`, { params });
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Get Consents
export const getAllConsents = async (id) => {
    try {
        const response = await apiClient.get(`${API_ENDPOINTS.GET_CONSENTS}/${id}`);
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Set Consents
export const setConsent = async (params) => {
    try {
        const response = await apiClient.post(API_ENDPOINTS.SET_CONSENTS, params, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};

// Get Settings
export const getGeneralSettings = async () => {
    try {
        const response = await apiClient.get(`${API_ENDPOINTS.GET_SETTINGS}?type=General`);
        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        // Handle Axios or network errors here
        throw new Error(error.response?.data?.message || 'Please try again.'); // Use fallback message
    }
};