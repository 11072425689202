import loginImage from '../../assets/images/BIG-Illustration.png';
import shape from '../../assets/images/LoginShape.png';
import HeaderLogin from '../../assets/images/HeaderLogin.png';
import { verifyAccount } from '../../services/apiService';
export default {
  data() {
    return {
      loginImage,
      shape,
      HeaderLogin,
      token: '',
      successmessage: '',
      errorMessage: '',
    };
  },
  mounted() {
    this.token = this.$route.query.token;
    console.log("Token:", this.token);
    this.verifyToken(this.token)
  },
  methods: {
       verifyToken(mytoken) {
        const data = {
          token: mytoken
        }
           // Logic to handle Verification
           console.log('Verify with:', data);
           verifyAccount(data)
             .then((data) => {
               console.log('Verify successful:', data);
              this.successmessage = data.message;
             })
             .catch((error) => {
               console.error('Login failed:', error);
               this.errorMessage = error.message || 'Inloggen mislukt. Probeer het opnieuw.';
             });
    }
  }
}