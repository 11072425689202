<template>
  <FullPageLoader :isLoading="isLoading" />

  <div class="hap-calendar-header">
    <div class="container">
      <div class="refresh-icon d-md-none" @click="resetFilters" v-if="isFiltered">
          <i class="fa fa-refresh"></i>
      </div>
      <img
        class="imageClass compound-half-screen d-md-none"
        :src="AppCompoundShape"
        alt="top-header"
      />
      <div class=" d-md-none happy-go-logo d-flex justify-content-center align-items-center"
      @click="$router.push('/search')">
        <img class="imageClass" :src="Logo" alt="top-header-logo" />
      </div>
      <h4 class="d-none d-md-block">Kalender</h4>
      <div class="hap-calendar-header__button">
        <button class="btn" @click="toggleFilter">
          <svg class="d-none d-md-block" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.73175 5.32873C1.58525 4.03892 2.50087 2 4.22657 2H19.7729C21.4986 2 22.4142 4.03893 21.2677 5.32873L14.9997 12.3802V21C14.9997 21.3466 14.8203 21.6684 14.5254 21.8507C14.2306 22.0329 13.8625 22.0494 13.5525 21.8944L9.55251 19.8944C9.21372 19.725 8.99972 19.3788 8.99972 19V12.3802L2.73175 5.32873ZM19.7729 4H4.22657L10.7471 11.3356C10.9098 11.5187 10.9997 11.7551 10.9997 12V18.382L12.9997 19.382V12C12.9997 11.7551 13.0896 11.5187 13.2523 11.3356L19.7729 4Z" fill="url(#paint0_linear_6348_54098)"/>
            <defs>
            <linearGradient id="paint0_linear_6348_54098" x1="2.22266" y1="2" x2="22.2176" y2="21.5492" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FC425C"/>
              <stop offset="1" stop-color="#FE8C71"/>
            </linearGradient>
            </defs>
          </svg>
  
          <svg
            class="d-md-none"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.73224 5.32873C1.58574 4.03892 2.50136 2 4.22706 2H19.7734C21.4991 2 22.4147 4.03893 21.2682 5.32873L15.0002 12.3802V21C15.0002 21.3466 14.8208 21.6684 14.5259 21.8507C14.2311 22.0329 13.863 22.0494 13.553 21.8944L9.553 19.8944C9.21421 19.725 9.00021 19.3788 9.00021 19V12.3802L2.73224 5.32873ZM19.7734 4H4.22706L10.7476 11.3356C10.9103 11.5187 11.0002 11.7551 11.0002 12V18.382L13.0002 19.382V12C13.0002 11.7551 13.0901 11.5187 13.2528 11.3356L19.7734 4Z"
              fill="white"
            />
          </svg>
        </button>
        <!--<button class="btn" @click="addEvent">
          <i class="fa fa-plus"></i>
        </button>-->
      </div>
    </div>
  </div>
  <div class="container margin-bottom-80">
    <ul class="nav nav-tabs profile-tabs hap-calendar-tabs">
      <li class="nav-item" @click="changeTabState(1)">
        <a
          class="nav-link"
          :class="{ active: tabState === 1 }"
          id="week"
          data-bs-toggle="tab"
          data-bs-target="#week-pane"
          role="tab"
          aria-controls="week-pane"
          href="#"
          >Week</a
        >
      </li>
      <li class="nav-item" @click="changeTabState(2)">
        <a
          class="nav-link"
          :class="{ active: tabState === 2 }"
          id="month"
          data-bs-toggle="tab"
          data-bs-target="#month-pane"
          role="tab"
          aria-controls="month-pane"
          href="#"
          >Maand</a
        >
      </li>
      <li class="nav-item" @click="changeTabState(3)">
        <a
          class="nav-link"
          :class="{ active: tabState === 3 }"
          id="year"
          data-bs-toggle="tab"
          data-bs-target="#year-pane"
          role="tab"
          aria-controls="year-pane"
          href="#"
          >Jaar</a
        >
      </li>
    </ul>
    <div class="tab-content">
      <div
        class="tab-pane fade"
        :class="{ 'show active': tabState === 1 }"
        id="week-pane"
        role="tabpanel"
        aria-labelledby="week"
        tabindex="0"
      >
        <WeekView
          :events="events"
          :key="weekViewKey"
          @onShowEventModal="onShowEventModal"
        />
      </div>
      <div
        class="tab-pane fade"
        :class="{ 'show active': tabState === 2 }"
        id="month-pane"
        role="tabpanel"
        aria-labelledby="month"
        tabindex="1"
      >
        <MonthView
          :events="events"
          :key="monthViewKey"
          @onShowEventModal="onShowEventModal"
        />
      </div>
      <div
        class="tab-pane fade"
        :class="{ 'show active': tabState === 3 }"
        id="year-pane"
        role="tabpanel"
        aria-labelledby="year"
        tabindex="2"
      >
        <YearView :events="events" @onShowEventModal="onShowEventModal" />
      </div>
    </div>
  </div>

  <MobileNavigation />

  <div
    id="eventModal"
    class="hap-calendar-event modal fade font-quicksand-500"
    :class="{
      'hap-calendar-event-watchlist': isWatchList(),
    }"
  >
    <div class="modal-dialog" role="document">
      <div v-if="eventModalData" class="modal-content">
        <div
          class="hap-calendar-event__header d-flex justify-content-between align-items-center"
          :class="{
            [eventModalData.extendedProps.category]: true,
          }"
        >
          <h5
            class="font-quicksand-600 font-16 mb-0 text-ellipsis"
            id="descriptionModalLabel"
          >
            {{ eventModalData.title }}
          </h5>
          <a
            class="cursor-pointer"
            :class="{
              'text-dark': !isWatchList(),
              'text-light': isWatchList(),
            }"
            data-bs-dismiss="modal"
          >
            <i class="fa fa-close"></i>
          </a>
        </div>
        <div class="modal-body">
          <ul class="font-16 font-opensans-400 p-0">
            <li class="d-flex align-items-center justify-content-between gap-2">
              <div
                class="d-flex align-items-center gap-2"
                :style="{
                  width: isWatchList() ? 'calc(100% - 96px)' : '100%',
                }">
                <img src="@/assets/icons/ic-date.svg" alt="Date" />
                 <span
                  >{{ setDateEvent(eventModalData?.extendedProps?.booking_id) }}</span
                >
              </div>
              <div v-if="isWatchList()" class="status-waitingList">Wachtlijst</div>
            </li>
            <li class="d-flex align-items-center gap-2">
              <img src="@/assets/icons/ic-location.svg" alt="Place" />
              <span>{{ eventModalData.extendedProps.place.address }}</span>
            </li>
            <li class="d-flex align-items-center gap-2">
              <img src="@/assets/icons/ic-time.svg" alt="Time" />
              <span
                >{{ setTimeEvent(eventModalData?.extendedProps?.booking_id) }}</span
              >
            </li>
          </ul>
          <div
            v-if="eventModalData.extendedProps.children && eventModalData.extendedProps.children.length"
            class="d-flex align-items-center mb-3"
          >
            <template
              v-for="(f, i) in eventModalData.extendedProps.children"
              :key="`${f.name || f.friend_name || f.first_name}-${i}`"
            >
              <img
                v-if="i < 2"
                class="friend"
                :src="f.profile_image
                  ? cdnPath + 'width=50,height=50,fit=cover,format=auto,metadata=none' + imgPathCamp + f.profile_image
                  : require('@/assets/images/calendar-default-avatar.png')"
                :alt="f.name || f.friend_name || f.first_name"
                :title="f.name || f.friend_name || f.first_name"
              />
            </template>
            <div
              v-if="eventModalData.extendedProps.children && eventModalData.extendedProps.children.length >= 2"
              class="friend-count"
            >
              +{{ eventModalData.extendedProps.children.length - 2 }}
            </div>
          </div>
          <div class="text-center">
            <button
              v-if="isWatchList()"
              type="button"
              class="btn-theme mt-2"
              data-bs-dismiss="modal"
              @click="$router.push(`/registered-camp-details/${eventModalData.extendedProps?.booking_id}`)">
              Bekijk kamp
            </button>
            <div
              v-if="!isWatchList()"
              type="button"
              class="btn-outline-primary"
              data-bs-dismiss="modal"
              @click="$router.push(`/camp-details/${eventModalData.extendedProps.place.camp_id}`)"
            >
              <a href="">Bekijk kamp</a>
            </div>
          </div>
          <div v-if="isWatchList()" class="text-center mt-3">
            <span
              class="color-primary cursor-pointer font-16 font-opensans-600"
              data-bs-dismiss="modal"
              >Uit wachtlijst halen</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    id="filterModal"
    class="hap-filter-modal modal fade font-quicksand-500 calender-filter"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content min-h-100vh">
        <div class="hap-header">
          <div class="container">
            <a href="#" @click="toggleFilter">
              <i class="fa fa-chevron-left"></i>
            </a>
  
            <h4>Filter kalender</h4>
  
            <div></div>
          </div>
        </div>

        <div class="container py-4">
          <div class="hap-form calendar">
            <div class="hap-form-check hap-form-check__friends mb-4">
              <div
                v-for="(c, ci) in children"
                :key="`friend-check-${c.id}`"
                class="form-check"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  :value="c.id"
                  :id="`friend-check-${c.id}`"
                />
                <label
                  class="form-check-label"
                  :for="`friend-check-${c.id}`"
                  @click="toggleChildFilter(ci)"
                >
                  <img
                    :src="
                      c.profile_image
                      ? imageUrl(c.profile_image)
                      : require('@/assets/images/calendar-default-avatar.png')
                    "
                    :alt="`${c.first_name} ${c.last_name}`"
                  />
                  {{ c.first_name }} {{ c.last_name }}
                </label>
              </div>
            </div>

            <h4 class="hap-form__title mt-4">Periode</h4>

            <VueDatePicker range format="MM/dd/yyyy" v-model="filterDateRange">
            </VueDatePicker>

            <div class="btn-bottom calendar">
              <button type="button" class="btn-theme mb-3" @click="doFilter">Opslaan</button>
              <div class="text-center w-100">
                <p @click="resetFilters"
                  class="color-primary mb-0 btn-theme-outline cursor-pointer font-16 font-opensans-600"
                  >Wis alle filters</p
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./index.js"></script>