import { forgotPassword } from '../../services/apiService';
import FullPageLoader from '../../components/FullPageLoader.vue';

export default {
  components: {
    FullPageLoader,
  },
  data() {
    return {
      email: '',
      emailError: '',
      errorMessage:'',
      loading: false,
    };
  },
  methods: {
    validate() {
      this.emailError = '';
      
      if (!this.email) {
        this.emailError = 'E-mail is vereist';
      } else if (!this.validEmail(this.email)) {
        this.emailError = 'E-mail is ongeldig';
      }

      return !(this.emailError);
    },
    validEmail(email) {
      const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return regex.test(email);
    },
    handleSubmit() {
      if (this.validate()) {
        const data = {
          email: this.email
        }
           // Logic to handle login
           this.loading = true;
           forgotPassword(data)
             .then((data) => {
               this.loading = false;
               sessionStorage.setItem('successMessage', data.message); // Store message temporarily
               this.$router.push('/success');
             })
             .catch((error) => {
               this.loading = false;
               this.errorMessage = error.message || 'Inloggen mislukt. Probeer het opnieuw.';
             });
      }
    }
  }
}