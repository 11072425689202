import ToastManager from '@/components/ToastMessages/ToastManager';
import { getNotifications, readNotification } from '@/services/apiService';
import { defineStore } from 'pinia';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    authToken: localStorage.getItem('authToken') || null,
    loggedInUser: JSON.parse(localStorage.getItem('loggedin_user')) || null,
    errorMessage: '',
    notificationsInterval: null, 
    notificationsCount: 0,
    lastRoute: ''
  }),
  actions: {
    setAuthData(data) {
      this.authToken = data.token;
      this.loggedInUser = data.data;
      // console.log("Data USERSSSS:", JSON.stringify(data.data));
      // Store data in localStorage for persistence
      localStorage.setItem('authToken', data.token);
      localStorage.setItem('loggedin_user', JSON.stringify(data.data));
    },
    setLastRoute(url) {
      this.lastRoute = url;
    },
    setUserData(data) {
      this.loggedInUser.parent = data;
      // Store data in localStorage for persistence
      localStorage.setItem('loggedin_user', JSON.stringify(this.loggedInUser));
    },
    logout() {
      this.clearNotificationsInterval();
      this.authToken = null;
      this.loggedInUser = null;
      localStorage.removeItem('authToken');
      localStorage.removeItem('loggedin_user');
    },
    setError(message) {
      this.errorMessage = message;
    },
    startNotificationsInterval(userId) {
      // Start the interval to call the notifications API every 10 seconds
      this.notificationsInterval = setInterval(() => {
        getNotifications(userId)
          .then((response) => {
            // console.log(response);
            if (response?.data?.length > 0) {
              response?.data.forEach(element => {
                const notification = element;
                if (notification?.is_read === 0) {
                  ToastManager.show(notification?.message, 'success', notification?.color_code, notification?.icon, false, '', '');
                  let data = {
                    id: notification.id
                  }
                  readNotification(data)
                  .then((response) => {
                    console.log(response?.message);
                  })
                  .catch((error) => {
                    console.log(error.message);
                  });
                }
              });
              // if (this.notificationsCount !== response?.data.length) {
              //   ToastManager.show(notification?.message, 'success', notification?.color_code, notification?.icon, false, '', '', notification.id);
              // }
            }
            if (response?.data?.length) {
              this.notificationsCount = response?.data?.filter(notification => notification.is_read === 0).length;
            }
          })
          .catch(() => {
            // console.log(error.message);
          });
      }, 10000); // 10 seconds interval
    },
    clearNotificationsInterval() {
      // Clear the interval
      if (this.notificationsInterval) {
        clearInterval(this.notificationsInterval);
        this.notificationsInterval = null;
      }
    }
  },
  getters: {
    isLoggedIn: (state) => !!state.authToken,
    user: (state) => state.loggedInUser,
    error: (state) => state.errorMessage,
  }
});
