<template>
  <div class="toaster-notification" :class="{ 'fade-in': visible && !fadingOut, 'fade-out': fadingOut }" 
      v-if="visible">
      <div class="toast-messages position-relative d-flex justify-content-between align-items-center">
          <div class="d-flex">
            <div class="margin-right-40">
                <div class="card-shape">
                  <svg v-if="type === 'success'" class="h-100" width="78" viewBox="0 0 78 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M32.6866 -82.053C32.694 -84.1821 32.7397 -86.2932 32.8237 -88.3862C33.3846 -102.517 36.1224 -117.372 30.422 -130.844C21.1739 -152.686 -3.08411 -153.968 -22.9377 -145.128C-36.2321 -139.212 -47.3226 -129.248 -54.6356 -116.649C-63.7321 -100.759 -65.1798 -82.5437 -69.652 -65.2434C-73.5539 -50.3821 -82.5532 -37.7703 -90.0209 -24.5004C-100.425 -6.01112 -114.066 12.1182 -113.703 34.3143C-113.052 74.4812 -73.9511 98.0061 -39.2468 107.003C-15.1742 113.254 15.9939 117.693 40.2195 110.386C64.007 103.216 77.2061 80.4945 77.0301 56.2955C76.8809 37.0946 69.4894 18.8018 60.8253 1.9157C52.8266 -13.6588 43.7053 -28.8485 37.9895 -45.4403C33.94 -57.1912 32.5856 -69.6406 32.6866 -82.053Z" fill="#F0F3DE"/>
                  </svg>
                  <svg v-if="type === 'error'" class="h-100" width="78" viewBox="0 0 78 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M32.6866 -82.053C32.694 -84.1821 32.7397 -86.2932 32.8237 -88.3862C33.3846 -102.517 36.1224 -117.372 30.422 -130.844C21.1739 -152.686 -3.08411 -153.968 -22.9377 -145.128C-36.2321 -139.212 -47.3226 -129.248 -54.6356 -116.649C-63.7321 -100.759 -65.1798 -82.5437 -69.652 -65.2434C-73.5539 -50.3821 -82.5532 -37.7703 -90.0209 -24.5004C-100.425 -6.01112 -114.066 12.1182 -113.703 34.3143C-113.052 74.4812 -73.9511 98.0061 -39.2468 107.003C-15.1742 113.254 15.9939 117.693 40.2195 110.386C64.007 103.216 77.2061 80.4945 77.0301 56.2955C76.8809 37.0946 69.4894 18.8018 60.8253 1.9157C52.8266 -13.6588 43.7053 -28.8485 37.9895 -45.4403C33.94 -57.1912 32.5856 -69.6406 32.6866 -82.053Z" fill="#FC425C"/>
                  </svg>
                </div>
                <div class="illustration">
                    <img class="imageClass" v-if="type === 'success'" src="@/assets/images/ToastSuccess.png" alt="card-shape"/>
                    <img class="imageClass" v-if="type === 'error'" src="@/assets/images/ToastError.png" alt="card-shape"/>
                </div>
            </div>
            <div v-if="!anchorTag" class="d-flex align-items-center margin-left-40">
                <h5 class="font-16 font-quicksand-500 mb-0 w-max-260px">{{ message }}</h5>
            </div>
          </div>
          <div v-if="anchorTag">
              <h5 class="font-16 font-quicksand-500 mb-1">{{ message }}</h5>
              <a :href="anchorUrl" class="font-16 font-opensans-600 mb-0 color-primary2">{{ anchorText }}</a>
          </div>
          <a @click="closeToast" class="cursor-pointer">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.78688 4.78688C4.23771 5.33606 4.23771 6.22644 4.78688 6.77562L8.01126 10L4.78688 13.2244C4.23771 13.7736 4.23771 14.6639 4.78688 15.2131C5.33606 15.7623 6.22644 15.7623 6.77562 15.2131L10 11.9887L13.2244 15.2131C13.7736 15.7623 14.6639 15.7623 15.2131 15.2131C15.7623 14.6639 15.7623 13.7736 15.2131 13.2244L11.9887 10L15.2131 6.77562C15.7623 6.22644 15.7623 5.33606 15.2131 4.78688C14.6639 4.23771 13.7736 4.23771 13.2244 4.78688L10 8.01126L6.77562 4.78688C6.22644 4.23771 5.33606 4.23771 4.78688 4.78688Z" fill="#4A4A4A"/>
            </svg>
          </a>
      </div>
  </div>
</template>

<script>

export default {
  props: {
    message: String,
    icon: String,
    background: String,
    anchorTag: Boolean,
    anchorText: String,
    anchorUrl: String,
    type: {
      type: String,
      default: 'info', // Can be 'success', 'error', 'warning', etc.
    },
    duration: {
      type: Number,
      default: 5000,
    },
  },

  data () {
    return {
      visible: true,
      fadingOut: false,
    }
  },
  mounted() {
    setTimeout(() => {
      this.fadingOut = true;
      setTimeout(this.closeToast, 500); // Match with fade-out duration
    }, this.duration);
  },
  methods: {
    closeToast() {
      if (this.visible) {
        this.visible = false;
        this.fadingOut = true; // Trigger fade-out
        this.$emit('close'); // Emit close event
        setTimeout(() => {
        this.$emit('removeToast'); // Emit event to call removeToast in the manager
        }, 500);
      }
    },
  },
}
</script>