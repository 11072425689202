<template>
  <FullPageLoader :isLoading="loading" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-5 col-lg-5 p-0 position-relative">
        <div class="gradient-bg-mobile">
          <h2 class="mt-5">Happy<span>Go</span></h2>
          <p class="mb-0">Welkom bij de beste kampzoeker van België!</p>
          <div class="login-illustration">
            <img class="login-shape" src="@/assets/images/LoginShape.png" alt="shape-img"/>
          </div>
        </div>
        <div class="gradient-bg-desktop d-flex justify-content-center align-items-center">
          <div class="text-center">
            <h2>Happy<span>Go</span></h2>
            <p class="mb-0">Welkom bij de beste kampzoeker van België!</p>
            <div class="login-illustration">
              <img class="login-shape" src="@/assets/images/LoginShape.png" alt="shape-img"/>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-5 col-lg-5 offset-md-1 offset-lg-1 mt-3 position-relative">
       
        <form @submit.prevent="handleSubmit" class="mt-5 p-3 center-desktop">
         
          <div class="form-group mt-2">
            <label for="email" class="input-label">E-mailadres</label>
            <input type="text" class="form-control form-input" v-model="email" placeholder="E-mailadres" />
            <div v-if="emailError" class="text-validation d-flex align-items-start mt-1">            
              <svg width="15" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.8295 5.8295C3.39017 6.26884 3.39017 6.98116 3.8295 7.4205L6.40901 10L3.8295 12.5795C3.39017 13.0188 3.39017 13.7312 3.8295 14.1705C4.26884 14.6098 4.98116 14.6098 5.4205 14.1705L8 11.591L10.5795 14.1705C11.0188 14.6098 11.7312 14.6098 12.1705 14.1705C12.6098 13.7312 12.6098 13.0188 12.1705 12.5795L9.59099 10L12.1705 7.4205C12.6098 6.98116 12.6098 6.26884 12.1705 5.8295C11.7312 5.39017 11.0188 5.39017 10.5795 5.8295L8 8.40901L5.4205 5.8295C4.98116 5.39017 4.26884 5.39017 3.8295 5.8295Z" fill="#F00000"/>
              </svg>
              {{ emailError }}
            </div>
            <div v-if="errorMessage" class="text-validation d-flex align-items-start mt-1">            
              <svg width="15" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.8295 5.8295C3.39017 6.26884 3.39017 6.98116 3.8295 7.4205L6.40901 10L3.8295 12.5795C3.39017 13.0188 3.39017 13.7312 3.8295 14.1705C4.26884 14.6098 4.98116 14.6098 5.4205 14.1705L8 11.591L10.5795 14.1705C11.0188 14.6098 11.7312 14.6098 12.1705 14.1705C12.6098 13.7312 12.6098 13.0188 12.1705 12.5795L9.59099 10L12.1705 7.4205C12.6098 6.98116 12.6098 6.26884 12.1705 5.8295C11.7312 5.39017 11.0188 5.39017 10.5795 5.8295L8 8.40901L5.4205 5.8295C4.98116 5.39017 4.26884 5.39017 3.8295 5.8295Z" fill="#F00000"/>
              </svg>
              {{ errorMessage }}
            </div>
          </div>
          <div class="form-group">
            <label for="password" class="input-label">Wachtwoord</label>
            <div class="password-group">              
              <svg class="lock-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.3125 18.125C4.88281 18.125 4.5151 17.9736 4.20937 17.6708C3.90312 17.3674 3.75 17.003 3.75 16.5774V8.83929C3.75 8.41369 3.90312 8.04923 4.20937 7.74589C4.5151 7.44308 4.88281 7.29167 5.3125 7.29167H6.09375V5.74405C6.09375 4.67361 6.47474 3.76103 7.23672 3.00631C7.99818 2.2521 8.91927 1.875 10 1.875C11.0807 1.875 12.0021 2.2521 12.7641 3.00631C13.5255 3.76103 13.9062 4.67361 13.9062 5.74405V7.29167H14.6875C15.1172 7.29167 15.4852 7.44308 15.7914 7.74589C16.0971 8.04923 16.25 8.41369 16.25 8.83929V16.5774C16.25 17.003 16.0971 17.3674 15.7914 17.6708C15.4852 17.9736 15.1172 18.125 14.6875 18.125H5.3125ZM5.3125 16.5774H14.6875V8.83929H5.3125V16.5774ZM10 14.256C10.4297 14.256 10.7977 14.1045 11.1039 13.8017C11.4096 13.4984 11.5625 13.1339 11.5625 12.7083C11.5625 12.2827 11.4096 11.9183 11.1039 11.6149C10.7977 11.3121 10.4297 11.1607 10 11.1607C9.57031 11.1607 9.2026 11.3121 8.89688 11.6149C8.59063 11.9183 8.4375 12.2827 8.4375 12.7083C8.4375 13.1339 8.59063 13.4984 8.89688 13.8017C9.2026 14.1045 9.57031 14.256 10 14.256ZM7.65625 7.29167H12.3438V5.74405C12.3438 5.09921 12.1159 4.55109 11.6602 4.0997C11.2044 3.64831 10.651 3.42262 10 3.42262C9.34896 3.42262 8.79557 3.64831 8.33984 4.0997C7.88411 4.55109 7.65625 5.09921 7.65625 5.74405V7.29167Z" fill="#4A4A4A"/>
              </svg>
              <input :type="passwordVisible ? 'text' : 'password'" class="form-control form-input pl-left-40px" v-model="password" placeholder="Wachtwoord" />
              <div v-if="passwordError" class="text-validation d-flex align-items-start mt-1">            
                <svg width="15" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.8295 5.8295C3.39017 6.26884 3.39017 6.98116 3.8295 7.4205L6.40901 10L3.8295 12.5795C3.39017 13.0188 3.39017 13.7312 3.8295 14.1705C4.26884 14.6098 4.98116 14.6098 5.4205 14.1705L8 11.591L10.5795 14.1705C11.0188 14.6098 11.7312 14.6098 12.1705 14.1705C12.6098 13.7312 12.6098 13.0188 12.1705 12.5795L9.59099 10L12.1705 7.4205C12.6098 6.98116 12.6098 6.26884 12.1705 5.8295C11.7312 5.39017 11.0188 5.39017 10.5795 5.8295L8 8.40901L5.4205 5.8295C4.98116 5.39017 4.26884 5.39017 3.8295 5.8295Z" fill="#F00000"/>
                </svg>
                {{ passwordError }}
              </div>
              <span class="eye-icon" @click="showPassword">
                <img :src="require(`@/assets/icons/ic-eye${passwordVisible ? '' : '-slash'}.svg`)">
              </span>
            </div>
          </div>
          <button type="submit" class="btn-theme mt-2">Login</button>
          <div class="text-center mt-3">
            <router-link class="color-primary font-16 font-opensans-600" to="/forgot-password">Wachtwoord vergeten</router-link>
          </div>
          <div class="mg-top-40px text-center p-2">
            <p class="color-black font-opensans-400 font-16">Ben je nog niet bekend bij ons? Maak dan eerst een nieuw account aan voor jezelf.</p>
          </div>
          <div class="btn-outline-primary cursor-pointer color-primary mb-3" @click="RedirectTo('/create-account')">
            Account aanmaken
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script src="./index.js"></script>