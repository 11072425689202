import loginImage from '../../assets/images/BIG-Illustration.png';
import shape from '../../assets/images/LoginShape.png';
import HeaderLogin from '../../assets/images/HeaderLogin.png';

export default {
  data() {
    return {
      loginImage,
      shape,
      HeaderLogin,
      successmessage: '',
    };
  },
  mounted() {
    this.successmessage = sessionStorage.getItem('successMessage') || 'Please verify your email address.';
    sessionStorage.removeItem('successMessage'); // Clear message after retrieval
  },
  methods: {
    RedirectTo(path) {
      this.$router.push(path);
    },
  }
}