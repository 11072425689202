import FullPageLoader from '../../components/FullPageLoader.vue';
import ChildAvatar from '../../assets/images/ChildAvatar.png';
import ParentAvatar from '../../assets/images/ParentAvatar.png';
import OtherAvatar from '../../assets/images/OtherAvatar.png';
import { useRoute } from 'vue-router';
import { acceptFriendRequest, fetchFriendRequestDetails, getAllChildrenList, rejectFriendRequest } from '@/services/apiService';
import { useAuthStore } from '@/store/authStore';
import ToastMessage from '@/components/ToastMessages/Generic/GenericToastManager';

export default {
    components: {
        FullPageLoader
    },
    data() {
    return {
        id: null,
        loading: false,
        details: null,
        familyChild: [],
        selectedChild: [],
        userInfo: '',
        imgPathCamp: process.env.VUE_APP_IMG_PATH_CAMP, 
        cdnPath: process.env.VUE_APP_CDN_PATH, 
        imgPath: process.env.VUE_APP_IMG_PATH, 
        placeholder: ParentAvatar,
        placeholderOther: OtherAvatar,
        placeholderChild: ChildAvatar,
    };
  },
  computed: {
    isMaxSelected() {
      return this.selectedChild.length >= 1;
    },
    
  },
  created() {
    const route = useRoute();
    const authStore = useAuthStore(); 
    this.userInfo = authStore.user;
    this.id = route.params.id;
    this.fetchDetail(route.params.id);
    this.getChildList();
  },
  methods: {
    async fetchDetail(id) {
      this.loading = true;
      fetchFriendRequestDetails(id)
      .then((response) => {
        this.loading = false;
        this.details = response.data;
      })
      .catch((error) => {
        this.loading = false;
        console.log(error.message);
      });
    },
    getChildList() {
      this.loading = true;
      getAllChildrenList(this.userInfo.parent.family_id)
      .then((response) => {
        this.familyChild = response.data;
      })
      .catch((error) => {
        console.log(error.message);
      });
    },
    rejectRequest() {
      this.loading = true;
      rejectFriendRequest(this.id)
      .then((response) => {
        this.loading = false;
        ToastMessage.show(response?.message, 'success');
        this.RedirectTo('/family')
      })
      .catch((error) => {
        this.loading = false;
        ToastMessage.show(error.message, 'error');
      });
    },
    acceptRequest() {

      let formData = new FormData()
      formData.append(`request_id`, this.id)
      if (this.selectedChild.length > 0) {
        for (let i = 0; i < this.selectedChild.length; i++) {
          formData.append(`child_id[${i}]`, this.selectedChild[i])
        }
      } else {
        ToastMessage.show('Please select a child', 'error');
        return
      }
      this.loading = true;
      acceptFriendRequest(formData)
      .then((response) => {
        this.loading = false;
        ToastMessage.show(response.message, 'success');
        this.RedirectTo('/family')
      })
      .catch((error) => {
        this.loading = false;
        console.log(error.message);
      });
    },
    toggleActivity(id) {
      const activitySelected = this.selectedChild.includes(id);

      if (this.isMaxSelected && !activitySelected) {
        // If the max selection is reached and the activity is not already selected, do nothing
        return;
      }

      if (activitySelected) {
        // If already selected, uncheck it
        this.selectedChild = this.selectedChild.filter(childId => childId !== id);
      } else {
        // If not selected, check it
        this.selectedChild.push(id);
      }
    },
    RedirectTo(path) {
      this.$router.push(path);
    },
    isActivityChecked(id) {
      return this.selectedChild.includes(id);
    },
    getProfilePicture(picture, role) {
      return picture ? this.cdnPath + 'width=200,height=200,fit=cover,format=auto,metadata=none' + this.imgPathCamp + picture : role === 3 ? this.placeholderOther : role === 4 ? this.placeholderChild : this.placeholder;
    },
  }
}