import { fetchAllBookingRegistrations, getChildData } from '@/services/apiService';
import AppCompoundShape from '../../../assets/images/AppCompoundShape.png';
import { useRoute } from 'vue-router';
import FullPageLoader from '../../../components/FullPageLoader.vue';
import ChildAvatar from '../../../assets/images/ChildAvatar.png';
import { useAuthStore } from '@/store/authStore';

export default {
    components: {
        FullPageLoader
    },
    data() {
        return {
        AppCompoundShape,
        childData: null,
        childId: 0,
        age: 0,
        imgPath: process.env.VUE_APP_IMG_PATH, 
        dob: '',
        placeholder: ChildAvatar,
        loading: false,
        isOpen: false,
        registrations: [],
        lastRegistrations: [],
        noResultRegistrations: false
        };
    },
    mounted () {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    created() {
        const authStore = useAuthStore(); 
        const route = useRoute();
        this.childId = route.params.id;
        this.fetchAllRegisteredBookings(authStore.user.parent.id, authStore.user.parent.app_user_id);
        this.fetchChildData(route.params.id);
    },
    methods: {
    async fetchChildData(id) {
        getChildData(id)
        .then((response) => {
            this.childData = response.data;
            if (response.data?.date_of_birth) {
                this.age = this.calculateAge(response.data?.date_of_birth)
                this.dob = this.formatDate(response.data?.date_of_birth)
            }
        })
        .catch((error) => {
            console.log(error.message);
        });
    },
    async fetchAllRegisteredBookings(parentId, appUserId) {
        this.loading = true;
        fetchAllBookingRegistrations(parentId, appUserId, this.childId)
        .then((response) => {
          this.loading = false;
        //   console.log(response)
          this.registrations = response.data?.new_bookings;
          this.lastRegistrations = response.data?.history_bookings;
        //   this.favorites = response.data?.favorite;
        //   this.waitingList = response.data?.waiting;
  
          if (response.data?.new_bookings.length === 0 && response.data?.history_bookings.length === 0) {
            this.noResultRegistrations = true;
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.message);
        });
    },
    getProfilePicture(picture) {
        return picture ? this.imgPath + picture : this.placeholder;
    },
    getActivityPicture(picture) {
        return this.imgPath + picture;
    },
    calculateAge(birthdate) {
        // Parse the birthdate into a Date object
        const birthDate = new Date(birthdate);
        
        // Get the current date
        const currentDate = new Date();
        
        // Calculate the difference in years
        let age = currentDate.getFullYear() - birthDate.getFullYear();
        
        // Adjust for the case where the current date is before the birthday this year
        const monthDifference = currentDate.getMonth() - birthDate.getMonth();
        if (monthDifference < 0 || (monthDifference === 0 && currentDate.getDate() < birthDate.getDate())) {
          age--;  // Subtract 1 if the current date is before the birthday
        }
        return age;
    },
    formatDate(dateString) {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    },
    displayCampUsers(min_age, max_age, is_subgroup) {
        if (is_subgroup === 'Yes') {
          return `Vanaf ${min_age} jaar`;
        } else {
          return `${min_age} - ${max_age} jaar`;
        }
      },
      displayCampDate(startDate, endDate) {
  
        // Parse the dates
        const minDate = new Date(startDate);
        const maxDate = new Date(endDate);
    
        // Check if the min and max dates are the same
        if (minDate.getTime() === maxDate.getTime()) {
            return this.formatCampDate(minDate);
        } else {
            return `${this.formatCampDate(minDate)} - ${this.formatCampDate(maxDate)}`;
        }
      },
      formatCampDate(date) {
          const options = { day: 'numeric', month: 'long', year: 'numeric' };
          return date.toLocaleDateString('nl-NL', options);
      },
      displayCampTime(start_time, end_time) {  
        // Convert time strings to Date objects
        const start = new Date(`1970-01-01T${start_time}`); // Use a fixed date to convert time
        const end = new Date(`1970-01-01T${end_time}`);
    
        // Format time as "h:mm" or "h" if minutes are 0
        const formattedStart = this.formatTime(start);
        const formattedEnd = this.formatTime(end);
    
        return `${formattedStart} - ${formattedEnd}`;
      },
      formatTime(date) {
          // Extract hours and minutes
          let hours = date.getHours();
          let minutes = date.getMinutes();
      
          // Format time in "h:mm" or "h" if minutes are 0
          if (minutes === 0) {
              return `${hours}u`;
          } else {
              return `${hours}:${minutes < 10 ? '0' + minutes : minutes}u`;
          }
      },
    getMarginStyle(item) {
        if (item.length === 1) {
            return { marginLeft: '0' };
        } else {
            return { marginLeft: '-15px' };
        }
    },
    RedirectTo(path) {
        this.$router.push(path);
    },
    toggleAccordion() {
        this.isOpen = !this.isOpen;
    },
    }
}