import { getAllActivities, getChildData, UpdateChild, UpdateChildActivities, UpdateChildMedicalInfo } from '@/services/apiService';
import FullPageLoader from '../../../components/FullPageLoader.vue';
import { useAuthStore } from '@/store/authStore';
import { ref } from 'vue';
import Datepicker from 'vue3-datepicker';
import ToastMessage from '@/components/ToastMessages/Generic/GenericToastManager';
import { Modal } from 'bootstrap';
import { useRoute } from 'vue-router';

export default {
    components: {
        Datepicker,
        FullPageLoader
    },
    setup() {
        // const selectedDate = ref(null); // Using ref for reactivity
        // Set upper limit to today's date (disables future dates)
        const upperLimit = new Date();
        upperLimit.setHours(23, 59, 59, 999); // Ensure the upper limit is set to the end of today
        return {
          // selectedDate,
          upperLimit
        };
    },
  data() {
    return {
        id: null,
        firstName: '',
        lastName: '',
        dob: '',
        uitpas: '',
        selectedDate: ref(null),
        registerationNumber: '',
        streetName: '',
        busNumber: '',
        postcode: '',
        localAuthority: '',
        firstNameError: '',
        lastNameError: '',
        dobError: '',
        streetNameError: '',
        busNumberError: '',
        localAuthorityError: '',
        postcodeError: '',
        selectedGender: '',
        currentTab: 0,
        imgPath: process.env.VUE_APP_IMG_PATH, 
        medical : {
            fullname : '',
            telephone : '',
            conditions : '',
            allergies : '',
            medications : '',
        },
        fullnameError: '',
        telephoneError: '',
        selectedMedical: 'no',
        tabs: [
            { id: 'general-info-tab-pane', label: 'Algemeen' },
            { id: 'activity-tab-pane', label: 'Activiteiten' },
            { id: 'medical-tab-pane', label: 'Medische fiche' },
        ],
        // activities: [
        //     { id: 1, name: 'Hockey', pic: require('@/assets/images/ActivityForm1.png'), checked: false },
        //     { id: 2, name: 'Football', pic: require('@/assets/images/ActivityForm2.png'), checked: false },
        //     { id: 3, name: 'Voetbal', pic: require('@/assets/images/ActivityForm3.png'), checked: false },
        //     { id: 4, name: 'Tennis', pic: require('@/assets/images/ActivityForm4.png'), checked: false },
        //     { id: 5, name: 'Zwemmen', pic: require('@/assets/images/ActivityForm5.png'), checked: false },
        //     { id: 6, name: 'Omni Sport', pic: require('@/assets/images/ActivityForm6.png'), checked: false },
        //     { id: 7, name: 'G-Sport', pic: require('@/assets/images/ActivityForm7.png'), checked: false },
        //     { id: 8, name: 'STEM', pic: require('@/assets/images/ActivityForm8.png'), checked: false },
        // ],
        selectedActivityIds: [],
        showSuccessModal: true,
        profilePictureError: '',
        fileName: '',
        fileSize: '',
        selectedFile: null,
        imageUrl: '',
        isUploading: false,
        loading: false,
        familyId: 0,
        userData: '',
        uploadProgress: '0 KB', // Initially set to 0 KB
        timeRemaining: 10, // Simulate a 10-second upload process
    };
  },
  created() {
    const authStore = useAuthStore(); 
    const route = useRoute();
    this.userData = authStore.user;
    this.id = route.params.id;
    if(parseInt(route.params.tab) !== 1) {
      this.currentTab = parseInt(route.params.tab);
    }
    this.fetchAllActivities(parseInt(route.params.tab));
    this.fetchChildData(route.params.id);
  },
  mounted () {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  },
  computed: {
    isMaxSelected() {
      return this.selectedActivityIds.length >= 5;
    },
  },
  methods: {
    async fetchChildData(id) {
      this.loading = true;
      getChildData(id)
      .then((response) => {
        this.loading = false;
        const data = response.data;
        if (data) {
          this.id = data?.id;
          this.role = data?.role;
          this.familyId = data?.family_id;
          this.firstName = data?.first_name;
          this.lastName = data?.last_name;
          this.selectedGender = data?.gender;
          this.selectedDate = new Date(data?.date_of_birth)
          this.registerationNumber = data?.national_register_number;
          this.streetName = data?.street_no;
          this.busNumber = data?.no_bus;
          this.postcode = data?.postcode;
          this.localAuthority = data?.local_authority;
          this.uitpas = data?.uitpas_number;
          if (data?.profile_image){
            this.imageUrl = this.getProfilePicture(data?.profile_image)
            const filename = data?.profile_image?.split('/').pop();
            this.fileName = filename;
          }
          if (data?.activities.length > 0) {
            for (let i = 0; i < data?.activities?.length; i++) {
              this.selectedActivityIds.push(data?.activities[i].id)
            }
          }
          if (data?.doctor) {
            this.medical.fullname = data?.doctor?.general_practitioner_name;
            this.medical.telephone = data?.doctor?.general_practitioner_phone;
            if (data?.doctor?.medical_conditions && data?.doctor?.allergies && data?.doctor?.allergies) {
              this.selectedMedical = 'yes';
              this.medical.conditions = data?.doctor?.medical_conditions;
              this.medical.allergies = data?.doctor?.allergies;
              this.medical.medications = data?.doctor?.medications;
            }
          }
        }
      })
      .catch((error) => {
        this.loading = false;
        console.log(error.message);
      });
    },
    isActivityChecked(activityId) {
      return this.selectedActivityIds.includes(activityId);
    },
    fetchAllActivities(tab) {
      getAllActivities()
      .then((response) => {
        this.activities = response.data;
        if(tab === 1) {
          this.currentTab = 1;
        }
      })
      .catch(() => {
        this.loading = false;
      });
    },
    setTab(index) {
        if (index === 1) {
            if (this.validate()) {
              this.handleSubmit()
            }
        } else if (index === 2) {
          this.CheckActivities()
        } else {
          this.currentTab = index;
        }
    }, 
    nextTab() {
        if (this.currentTab < this.tabs.length - 1) {
          this.currentTab += 1;
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    },
    prevTab() {
        if (this.currentTab > 0) {
          this.currentTab -= 1;
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    },
    // toggleActivity(id) {
    //     const activity = this.activities.find(activity => activity.id === id);
    //     if (activity) {
    //       if (this.isMaxSelected && !activity.checked) {
    //         // Do nothing if max selected and trying to check another
    //         return;
    //       }
    //       activity.checked = !activity.checked;
    //     }
    //   },
      toggleActivity(id) {
        const activitySelected = this.selectedActivityIds.includes(id);
  
        if (this.isMaxSelected && !activitySelected) {
          // If the max selection is reached and the activity is not already selected, do nothing
          return;
        }
  
        if (activitySelected) {
          // If already selected, uncheck it
          this.selectedActivityIds = this.selectedActivityIds.filter(activityId => activityId !== id);
        } else {
          // If not selected, check it
          this.selectedActivityIds.push(id);
        }
      },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    deletePortfolio() {
      this.fileName = '';
      this.fileSize = 0;
      this.selectedFile = null;
      this.imageUrl = '';
    },
    selectGender(val) {
      this.selectedGender = val;
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        // Set initial file info
        this.fileName = file.name;
        this.fileSize = (file.size / 1024 / 1024).toFixed(4); // Convert to MB
        this.selectedFile = file;

        // Simulate file upload process
        this.startUploadSimulation();
      } else {
        this.fileName = '';
        this.fileSize = 0;
      }
    },
    // Simulate the file upload process
    startUploadSimulation() {
      this.isUploading = true;
      this.uploadProgress = '0 KB';
      this.timeRemaining = 10; // Reset the time for each upload

      // Simulate upload progress over 2 seconds (adjust this for your needs)
      let simulatedProgress = 0;
      const interval = setInterval(() => {
        simulatedProgress += 100; // Increase progress in KB
        this.uploadProgress = `${simulatedProgress} KB / ${this.fileSize * 1024} KB`;
        this.timeRemaining--;

        if (this.timeRemaining <= 0) {
          // Stop the simulation after 2 seconds (this is where the "upload" finishes)
          clearInterval(interval);
          this.finishUpload();
        }
      }, 200); // Update every 200 ms for the upload simulation
    },

    // Finish the upload (image is now fully uploaded)
    finishUpload() {
      this.isUploading = false;
      this.imageUrl = URL.createObjectURL(this.selectedFile); // Create a URL for the selected file
    },
    CheckActivities() {
        if(this.selectedActivityIds.length > 5) {
            ToastMessage.show("Selecteer maximum 5 activiteiten", 'error');
        } else {
            this.loading = true;
            let formData = new FormData()
            formData.append("child_id", this.id)
            
            for(let i = 0; i < this.selectedActivityIds.length; i++) {
              formData.append("activity_id[" + i + "]", this.selectedActivityIds[i])
            }

            UpdateChildActivities(formData)
            .then((response) => {
              if (response.status == 'success') {
                this.loading = false;
                ToastMessage.show(response.message, 'success');
                this.currentTab = 2;
              }
            })
            .catch((error) => {
              this.loading = false;
              ToastMessage.show(error.message, 'error');
            });
        }
    },
    validate() {
      this.firstNameError = '';
      this.lastNameError = '';
      this.dobError = '';
      this.streetNameError = '';
      this.postcodeError = '';
      this.busNumberError = '';
      this.localAuthorityError = '';
      this.profilePictureError = '';
      
      if (!this.firstName) {
        this.firstNameError = 'Required';
      }
  
      if (!this.lastName) {
          this.lastNameError = 'Required';
      }
    
      if (this.selectedDate === null) {
          this.dobError = 'Required';
      }
            
      if (!this.streetName) {
        this.streetNameError = 'Required';
      }
      if (!this.busNumber) {
        this.busNumberError = 'Required';
      }
      if (!this.postcode) {
        this.postcodeError = 'Required';
      }
      if (!this.localAuthority) {
        this.localAuthorityError = 'Required';
      }

      // Validate profile picture
      if (this.selectedFile) {
        const allowedTypes = ['image/jpeg', 'image/png'];
        if (!allowedTypes.includes(this.selectedFile.type)) {
          this.profilePictureError = 'Profile picture must be a JPEG, or PNG';
        }
      }

      return !(this.firstNameError || this.lastNameError || this.dobError || this.profilePictureError || this.streetNameError || this.busNumberError || this.postcodeError || this.localAuthorityError); // return true if no errors
    },
    validateMedical() {
        this.fullnameError = '';
        this.telephoneError = '';
        
        if (!this.medical.fullname) {
          this.fullnameError = 'Required';
        }
    
        if (!this.medical.telephone) {
            this.telephoneError = 'Required';
        }
  
        return !(this.fullnameError || this.telephoneError); // return true if no errors
    },
    RedirectTo(path) {
      this.$router.push(path);
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based, so add 1
      const day = date.getDate().toString().padStart(2, '0'); // Ensure two-digit day
    
      return `${year}-${month}-${day}`;
    },
    handleSubmit() {
      if(this.validate()) {
        this.loading = true;
        let formData = new FormData()
        formData.append("first_name", this.firstName)
        formData.append("last_name", this.lastName)
        formData.append("dob", this.formatDate(this.selectedDate))
        formData.append("gender", this.selectedGender)
        formData.append("uitpass_no", this.uitpas)
        formData.append("registration_no", this.registerationNumber)
        formData.append("street_no", this.streetName)
        formData.append("no_bus", this.busNumber)
        formData.append("post_code", this.postcode)
        formData.append("local_authority", this.localAuthority)
        if (this.selectedFile) {
          formData.append("profile_picture", this.selectedFile)
        }     
        formData.append("child_id", this.id)

        UpdateChild(formData)
        .then((response) => {
          if (response.status == 'success') {
            this.loading = false;
            ToastMessage.show(response.message, 'success');
            this.currentTab = 1
          }
        })
        .catch((error) => {
          this.loading = false;
          window.scrollTo({ top: 0, behavior: 'smooth' });
          ToastMessage.show(error.message, 'error');
        });
      }
    },
    handleMedicalSubmit() {
      if (this.validateMedical()) {
        this.loading = true;
        let formData = new FormData()
        formData.append("general_practitioner_name", this.medical.fullname)
        formData.append("general_practitioner_phone", this.medical.telephone)
        if (this.selectedMedical === 'yes') {
          formData.append("allergies", this.medical.allergies)
          formData.append("medications", this.medical.medications)
          formData.append("medical_conditions", this.medical.conditions)
        }
        formData.append("child_id", this.id)

        UpdateChildMedicalInfo(formData)
        .then((response) => {
          if (response.status == 'success') {
            const modal = new Modal(document.querySelector('#successModal'));
            modal.show();
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          window.scrollTo({ top: 0, behavior: 'smooth' });
          ToastMessage.show(error.message, 'error');
        });
      }
    },
    getProfilePicture(picture) {
      return this.imgPath + picture;
    },
  }
}