<template>
    <FullPageLoader :isLoading="loading" />

    <div class="header-mobile-profile">
        <div class="header-mobile">
            <img class="imageClass" :src="AppCompoundShape" alt="top-header"/>
        </div>
        <div class="header-shape-text">
            <router-link class="back-arrow" to="/more">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0607 7.06066C16.6464 6.47487 16.6464 5.52513 16.0607 4.93934C15.4749 4.35355 14.5251 4.35355 13.9393 4.93934L7.93934 10.9393C7.35355 11.5251 7.35355 12.4749 7.93934 13.0607L13.9393 19.0607C14.5251 19.6464 15.4749 19.6464 16.0607 19.0607C16.6464 18.4749 16.6464 17.5251 16.0607 16.9393L11.1213 12L16.0607 7.06066Z" fill="white"/>
                    <mask id="mask0_4603_993" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="7" y="4" width="10" height="16">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0607 7.06066C16.6464 6.47487 16.6464 5.52513 16.0607 4.93934C15.4749 4.35355 14.5251 4.35355 13.9393 4.93934L7.93934 10.9393C7.35355 11.5251 7.35355 12.4749 7.93934 13.0607L13.9393 19.0607C14.5251 19.6464 15.4749 19.6464 16.0607 19.0607C16.6464 18.4749 16.6464 17.5251 16.0607 16.9393L11.1213 12L16.0607 7.06066Z" fill="white"/>
                    </mask>
                    <g mask="url(#mask0_4603_993)">
                    </g>
                </svg>
            </router-link>
        </div>
        <div class="header-shape-text d-flex justify-content-center align-items-center">
            <h4 class="font-20 font-quicksand-600 mb-0">Notificaties</h4>
        </div>
    </div>
    <div class="container mt-3 overflow-auto">
        <div class="show-desktop">
            <div>
                <router-link to="/more" class="d-flex align-items-center">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7071 4.70711C11.0976 4.31658 11.0976 3.68342 10.7071 3.29289C10.3166 2.90237 9.68342 2.90237 9.29289 3.29289L5.29289 7.29289C4.90237 7.68342 4.90237 8.31658 5.29289 8.70711L9.29289 12.7071C9.68342 13.0976 10.3166 13.0976 10.7071 12.7071C11.0976 12.3166 11.0976 11.6834 10.7071 11.2929L7.41421 8L10.7071 4.70711Z" fill="url(#paint0_linear_5796_16232)"/>
                    <mask id="mask0_5796_16232" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="5" y="3" width="6" height="10">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7071 4.70711C11.0976 4.31658 11.0976 3.68342 10.7071 3.29289C10.3166 2.90237 9.68342 2.90237 9.29289 3.29289L5.29289 7.29289C4.90237 7.68342 4.90237 8.31658 5.29289 8.70711L9.29289 12.7071C9.68342 13.0976 10.3166 13.0976 10.7071 12.7071C11.0976 12.3166 11.0976 11.6834 10.7071 11.2929L7.41421 8L10.7071 4.70711Z" fill="white"/>
                    </mask>
                    <g mask="url(#mask0_5796_16232)">
                    </g>
                    <defs>
                    <linearGradient id="paint0_linear_5796_16232" x1="5" y1="3" x2="13.8235" y2="8.29412" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FC425C"/>
                    <stop offset="1" stop-color="#FE8C71"/>
                    </linearGradient>
                    </defs>
                    </svg>
                    <span class="margin-left-5 color-primary2 font-16 font-opensans-600">
                        Terug
                    </span>
                </router-link>
            </div>
            <div class="mt-3 mb-4 d-flex justify-content-between align-items-center">
                <h6 class="font-24 font-quicksand-600 mb-0">Notificaties</h6>
                <div class="d-flex justify-content-end mt-2 mb-2">
                    <span class="color-primary cursor-pointer font-16 font-opensans-600" @click="MarkAllAsRead">Markeer alle als gelezen</span>
                </div>
            </div>
        </div>
        <div class="show-mobile text-end mt-2 mb-2">
            <span class="color-primary cursor-pointer font-16 font-opensans-600" v-if="notifications?.filter(notification => notification.is_read === 0).length" @click="MarkAllAsRead">Markeer alle als gelezen</span>
        </div>
        <div>
            <div class="m-0 row col-12 col-lg-7">
                <div
                v-for="n in notifications"
                :key="n.id"
                :class="[
                    'notification-card',
                    'col-12',
                    'position-relative',
                    { 'unread-notifications': n.is_read === 0 },
                ]"
                >
                    <span v-if="n.is_read === 0" class="unread-dot"></span>
                    <div class="d-flex align-items-center">
                        <img :src="getNotificationIcon(n.icon)" :alt="n.icon" height="50">
                        <div class="notification-card-title" :class="{ 'cursor-pointer': n.url }" @click="onClickNotification(n)">
                            <span class="notification-date">{{ formatDate(n.updated_at) }}</span>
                            <h5>{{ n.message }}</h5>
                            <span class="color-primary font-16 font-opensans-600">{{ n.title }}</span>
                        </div>
                        <a class="cross-icon-notification cursor-pointer" @click="removeNotification(n.id)">
                            <i class="fa fa-close color-black"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="./index.js"></script>