import MobileNavigation from '../../components/MobileNavigation.vue';
import SleepingDog from '../../assets/images/SleepingDog.png';
import ChildAvatar from '../../assets/images/ChildAvatar.png';
import Avatar3 from '../../assets/images/Avatar3.png';
import Avatar4 from '../../assets/images/Avatar4.png';
import { fetchAllRegistrations, fetchAllRegistrationsChilds } from '@/services/apiService';
import { useAuthStore } from '@/store/authStore';
import FullPageLoader from '@/components/FullPageLoader.vue';
import { useRoute } from 'vue-router';

export default {
  components: {
    MobileNavigation,
    FullPageLoader
  },
  data() {
    return {
      Avatar3,
      SleepingDog,
      loading: false,
      imgPath: process.env.VUE_APP_IMG_PATH,
      imgPathCamp: process.env.VUE_APP_IMG_PATH_CAMP, 
      cdnPath: process.env.VUE_APP_CDN_PATH,  
      isOpen: false,
      isLocked: false,
      isFilterOpen: false,
      tabState: '1',
      noResultRegistrations: false,
      noResultWaitingList: false,
      noResultFavorites: false,
      showFilterNames: false,
      filteredNames: [],
      registrations: [],
      waitingList: [],
      favorites: [],
      lastRegistrations: [],
      friends: [
        { id: 2, name: 'Robbe van Loock', pic: Avatar3 },
        { id: 3, name: 'Olivia Van Loock', pic: Avatar4 },
      ],
      placeholderChild: ChildAvatar,
      familyId: 0,
      appUserId: 0,
      selectedChild: [],
      currentTab: 1,
    };
  },
  created() {
    const authStore = useAuthStore(); 
    this.familyId = authStore.user.parent.family_id;
    this.appUserId = authStore.user.parent.app_user_id;
    this.fetchAllRegisteredBookings(authStore.user.parent.id, authStore.user.parent.app_user_id);
    this.fetchAllRegisteredChilds(authStore.user.parent.family_id);

    const route = useRoute();
    this.currentTab = parseInt(route.params.tab);
    // console.log(this.currentTab)
  },
  methods: {
    async fetchAllRegisteredBookings(parentId, appUserId) {
      this.loading = true;
      let filter = ''
      if (this.selectedChild.length > 0) {
        this.selectedChild.forEach((element, index) => {
          filter += `?child_id[${index}]=` + element.id
        });
      }
      fetchAllRegistrations(parentId, appUserId, filter)
      .then((response) => {
        this.loading = false;
        // console.log(response)
        this.registrations = response.data?.registered?.new_bookings;
        this.lastRegistrations = response.data?.registered?.history_bookings;
        this.favorites = response.data?.favorite;
        this.waitingList = response.data?.waiting;

        if (response.data?.registered?.new_bookings.length === 0 && response.data?.registered?.history_bookings.length === 0 && this.isFilterOpen) {
          this.noResultRegistrations = true;
        } else {
          this.noResultRegistrations = false;
        }
        if (response.data?.favorite.length === 0 && this.isFilterOpen) {
          this.noResultFavorites = true;
        } else {
          this.noResultFavorites = false;
        }
        if (response.data?.waiting.length === 0 && this.isFilterOpen) {
          this.noResultWaitingList = true;
        } else {
          this.noResultWaitingList = false;
        }
      })
      .catch((error) => {
        this.loading = false;
        console.log(error.message);
      });
    },
    async fetchAllRegisteredChilds(familyId) {
      fetchAllRegistrationsChilds(familyId)
      .then((response) => {
        this.friends = response.data;
      })
      .catch((error) => {
        console.log(error.message);
      });
    },
    getMarginStyle(item) {
      if (item.length === 1) {
        return { marginLeft: '0' };
      } else {
        return { marginLeft: '-15px' };
      }
    },
    RedirectTo(path) {
      const authStore = useAuthStore()
      if (this.currentTab === 1) {
        authStore?.setLastRoute('/registrations/1')
      } else if (this.currentTab === 2) {
        authStore?.setLastRoute('/registrations/2')
      } else {
        authStore?.setLastRoute('/registrations/3')
      }
      this.$router.push(path);
    },
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
    changeTabState(tab) {
      this.tabState = tab;
    },
    isActivityChecked(id) {
      return this.selectedChild.some(child => child.id === id);
    },
  
    toggleActivity(child) {
      const activitySelected = this.isActivityChecked(child.id);
  
      if (activitySelected) {
        // If already selected, uncheck it (remove from the selected array)
        this.selectedChild = this.selectedChild.filter(childId => childId.id !== child.id);
      } else {
        // If not selected, check it (add to the selected array)
        this.selectedChild.push(child);
      }
    },
    getProfilePicture(picture) {
      return picture ? this.imgPath + picture : this.placeholderChild;
    },
    showFilters() {
      if (this.selectedChild.length > 0) { 
        this.isFilterOpen = true;
        this.showFilterNames = true;
        this.fetchAllRegisteredBookings(this.familyId, this.appUserId);
      }
    },
    removeFilter(id) {
      this.selectedChild = this.selectedChild.filter(child => child.id !== id);
      this.fetchAllRegisteredBookings(this.familyId, this.appUserId);
      if (this.selectedChild.length === 0) {
        this.isFilterOpen = false;
        this.showFilterNames = false;
      }
    },
    displayCampUsers(min_age, max_age, is_subgroup) {
      if (is_subgroup === 'Yes') {
        return `Vanaf ${min_age} jaar`;
      } else {
        return `${min_age} - ${max_age} jaar`;
      }
    },
    displayCampDate(startDate, endDate) {

      // Parse the dates
      const minDate = new Date(startDate);
      const maxDate = new Date(endDate);
  
      // Check if the min and max dates are the same
      if (minDate.getTime() === maxDate.getTime()) {
          return this.formatDate(minDate);
      } else {
          return `${this.formatDate(minDate)} - ${this.formatDate(maxDate)}`;
      }
    },
    formatDate(date) {
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        return date.toLocaleDateString('nl-NL', options);
    },
    displayCampTime(start_time, end_time) {  
      // Convert time strings to Date objects
      const start = new Date(`1970-01-01T${start_time}`); // Use a fixed date to convert time
      const end = new Date(`1970-01-01T${end_time}`);
  
      // Format time as "h:mm" or "h" if minutes are 0
      const formattedStart = this.formatTime(start);
      const formattedEnd = this.formatTime(end);
  
      return `${formattedStart} - ${formattedEnd}`;
    },
    formatTime(date) {
        // Extract hours and minutes
        let hours = date.getHours();
        let minutes = date.getMinutes();
    
        // Format time in "h:mm" or "h" if minutes are 0
        if (minutes === 0) {
            return `${hours}u`;
        } else {
            return `${hours}:${minutes < 10 ? '0' + minutes : minutes}u`;
        }
    },
    getLogo(picture) {
      return picture ? this.cdnPath + 'width=30,height=30,fit=cover,format=auto,metadata=none' + this.imgPathCamp + picture : this.placeholderChild;
    },
  }
}