import Datepicker from 'vue3-datepicker';
import { ref } from 'vue';
import FullPageLoader from '../../../components/FullPageLoader.vue';
import { useRoute } from 'vue-router';
import ParentAvatar from '../../../assets/images/ParentAvatar.png';
import OtherAvatar from '../../../assets/images/OtherAvatar.png';
import { DeleteFamily, getFamilyMemberData } from '@/services/apiService';

export default {
    components: {
        Datepicker,
        FullPageLoader
    },
    setup() {
        const selectedDate = ref(null); // Using ref for reactivity
    
        return {
          selectedDate,
        };
    },
    created() {
        const route = useRoute();
        this.memberId = route.params.id;
        this.fetchFamilyMemberData(route.params.id);
    },
    data() {
        return {
        memberData: null,
        loading: false,
        memberId: 0,
        docs: false,
        imgPath: process.env.VUE_APP_IMG_PATH, 
        placeholder: ParentAvatar,
        placeholderOther: OtherAvatar,
        conditions: [],
        // conditions: [{ name: 'Factuur 12/08/2024' }, { name: 'Fiscaal attest 10/08/2024' }, { name: 'Factuur 06/04/2024' }],
        };
    },
    methods: {
        RedirectTo(path) {
            this.$router.push(path);
        },
        RedirectToEdit(tab) {
            if (this.memberData.role !== 3) {
                this.$router.push('/update-parent-profile/' + this.memberData.id + '/' + tab);
            } else {
                this.$router.push('/update-other-profile/' + this.memberData.id);
            }
        },
        async fetchFamilyMemberData(id) {
            this.loading = true;
            getFamilyMemberData(id)
            .then((response) => {
              this.loading = false;
              this.memberData = response.data;
            })
            .catch((error) => {
              this.loading = false;
              console.log(error.message);
            });
        },
        getProfilePicture(picture, role) {
            return picture ? this.imgPath + picture : role === 3 ? this.placeholderOther : this.placeholder;
        },
        deleteFamilyMember() {
            this.loading = true;
            let formData = new FormData()
            formData.append("parent_id", this.memberId)
            DeleteFamily(formData)
            .then(() => {
                this.loading = false;
                this.$refs.closeIcon.click();
                this.RedirectTo('/family');
              })
              .catch((error) => {
                this.loading = false;
                console.log(error.message);
              });
        }
    }
}