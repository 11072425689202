import { sliceEvents, createPlugin } from '@fullcalendar/core';

const listWeekView = {
  type: 'list',
  duration: { weeks: 1 },

  content: function (props) {
    const originalEvents = sliceEvents(props, true); // allDay=true
    const start = props.dateProfile.currentRange.start;
    const end = props.dateProfile.currentRange.end;

    const days = [];
    let loop = new Date(start);
    while (loop < end) {
      days.push(new Date(loop));

      let newDate = loop.setDate(loop.getDate() + 1);
      loop = new Date(newDate);
    }

    const listOfEvents = [];
    days.forEach((day) => {
      const events = originalEvents.filter((dayEvent) => {
        const from = dayEvent.instance.range.start.getTime();
        const to = dayEvent.instance.range.end.getTime();
        const current = day.getTime();
        
        return current >= from && current <= to;
      });
      
      listOfEvents.push({
        isToday: day.setHours(0,0,0,0) === new Date().setHours(0,0,0,0),
        date: day.getDate(),
        day: new Intl.DateTimeFormat('nl-nl', { dateStyle: 'full' }).format(day).substring(0, 2),
        events,
      });
    });

    const html = listOfEvents.map((list) => `
      <div class="fc-list-week">
        <div class="fc-list-week__day">
          <h6 class="${list.isToday ? 'today' : ''}">${list.date}</h6>
          ${list.day}
        </div>
        <ul class="fc-list-week__event">
          ${list.events.map((ev) => {
            const data = ev.def;
            const { extendedProps: props } = data;
            const stringData = JSON.stringify({
              title: data.title,
              extendedProps: data.extendedProps,
              start: ev.range.start,
              end: ev.range.end,
            });

            const imgPathCamp = process.env.VUE_APP_IMG_PATH_CAMP;
            const cdnPath = process.env.VUE_APP_CDN_PATH; 
            const friends = props.children;

            if (props.friends && props.friends.length > 0) {
              props.friends.forEach((f) => {
                friends.push(f);
              });
            }

            return `
              <li class="fc-list-event ${props.category}" title="${data.title}" data-event='${stringData}'>
                <span class="${props.category === 'watchlist' ? 'watchlist' : ''}" title="${data.title}">${data.title}</span>
                <div class="${props.category === 'favorite' && (!props.friends || !props.friends.length) ? 'pr-2' : ''}">
                  ${props.category === 'watchlist' ? '<span class="badge">Wachtlijst</span>' : '' }
                  ${props.category === 'favorite' ? '<i class="fa-regular fa-heart"></i>' : '' }
                  ${friends && friends.length > 0 ?
                    `<div class="fc-list-week__event-friends">
                      <!--<img
                        class="item-0 ${friends[0].profile_image ? 'bordered' : ''}"
                        src="${cdnPath + 'width=50,height=50,fit=cover,format=auto,metadata=none' + imgPathCamp + friends[0].profile_image || require('@/assets/images/calendar-default-avatar.png')}"
                        title="${friends[0].name || data.title}"
                        alt="${friends[0].name || data.title}" />-->
                        
                        ${friends.map((friend, index) => {
                          let imgEl = '';
                          let imgUrl = friend.profile_image !== null ? cdnPath + 'width=50,height=50,fit=cover,format=auto,metadata=none' + imgPathCamp + friend.profile_image : require('@/assets/images/calendar-default-avatar.png');
                          if (index < 2) {
                            imgEl += `<img
                              class="item-${index+1} ${friend.profile_image ? 'bordered' : ''}"
                              src="${imgUrl}"
                              title="${friend.name || friend.friend_name || data.title}"
                              alt="${friend.name || friend.friend_name || data.title}" />`;
                          }
                          
                          if (index === 2) {
                            imgEl += `<div
                              class="item-${index+1} more-friends"
                              title="${friend.name || friend.friend_name || data.title}"
                            >+${friends.length - 2}</div>`;
                          }

                          return imgEl;
                        }).join('')}
                    </div>`
                    : ''}
                </div>
              </li>
            `
          }).join('')}
        </ul>
      </div>
    `).join('');

    return { html }
  },
}

export default createPlugin({
  views: {
    listWeek: listWeekView
  }
});