import { toRaw } from 'vue';
import { Tooltip } from 'bootstrap';
import AppCompoundShape from '@/assets/images/AppCompoundShape.png';
import FallbackCover from '@/assets/images/NoCover.png';
import FallbackAvatar from '@/assets/images/Avatars.png';
import Org from '@/assets/images/Org.png';

import { useAuthStore } from '@/store/authStore';

import FullPageLoader from '@/components/FullPageLoader.vue';

import {
  fetchCampBookingDetails,
  enrollmentCompleteRegistration,
} from '@/services/apiService';

export default {
  components: {
    FullPageLoader,
  },
  data() {
    return {
      AppCompoundShape,
      FallbackCover,
      FallbackAvatar,
      Org,
      userInfo: '',
      currentTab: 0,
      tabs: [
        {
          id: 'registration-tab-pane',
          label: 'Rijksregisternr',
        },
        {
          id: 'form-tab-pane',
          label: 'Vragen',
        },
        {
          id: 'info-tab-pane',
          label: 'Bijkomende info',
        },
      ],
      camp: null,
      form: {
        registrationNumbers: [],
        additionalInfo: [],
        consent: [],
      },
      paymentCheck: null,
      isSuccess: false,
      isLoading: false,
      campId: null,
    };
  },
  created() {
    const authStore = useAuthStore(); 
    this.userInfo = authStore.user;
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const paymentCheck = localStorage.getItem('payment_check');
    if (paymentCheck) {
      this.paymentCheck = JSON.parse(paymentCheck);
    }

    const urlParams = new URLSearchParams(window.location.search);
    const campId = urlParams.get('campId');
    this.campId = campId;

    this.getCampDetail();
  },
  computed: {
    title() {
      if (this.isSuccess) {
        return 'Bevestiging';
      }

      return 'Vervolledig je inschriijving';
    },
    disableNext() {
      if (!this.camp) return true;

      if (this.currentTab === 0 && this.form.registrationNumbers.some((r) => !r || r === '')) {
        return true;
      }

      const questions = [];
      this.camp.questions.forEach((q) => {
        questions.push(this.form[q.id]);
      });

      if (this.currentTab === 1 && questions.some((q) => q.required && q.value.some((v) => v === ''))) {
        return true;
      }

      // mandatory consent
      // if (this.currentTab === 2 && this.form.consent.some((q) => !q)) {
      //   return true;
      // }

      return false;
    },
    closeLink() {
      if (this.isSuccess) {
        return '/registrations/1'
      }

      return '/search';
    }
  },
  methods: {
    async getCampDetail() {
      this.isLoading = true;
      fetchCampBookingDetails(this.campId)
        .then((response) => {
          this.isLoading = false;
          this.camp = response.data;

          if (this.paymentCheck) {
            if (this.camp && this.camp.questions && this.camp.questions.length > 0) {
              this.camp.questions.forEach((q) => {
                this.form = {
                  ...toRaw(this.form),
                  [q.id]: {
                    required: q.is_mandatory,
                    value: [],
                  },
                };

                this.paymentCheck.camp_booking_details.details.forEach(() => {
                  this.form[q.id].value.push('');
                });
              });
            }

            this.paymentCheck.camp_booking_details.details.forEach(() => {
              this.form.registrationNumbers.push('');
              this.form.additionalInfo.push('');
              this.form.consent.push(false);
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error.message);
        });
    },
    async submitForm() {
      this.isLoading = true;

      const payload = [];
      const bookDetails = this.paymentCheck.camp_booking_details;
      bookDetails.details.forEach((b, i) => {
        const obj = {
          camp_booking_detail_id: bookDetails.id,
          camp_booking_id: b.camp_booking_id,
          child_id: b.child.id,
          registration_number: this.form.registrationNumbers[i],
          campanswers: [],
          additional_info: this.form.additionalInfo[i],
        };

        this.camp.questions.forEach((q) => {
          obj.campanswers.push({
            id: q.id,
            answer: this.form[q.id].value[i],
          });
        });

        payload.push(obj);
      });

      try {
        const response = enrollmentCompleteRegistration(payload);
        if (response) {
          this.isSuccess = true;
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        this.isLoading = false;
      }
    },
    displayCampDate(slots) {
      // Check if the campDates array is empty or doesn't have the expected structure
      if (!slots || !slots.length) return "No dates available";
  
      // Parse the dates
      const minDate = new Date(slots[0].start_date);
      const maxDate = new Date(slots[slots.length - 1].start_date);
      const isSameYear = minDate.getYear() === maxDate.getYear();
      const isSameMonth = minDate.getMonth() === maxDate.getMonth();
  
      // Check if the min and max dates are the same
      if (minDate.getTime() === maxDate.getTime()) {
        return this.formatDate(minDate);
      } else if (isSameYear) {
        const strSplitted = this.formatDate(minDate).split(' ');
        return `${strSplitted[0]} ${isSameMonth ? '' : strSplitted[1]} - ${this.formatDate(maxDate)}`;
      } else {
        return `${this.formatDate(minDate)} - ${this.formatDate(maxDate)}`;
      }
    },
    formatDate(date) {
      const options = { day: 'numeric', month: 'long', year: 'numeric' };
      return date.toLocaleDateString('nl-NL', options);
    },
    formatTime(date) {
      return new Date(date).getHours() + 'u';
    },
    imageUrl(url) {
      const cdn = process.env.VUE_APP_CDN_PATH;
      const path = process.env.VUE_APP_IMG_PATH_CAMP;
      return `${cdn}width=200,height=200,fit=cover,format=auto,metadata=none${path}${url}`;
    },
    setTab(index) {
      this.currentTab = index;
    },
    next() {
      if (this.isSuccess) {
        this.$router.push('/registrations/1');
        return;
      }

      if (this.currentTab === 2) {
        this.submitForm();
        return;
      }

      this.currentTab += 1;

      if (this.currentTab === 2) {
        setTimeout(() => {
          const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
          const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl));
          console.log(tooltipList);
        }, 500);
      }
    },
    back() {
      this.currentTab -= 1;
    },
    nextButton() {
      const text = {
        2: 'Ga naar betalen',
        3: 'Bevestig inschrijving',
      };

      return text[this.currentTab] || 'Volgende';
    },
  }
}